import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { createPortal } from 'react-dom';
import {
  IoCopy,
  IoShareOutline,
  IoCheckmarkCircleOutline,
  IoAddCircleOutline,
} from "react-icons/io5";
import styles from "../Chat.module.css";
import { useApi } from "../../../hooks/useApi";
import { CreatePostModal } from "../../../components/CreatePostModal/CreatePostModal";
import { useTranslation } from "react-i18next";

const tg = window.Telegram.WebApp;

export const MessageContextMenu = ({
  x,
  y,
  onClose,
  message,
  characterId,
  containerRef,
  messageRef,
  onSelect,
}) => {
  const menuRef = useRef(null);
  const [position, setPosition] = useState({ top: y, left: x });
  const [sharePostModalOpen, setSharePostModalOpen] = useState(false);
  const [sharePostLoading, setSharePostLoading] = useState(false);
  const { t } = useTranslation();

  const apiOptions = useMemo(
    () => ({
      baseUrl: process.env.REACT_APP_API_URL,
      requestOptions: {
        headers: {
          "x-init-data": tg.initData,
        },
      },
    }),
    [t]
  );

  const {
    user: { useShareMessage, useShareImage },
    post: { useCreate },
  } = useApi(apiOptions);
  const { fetch: shareMessage } = useShareMessage(message.id);
  const { fetch: shareImage } = useShareImage(message.uuid);
  const { fetch: createPost } = useCreate(message.id);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        messageRef.current &&
        !menuRef.current.contains(event.target) &&
        !messageRef.current.contains(event.target)
      ) {
        onClose();
      }
    };

    // Close menu on scroll
    const handleScroll = () => {
      onClose();
    };

    // Use a small timeout to avoid handling the same click that opened the menu
    const timeoutId = setTimeout(() => {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("touchstart", handleClickOutside);
      
      // Add scroll event listeners to both document and container
      document.addEventListener("scroll", handleScroll, true);
      if (containerRef.current) {
        containerRef.current.addEventListener("scroll", handleScroll);
      }
    }, 10);

    return () => {
      clearTimeout(timeoutId);
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
      document.removeEventListener("scroll", handleScroll, true);
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [onClose, messageRef, containerRef]);

  useEffect(() => {
    if (!menuRef.current || !containerRef.current || !messageRef.current)
      return;

    const menuRect = menuRef.current.getBoundingClientRect();
    const containerRect = containerRef.current.getBoundingClientRect();
    const messageRect = messageRef.current.getBoundingClientRect();
    const isUser = message.role === "USER";
    const padding = 12;

    // Calculate absolute position in the viewport
    let newLeft, newTop;

    // Check if menu fits below the message
    if (messageRect.bottom + menuRect.height + padding <= window.innerHeight) {
      newTop = messageRect.bottom + padding;
    } 
    // Otherwise, try to position above the message
    else if (messageRect.top - menuRect.height - padding >= 0) {
      newTop = messageRect.top - menuRect.height - padding;
    } 
    // If neither works well, position it at the message center
    else {
      newTop = messageRect.top + messageRect.height / 2 - menuRect.height / 2;
    }

    // For horizontal positioning
    if (isUser) {
      // For user messages, align with the right side of the message
      newLeft = messageRect.right - menuRect.width;
    } else {
      // For bot messages, align with the left side of the message
      newLeft = messageRect.left;
    }

    // Ensure the menu stays within the viewport horizontally
    if (newLeft < 0) newLeft = 0;
    if (newLeft + menuRect.width > window.innerWidth) {
      newLeft = window.innerWidth - menuRect.width;
    }

    setPosition({ top: newTop, left: newLeft });
  }, [x, y, message.role, containerRef, messageRef]);

  const menuStyle = useMemo(
    () => ({
      position: "absolute",
      top: `${position.top}px`,
      left: `${position.left}px`,
    }),
    [position]
  );

  const handleCopy = async (e) => {
    e.stopPropagation();

    if (tg.platform === "web") {
      tg.showAlert("Copy is not supported on web version of Telegram");
      return;
    }

    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = message.text;

    const processNode = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        return node.textContent;
      }

      let text = "";
      for (const child of node.childNodes) {
        text += processNode(child);
      }

      switch (node.nodeName.toLowerCase()) {
        case "p":
          return text + "\n\n";
        case "b":
        case "strong":
          return `*${text}*`;
        case "i":
        case "em":
          return `_${text}_`;
        default:
          return text;
      }
    };

    const plainText = processNode(tempDiv).trim();
    await navigator.clipboard.writeText(plainText);
    onClose();
  };

  // const handleDelete = (e) => {
  //     e.stopPropagation();
  //     onClose();
  // };

  // const handleEdit = (e) => {
  //     e.stopPropagation();
  //     onClose();
  // };

  const handleShare = useCallback(async (e) => {
    e.stopPropagation();
    try {
      if (message.isPicGeneration) {
        const { prepared_message } = await shareImage();
        await tg.shareMessage(prepared_message.id);
      } else {
        const { prepared_message } = await shareMessage();
        await tg.shareMessage(prepared_message.id);
      }
      onClose();
    } catch (error) {
      console.error("Error sharing:", error);
    }
  }, [message, shareMessage, shareImage]);

  const handleSelect = useCallback((e) => {
    e.stopPropagation();
    onSelect(message);
    onClose();
  }, [message, onSelect, onClose]);

  const handleCreatePost = useCallback((e) => {
    e.stopPropagation();
    setSharePostModalOpen(true);
  }, [message]);

  const handleSharePost = useCallback(async (text) => {
    console.log(message, text, characterId)
    setSharePostLoading(true);
    await createPost({ body: JSON.stringify({ character_id: characterId, content: text, preview_url: message.image_url }) });
    setSharePostLoading(false);
    setTimeout(() => {
      setSharePostModalOpen(false);
    }, 2000);
  }, [message, createPost, characterId]);

  const handleCloseSharePostModal = useCallback((e) => {
    if (e) e.stopPropagation();
    setSharePostModalOpen(false);
  }, []);

  return createPortal(
    <div
      ref={menuRef}
      className={styles.contextMenu}
      style={menuStyle}
      onTouchStart={(e) => {
        e.stopPropagation();
        tg.HapticFeedback.impactOccurred("light");
      }}
      onTouchEnd={(e) => {
        e.stopPropagation();
        tg.HapticFeedback.impactOccurred("light");
      }}
    >
      <button className={styles.contextMenuItem} onClick={handleCopy}>
        <IoCopy />
        <span>{t('chat.contextMenu.copy')}</span>
      </button>
      {!(message.kind === 'TOOL_CALL' && message.role === 'USER') && (
        <>
            { message.isPicGeneration && (
                <button className={styles.contextMenuItem} onClick={handleCreatePost}>
                    <IoAddCircleOutline />
                    <span>{t('chat.contextMenu.createPost')}</span>
                </button>
            )}
            <button className={styles.contextMenuItem} onClick={handleShare}>
                <IoShareOutline />
                <span>{t('chat.contextMenu.share')}</span>
            </button>
            <button className={styles.contextMenuItem} onClick={handleSelect}>
                <IoCheckmarkCircleOutline />
                <span>{t('chat.contextMenu.select')}</span>
            </button>
        </>
      )}

      <CreatePostModal
        isOpen={sharePostModalOpen}
        onClose={handleCloseSharePostModal}
        onSubmit={handleSharePost}
        loading={sharePostLoading}
        title={t('chat.postModal.title')}
        placeholder={t('chat.postModal.placeholder')}
      />
    </div>,
    document.body
  );
};
