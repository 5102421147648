import React, { useCallback, useEffect, useState, useMemo, useRef, useContext } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { IoTrash, IoAddCircle, IoClose } from "react-icons/io5";
import { useTranslation } from "react-i18next";

import 'react-image-crop/dist/ReactCrop.css'

import styles from './Edit.module.css';
import ModerationGuide from './ModerationGuide';

import { Profile } from './tabs/Profile';
import { Lora } from './tabs/Lora';
import { Loader } from '../../components/Loader';
import { useApi, ApiError } from '../../hooks/useApi';
import { useGenerate, GenerateContext } from '../../hooks/useGenerate';
import { UserContext } from '../../components/UserProvider';

let tg = window.Telegram.WebApp;

export function Edit() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultTab = searchParams.get('tab');

  const bioRef = useRef(null);
  const greetingRef = useRef(null);
  const descriptionRef = useRef(null);
  const systemPromptRef = useRef(null);
  const scenarioRef = useRef(null);
  const avatarFileRef = useRef(null);
  const scrollRef = useRef(null);

  const [character, setCharacter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(null);
  const [newAvatar, setNewAvatar] = useState(null);
  const [moderationError, setModerationError] = useState(null);
  const [moderationWarnings, setModerationWarnings] = useState(null);
  const [moderationGuideOpen, setModerationGuideOpen] = useState(false);
  const [tab, setTab] = useState(defaultTab || 'profile');
  const [formErrors, setFormErrors] = useState({});
  const [importOpen, setImportOpen] = useState(false);
  const [importFromUrl, setImportFromUrl] = useState(false);
  const [importUrl, setImportUrl] = useState('');
  const [importMessage, setImportMessage] = useState('');
  const [importCharacterLoading, setImportCharacterLoading] = useState(false);
  const [counter, setCounter] = useState(0);

  const [fullscreen, setFullscreen] = useState(false);

  const [saving, setSaving] = useState(false);

  const userMessageRef = useRef(null);
  const characterMessageRef = useRef(null);
  const [userMessage, setUserMessage] = useState('');
  const [characterMessage, setCharacterMessage] = useState('');

  const [keyboardOpen, setKeyboardOpen] = useState(false);

  const apiOptions = useMemo(() => ({
    baseUrl: process.env.REACT_APP_API_URL,
    requestOptions: {
      headers: {
        'x-init-data': tg.initData,
      }
    }
  }), [])

  const {
    character: {
      useGet: getCharacter,
      useAvatarUpload: avatarUpload,
      useUpdateProfile: updateProfile,
      useImport: importCharacter,
      useGenerate: useGenerateRequest,
      useUpdateLora: updateLora
    },
    user: {
      useMe,
    }
  } = useApi(apiOptions)

  const { fetch: fetchMe } = useMe();
  const { fetch: getCharacterFetch, data: getCharacterData, error: getCharacterError } = getCharacter(id)
  const { fetch: avatarUploadFetch, loading: avatarUploadLoading } = avatarUpload(id)
  const { fetch: updateProfileFetch, data: updateProfileData, loading: updateProfileLoading } = updateProfile(id)
  const { fetch: updateLoraFetch } = updateLora(id)
  const { fetch: importCharacterFetch } = importCharacter(id)
  const { fetch: generateFetch } = useGenerateRequest()

  const onApply = useCallback((field, value) => {
    return setCharacter({
      ...character,
      [field]: value,
    })
  }, [character])

  const onGenerateFetch = useCallback(async (field, value, prompt) => {
    try {
      const data = await generateFetch({
        body: JSON.stringify({
            id,
            prompt,
            field,
            value
        })
      })

      return data.result
    } catch (error) {
      setErrors((state) => [...state, error.message])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const generate = useGenerate({ onApply, onGenerateFetch })
  const { modal } = generate

  const { user } = useContext(UserContext);

  useEffect(() => {
    tg.expand()
    tg.BackButton.show()
    tg.BackButton.onClick(() => navigate(`/character/${id}`))
    tg.disableVerticalSwipes()

    return () => {
      tg.viewportChanged = null
      tg.BackButton.offClick()
    }
  }, [id, navigate]);

  useEffect(() => {
    if (tab === 'profile') {
      [bioRef, greetingRef, descriptionRef, scenarioRef, systemPromptRef].forEach((ref) => {
        if (ref.current) {
          ref.current.style.height = "1px";
          ref.current.style.height = (25 + ref.current.scrollHeight) + "px";
        }
      })
    } else if (tab === 'dialogues') {
      [userMessageRef].forEach((ref) => {
        if (ref.current) {
          ref.current.style.height = "1px";
          ref.current.style.height = (25 + ref.current.scrollHeight) + "px";
        }
      })
    }

  }, [tab, character])

  useEffect(() => {
    if (!user || getCharacterData) return
    getCharacterFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, getCharacterData])

  useEffect(() => {
    const lastIndex = errors.length - 1
    const timeout = setTimeout(() => {
      setErrors((state) => {
        return state.splice(0, lastIndex)
      })
    }, 3000)

    return () => clearTimeout(timeout)
  }, [errors])

  useEffect(() => {
    setErrors((errors) => {
      if (getCharacterError) {
        return [...errors, getCharacterError]
      }

      return errors
    })
  }, [getCharacterError])

  useEffect(() => {
    if (!updateProfileData) return;

    if (updateProfileData.moderation_error) {
      setModerationError(updateProfileData.moderation_error)
      setCharacter((state) => {
        return {
          ...state,
          is_public: false,
        }
      })
    }

    if (updateProfileData.moderation_score) {
      setModerationWarnings(
        Object.fromEntries(Object.entries(updateProfileData.moderation_score).filter(([key, value]) => parseFloat(value) > 0.1))
      )
    }

    if (updateProfileData.rating) {
      setCharacter((state) => {
        return {
          ...state,
          rating: updateProfileData.rating,
        }
      })
    }
  }, [updateProfileData])

  useEffect(() => {
    if (!getCharacterData) return;
    
    setCharacter({
      ...getCharacterData,
    })

    setModerationWarnings(
      Object.fromEntries(Object.entries(getCharacterData.moderation_score).filter(([key, value]) => parseFloat(value) > 0.1))
    )
    setLoading(false)
  }, [getCharacterData])

  const onChange = useCallback((key) => (e) => {
    setCharacter({ ...character, [key]: e.target.value })
  }, [character])

  const onAvatarEditClick = useCallback(() => {
    if (!character.avatar_url) {
      avatarFileRef.current.click()
      return
    }

    try {
      const image = new Image();

      image.addEventListener("load", () => {
        const canvas = document.createElement('canvas');

        canvas.width = image.width;
        canvas.height = image.height;

        const ctx = canvas.getContext('2d');

        ctx.drawImage(image, 0, 0);

        canvas.toBlob((blob) => {
          const reader = new FileReader();

          reader.onload = () => {
            const buffer = reader.result;
            setNewAvatar(URL.createObjectURL(new Blob([buffer], { type: 'image/png' })));
          };
          reader.readAsArrayBuffer(blob);
        });
      }, false);

      image.crossOrigin = 'anonymous';
      image.src = character.avatar_url + '?t=' + new Date().getTime();

    } catch (e) {
      setErrors((state) => [...state, 'Error loading avatar'])
    }
  }, [character])

  const onAvatarChange = useCallback((e) => {
    const file = e.target.files[0]

    let numberOfBytes = 0;
    for (const file of e.target.files) {
      numberOfBytes += file.size;
    }

    const allowedMimeTypes = ['image/jpeg', 'image/png', 'image/webp'];
    if (!allowedMimeTypes.includes(file.type)) {
      setErrors((state) => [...state, 'Invalid file type. Only JPEG, PNG, and WebP are allowed.'])
      return;
    }

    if (numberOfBytes > 1024 * 1024 * 2) {
      setErrors((state) => [...state, 'File size must be less than 2MB'])
      return
    }

    file.arrayBuffer().then((buffer) => {
      setNewAvatar(URL.createObjectURL(new Blob([buffer], { type: file.type })))
    })
  }, [])

  const onAvatarSaveClick = useCallback((file) => {
    const body = new FormData()

    body.append('file', file)

    avatarUploadFetch({ body }).then((res) => {
      setCharacter({
        ...character,
        avatar_url: res.avatar_url,
      })
      setNewAvatar(null)
    }).catch((e) => {
      setErrors((state) => [...state, e.message])
    })

    avatarFileRef.current.value = ''

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [character])

  const onAvatarUpload = useCallback(() => {
    avatarFileRef.current.click()
  }, [avatarFileRef])

  const onAvatarCancelClick = useCallback(() => {
    setNewAvatar(null)
  }, [])

  const onSave = useCallback(() => {
    if (tab === 'lora') {
      setSaving(true)
      updateLoraFetch({
        body: JSON.stringify({ air: character.lora.air }),
      }).then(() => {
        setSuccess('Lora updated')
        setSaving(false)

        setTimeout(() => {
          setSuccess(null)
        }, 3000)
      }).catch((e) => {
        setErrors((state) => [...state, e.message])
        setSaving(false)
      })

      return;
    }

    setSaving(true)
    updateProfileFetch({
      body: JSON.stringify(character),
    }).then((data) => {
      setSuccess('Character updated')
      setSaving(false)

      setCharacter({
        ...character,
        moderation_score: data.moderation_score,
        is_not_safe: data.is_not_safe,
      })

      setModerationWarnings(
        Object.fromEntries(Object.entries(data.moderation_score).filter(([key, value]) => parseFloat(value) > 0.1))
      )

      setTimeout(() => {
        setSuccess(null)
      }, 3000)
    }).catch((e) => {
      if (e instanceof ApiError) {
        if ('explanation' in e.response) {
          setModerationError(e.response.explanation)
        } else if ('errors' in e.response) {
          setFormErrors(e.response.errors)
        } else {
          setErrors((state) => {
            return [...state, e.response.error]
          })
        }
      } else {
        setErrors((state) => {
          return [...state, e.message]
        })
      }

      setSaving(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [character, tab])

  const onAddDialogue = useCallback(() => {
    setCharacter({
      ...character,
      examples: [{ user: userMessage, character: characterMessage }, ...character.examples],
    })

    setUserMessage('')
    setCharacterMessage('')
  }, [character, userMessage, characterMessage])

  const onChangeExample = useCallback((key, index) => (e) => {
    setCharacter({
      ...character,
      examples: character.examples.map((example, i) => i === index ? { ...example, [key]: e.target.value } : example),
    })
  }, [character])

  const onDeleteExample = useCallback((index) => {
    setCharacter({
      ...character,
      examples: character.examples.filter((_, i) => i !== index),
    })
  }, [character])

  const openModerationGuide = useCallback((e) => {
    e.preventDefault()
    setModerationGuideOpen(true)
  }, [])

  const onChangeTab = useCallback((tab) => () => {
    setTab(tab)
    setSearchParams({ tab })
  }, [setTab, setSearchParams])

  const onChangePublish = useCallback((e) => {
    setCharacter({
      ...character,
      is_public: e.target.checked,
    })
  }, [character])

  const onChangeAdult = useCallback((e) => {
    setCharacter({
      ...character,
      is_adult: e.target.checked,
    })
  }, [character])

  const onImportClick = useCallback(() => {
    setImportOpen(!importOpen)
  }, [importOpen])

  const onImportFromUrlClick = useCallback(() => {
    setImportOpen(false)
    setImportFromUrl(true)
  }, [])

  const onChangeImportUrl = useCallback((e) => {
    setImportUrl(e.target.value)
  }, [])

  const onImportFromUrl = useCallback((e) => {
    e.stopPropagation()

    const interval = setInterval(() => {
      setCounter((prevCounter) => prevCounter + 1)
    }, 1000)

    setImportCharacterLoading(true)
    setImportFromUrl(false)

    importCharacterFetch({ body: JSON.stringify({ url: importUrl, id: character.id }) })
      .then((data) => {
        setCharacter({
          ...character,
          id: data.character.id,
        })

        const eventSource = new EventSource(
          `${process.env.REACT_APP_API_URL}/character/import/${data.task.id}?ts=${new Date().getTime()}`,
        )

        eventSource.onmessage = (event) => {
          const data = JSON.parse(event.data)

          setImportMessage(data.message)

          if (data.status === 'FINISHED') {
            getCharacterFetch()
          }

          if (data.status === 'ERROR') {
            setErrors((state) => [...state, data.error])
          }

          if (data.status === 'FINISHED' || data.status === 'ERROR') {
            setCounter(0)
            setImportOpen(false)
            setImportFromUrl(false)
            setImportUrl('')
            setImportCharacterLoading(false)
            setImportMessage('')
            clearInterval(interval)
            eventSource.close()
          }
        }

        eventSource.onerror = (event) => {
          // console.error('Import task error', event)
          // if (event.target.readyState === EventSource.CLOSED) {
          //   console.error('Connection was closed')
          // } else if (event.target.readyState === EventSource.CONNECTING) {
          //   console.error('Connection is being re-established')
          // } else {
          //   console.error('An unknown error occurred')
          // }

          setCounter(0)
          setImportCharacterLoading(false)
          clearInterval(interval)
        }

        eventSource.onclose = () => {
          setCounter(0)
          setImportCharacterLoading(false)
          clearInterval(interval)
        }
      }).catch((e) => {
        setErrors((state) => [...state, e.response.error])
        setCounter(0)
        setImportOpen(false)
        setImportFromUrl(false)
        setImportUrl('')
        setImportCharacterLoading(false)
        setImportMessage('')
        clearInterval(interval)
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importUrl, character])

  if (loading) {
    return <Loader />;
  }

  return (
    <GenerateContext.Provider value={generate}>
      <div className={styles.edit + ' ' + styles[tg.platform] + ' ' + (fullscreen ? styles.fullscreen : '') + ' ' + (keyboardOpen ? styles.keyboardOpen : '')} ref={scrollRef}>

      {success && (
        <div className={styles.success}>
          <div>{success}</div>
        </div>
      )}

      {errors.length > 0 && (
        <div className={styles.error}>
          {errors.map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </div>
      )}

      {
        moderationError && (
          <div className={styles.error}>
            <div>
              <p>Character can't be saved due to the following reasons:</p>
              <p>{moderationError.explanation}</p>
              <p>Forbidden categories: {moderationError.categories.join(', ')}</p>
              <button className={styles.button} onClick={() => setModerationError(null)}>Close</button>
            </div>
          </div>
        )
      }

      {moderationGuideOpen && (
        <ModerationGuide onClose={() => setModerationGuideOpen(false)} />
      )}

      { modal }

      {importFromUrl && (
          <div className={styles.overlay + ' ' + styles.importOverlay}>
            <div className={styles.close} onClick={() => setImportFromUrl(false)}><IoClose size={40} /></div>
            <div className={styles.modalField}>
              <span className={styles.text}>{t('character.importForm.enterUrl')}</span>
              <span className={styles.text}>{t('character.importForm.anyWebsite')}</span>
              <p className={styles.text}>{t('character.importForm.aiExtract')}</p>
              <p>{t('character.importForm.credits')}</p>
            </div>
            <div className={styles.field + ' ' + styles.importUrl}>
              <input 
                type="text" 
                placeholder={t('character.importForm.urlPlaceholder')} 
                value={importUrl} 
                onChange={onChangeImportUrl} 
                onClick={(e) => e.stopPropagation()} 
              />
              <button className={styles.button} onClick={onImportFromUrl}>
                {t('character.importForm.importButton')}
              </button>
            </div>
          </div>
        )}

      {
        (avatarUploadLoading || updateProfileLoading || importCharacterLoading) && (
          <div className={styles.spinnerOverlay}>
            { importCharacterLoading && <span>{t('edit.importMessage')}</span>}
            <span>{t('edit.updating')}</span>
            { importCharacterLoading && <span>{counter}</span>}
            <div className={styles.spinner} />
          </div>
        )
      }

      <div className={styles.container + ' ' + (importFromUrl || avatarUploadLoading || updateProfileLoading || importCharacterLoading || modal || moderationGuideOpen ? styles.blur : '')}>

        <div className={styles.tabs}>
          <div data-tab="profile" className={styles.tab + ' ' + (tab === 'profile' ? styles.active : '')} onClick={onChangeTab('profile')}>
            <span>{t('edit.tabs.profile')}</span>
          </div>
          <div data-tab="lora" className={styles.tab + ' ' + (tab === 'lora' ? styles.active : '')} onClick={onChangeTab('lora')}>
            <span>{t('edit.tabs.lora')}</span>
          </div>
          <div data-tab="dialogues" className={styles.tab + ' ' + (tab === 'dialogues' ? styles.active : '')} onClick={onChangeTab('dialogues')}>
            <span>{t('edit.tabs.dialogues')}</span>
          </div>
        </div>

        {tab === 'profile' && <Profile
          userData={user}
          character={character}
          onChange={onChange}
          generate={generate}
          avatarFileRef={avatarFileRef}
          avatarUploadLoading={avatarUploadLoading}
          newAvatar={newAvatar}
          onAvatarChange={onAvatarChange}
          onAvatarUpload={onAvatarUpload}
          onAvatarSaveClick={onAvatarSaveClick}
          onAvatarCancelClick={onAvatarCancelClick}
          onAvatarEditClick={onAvatarEditClick}
          onImportClick={onImportClick}
          importOpen={importOpen}
          formErrors={formErrors}
          setCharacter={setCharacter}
          onChangePublish={onChangePublish}
          onChangeAdult={onChangeAdult}
          openModerationGuide={openModerationGuide}
          moderationWarnings={moderationWarnings}
          onImportFromUrlClick={onImportFromUrlClick}
        />}

        {tab === 'lora' && <Lora
          character={character}
          setCharacter={setCharacter}
        />}

        {
          tab === 'dialogues' && (
            <>
              {
                Object.keys(moderationWarnings).length > 0 && (
                  <div className={styles.field + ' ' + styles.moderation_score}>
                    { character.is_not_safe && <span>{t('edit.moderationScore.notSafeFor')}</span> }
                    <span>{t('edit.moderationScore.moderationWarnings')}</span>
                    <div>
                      {
                        Object.entries(moderationWarnings).map(([key, value]) => (
                          value && <div key={key}>{key}: <span className={ value <= 0.2 ? styles.alert : value <= 0.5 ? styles.warning : styles.danger } >{value.toFixed(2)}</span></div>
                        ))
                      }
                    </div>
                    <a href="#generate" onClick={openModerationGuide}>{t('edit.moderationScore.whatDoesItMean')}</a>
                  </div>
                )
              }
              <div className={styles.field + ' ' + styles.dialogueUser}>
                <div className={styles.userAvatar}>U</div>
                <textarea placeholder={t('edit.dialogues.userQuestion')} ref={userMessageRef} onChange={(e) => setUserMessage(e.target.value)} value={userMessage} maxLength={500} />
                <span className={styles.counter}>{t('edit.dialogues.counter', { current: userMessage?.length || 0, max: 500 })}</span>
              </div>
              <div className={styles.field + ' ' + styles.dialogueCharacter}>
                <div className={styles.characterAvatar}>{character?.name[0]}</div>
                <textarea placeholder={t('edit.dialogues.characterAnswer', { name: character?.name })} ref={characterMessageRef} onChange={(e) => setCharacterMessage(e.target.value)} value={characterMessage} maxLength={500} />
                <span className={styles.counter}>{t('edit.dialogues.counter', { current: characterMessage?.length || 0, max: 500 })}</span>
              </div>
              <button className={styles.button + ' ' + styles.addDialogue} onClick={onAddDialogue}>
                <IoAddCircle size={20} />
              </button>

              <div className={styles.field}>
                {
                  character?.examples.map((example, index) => (
                    <div className={styles.dialogue} key={index}>
                      <div className={styles.field + ' ' + styles.dialogueUser}>
                        <div className={styles.userAvatar}>U</div>
                        <textarea placeholder="User question" value={example.user} maxLength={500} onChange={onChangeExample('user', index)} onFocus={() => setKeyboardOpen(true)} onBlur={() => setKeyboardOpen(false)} />
                        <span className={styles.counter}>{example.user?.length || 0}/500</span>
                      </div>
                      <div className={styles.field + ' ' + styles.dialogueCharacter}>
                        <div className={styles.characterAvatar}>{character?.name[0]}</div>
                        <textarea placeholder={`${character?.name} answer`} value={example.character} maxLength={500} onChange={onChangeExample('character', index)} onFocus={() => setKeyboardOpen(true)} onBlur={() => setKeyboardOpen(false)} />
                        <span className={styles.counter}>{example.character?.length || 0}/500</span>
                      </div>
                      <button className={styles.button + ' ' + styles.deleteDialogue} onClick={() => onDeleteExample(index)}><IoTrash size={20} /></button>
                    </div>
                  ))
                }
              </div>
            </>
          )
        }
      </div>

        <footer className={styles.footer + ' ' + (importFromUrl || avatarUploadLoading || updateProfileLoading || importCharacterLoading || modal ? styles.blur : '')}>
          <button disabled={saving} onClick={onSave} className={styles.button}>Save</button>
        </footer>
      </div>
    </GenerateContext.Provider>
  )
}