import React from 'react';
import { useTranslation } from "react-i18next";
import { Textarea } from './Textarea'

export function Description({ value, onChange, error, generate }) {
    const { t } = useTranslation();
    
    return (
        <Textarea
            name="description"
            text={t('character.form.description.description')}
            placeholder={t('character.form.description.placeholder')}
            onChange={onChange}
            maxLength={10000}
            value={value}
            error={error}
            generate={generate}
        />
    )
}