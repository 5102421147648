import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useApi } from '../../../hooks/useApi';
import { useLongPolling } from '../../../hooks/useLongPolling';
import { ImageLightbox } from './ImageLightbox';
import styles from '../Chat.module.css';
import { IoRepeat, IoAlertCircle } from "react-icons/io5";

const tg = window.Telegram.WebApp;

export const MessageImageGeneration = ({ uuid, message, onSuccess }) => {
    const { t } = useTranslation();
    const [status, setStatus] = useState(message.status);
    const [result, setResult] = useState(message.image_url);
    const [rating, setRating] = useState(message.rating);
    const [statusMessage, setStatusMessage] = useState(message.message);
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [isBlurred, setIsBlurred] = useState(['R', 'X', 'XXX'].includes(message.rating));

    const apiOptions = useMemo(() => ({
        baseUrl: process.env.REACT_APP_API_URL,
        requestOptions: {
            headers: {
                'x-init-data': tg.initData,
            }
        }
    }), []);

    const { user: { useImageGenerationTask, useShareImage } } = useApi(apiOptions);
    const { fetch: imageGenerationTask } = useImageGenerationTask(uuid);
    const { fetch: shareImage } = useShareImage(uuid);

    const handleImageGenerationTask = useCallback(({ status, task }) => {
        if (task.url && task.url.startsWith('https://')) {
            setStatus(task.status)
            setResult(task.url)
            setRating(task.rating)
            setIsBlurred(['R', 'X', 'XXX'].includes(task.rating))
            onSuccess(message, task)
        } else if (status === 'ok') {
            const { status, message: newStatusMessage } = JSON.parse(task.message.text)
            setStatus(status)
            setStatusMessage(newStatusMessage)
        }
    }, [onSuccess]);

    const handleImageGenerationTaskError = useCallback((error) => {
        console.error('Failed to fetch image generation task:', error);
    }, []);

    useLongPolling({
        fetch: imageGenerationTask,
        onSuccess: handleImageGenerationTask,
        onError: handleImageGenerationTaskError,
        enabled: status === 'PENDING' || status === 'RUNNING',
        dependencies: [uuid],
        interval: 1000,
    });

    const getStatusText = useCallback(() => {
        switch (status) {
            case 'PENDING':
                return t('chat.imageGeneration.starting');
            default:
                return statusMessage;
        }
    }, [status, statusMessage, t]);

    const handleShare = useCallback(async () => {
        const { prepared_message } = await shareImage()
        await tg.shareMessage(prepared_message.id);
    }, [uuid])

    return (
        <div className={styles.imageGeneration}>
            {status !== 'FINISHED' && status !== 'ERROR' ? (
                <>
                    <div className={styles.imageGenerationStatus}>
                        <div className={styles.imageGenerationSpinner} />
                        <div className={styles.imageGenerationStatusText}>{getStatusText()}</div>
                    </div>
                </>
            ) : status === 'ERROR' ? (
                <div className={styles.imageGenerationResult}>
                    <div className={styles.imageGenerationError}>
                        <IoAlertCircle className={styles.errorIcon} />
                        <span>{getStatusText()}</span>
                    </div>
                </div>
            ) : (
                <div className={styles.imageGenerationResult}>
                    <img
                        src={result}
                        alt="Generated"
                        className={`${styles.generatedImage} ${isBlurred ? styles.blurred : ''}`}
                        onClick={() => {
                            if (isBlurred) {
                                setIsBlurred(false);
                            } else {
                                setLightboxOpen(true);
                            }
                        }}
                    />
                    {isBlurred && (
                        <div className={styles.nsfwWarning}>
                            {t('chat.imageGeneration.rating', { rating })}
                        </div>
                    )}
                </div>
            )}
            {lightboxOpen && (
                <ImageLightbox
                    src={result}
                    onClose={() => setLightboxOpen(false)}
                    onShare={handleShare}
                />
            )}
        </div>
    );
}; 