import React, { useContext, useState, useCallback, useRef, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  IoChatbubbleOutline,
  IoHomeOutline,
  IoAddCircleOutline,
  IoPersonOutline,
} from "react-icons/io5";
import { PiHandCoinsLight } from "react-icons/pi";
import { useTranslation } from "react-i18next";

import styles from "./Home.module.css";

import { Loader } from "../../components/Loader";
import { UserContext } from "../../components/UserProvider";
import { Feed } from "../../components/Feed";
import { Characters } from "./tabs/Characters";
import { Tutorial } from "../../components/Tutorial/Tutorial";

const tg = window.Telegram.WebApp;

const TUTORIAL_COMPLETED_KEY = "tutorial_completed";

export function Home() {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const defaultTab = searchParams.get('tab');

  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const loaderRef = useRef(null);

  const [mainTab, setMainTab] = useState((defaultTab == 'null' ? 'feed' : defaultTab) || "feed");
  const [loadMoreHooks, setLoadMoreHooks] = useState({
    feed: null,
    characters: null,
  });

  useEffect(() => {
    tg.BackButton.hide()
  }, []);
  
  const [showTutorial, setShowTutorial] = useState(() => {
    const tutorialCompleted = localStorage.getItem(TUTORIAL_COMPLETED_KEY);
    const tutorialStep = localStorage.getItem("tutorial_current_step");
    return !tutorialCompleted && (!tutorialStep || parseInt(tutorialStep) < 5);
  });

  const feedTabRef = useRef(null);
  const charactersTabRef = useRef(null);
  const searchPanelRef = useRef(null);
  const browsingLevelRef = useRef(null);
  const charactersGridRef = useRef(null);

  useEffect(() => {
    // Check if the tutorial has been completed before
    const tutorialCompleted = localStorage.getItem(TUTORIAL_COMPLETED_KEY);
    if (!tutorialCompleted && user) {
      setShowTutorial(true);
    }
  }, [user]);

  const handleTutorialComplete = useCallback(() => {
    setShowTutorial(false);
    localStorage.removeItem("tutorial_current_step");
    localStorage.setItem(TUTORIAL_COMPLETED_KEY, "true");
  }, []);

  const onChangeMainTab = useCallback((tab) => () => {
    setSearchParams({ tab })
    setMainTab(tab);
  }, [setSearchParams]);

  const onAddCharacterClick = useCallback(() => {
    tg.BackButton.show()
    navigate('/character/create')
  }, [navigate])

  const onProfileClick = useCallback(() => {
    tg.BackButton.show()
    navigate('/profile')
  }, [navigate])

  const onChatsClick = useCallback(() => {
    tg.BackButton.show()
    navigate('/chats')
  }, [navigate])

  useEffect(() => {
    if (!loaderRef.current) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          if (mainTab === "feed" && loadMoreHooks.feed) {
            loadMoreHooks.feed();
          }

          if (mainTab === "characters" && loadMoreHooks.characters) {
            loadMoreHooks.characters();
          }
        }
      },
      { threshold: 1.0 }
    );

    observer.observe(loaderRef.current);
    return () => observer.disconnect();
  }, [loaderRef.current, loadMoreHooks, mainTab]);

  const onEarnClick = useCallback(() => {
    navigate('/earn')
  }, [navigate])

  if (!user) {
    return <Loader />;
  }

  return (
    <div className={styles.home + " " + styles[tg.platform]}>
      <div className={styles.mainTabs}>
        <div
          ref={feedTabRef}
          className={
            styles.mainTab +
            " " +
            (mainTab === "feed" ? styles.mainTabActive : "")
          }
          onClick={onChangeMainTab("feed")}
        >
          <span>{t('tabs.feed')}</span>
        </div>
        <div
          ref={charactersTabRef}
          className={
            styles.mainTab +
            " " +
            (mainTab === "characters" ? styles.mainTabActive : "")
          }
          onClick={onChangeMainTab("characters")}
        >
          <span>{t('tabs.characters')}</span>
        </div>
      </div>
      {mainTab === "feed" && <Feed setLoadMoreHooks={setLoadMoreHooks} />}
      {mainTab === "characters" && (
        <Characters 
          setLoadMoreHooks={setLoadMoreHooks} 
          searchPanelRef={searchPanelRef}
          browsingLevelRef={browsingLevelRef}
          charactersGridRef={charactersGridRef}
        />
      )}
      <div ref={loaderRef} style={{ height: "20px" }} />
      <footer className={styles.footer}>
        <div className={styles.footerTabs}>
          <button>
            <IoHomeOutline />
          </button>
          <button onClick={onChatsClick}>
            <IoChatbubbleOutline />
          </button>
          <button className={styles.earnButton} onClick={onEarnClick}>
            <PiHandCoinsLight />
            <label>{t('buttons.earn')}</label>
          </button>
          <button onClick={onAddCharacterClick}>
            <IoAddCircleOutline />
          </button>
          <button onClick={onProfileClick}>
            <IoPersonOutline />
          </button>
        </div>
      </footer>
      
      {showTutorial && (
        <Tutorial 
          onComplete={handleTutorialComplete}
          mainTab={mainTab}
          onChangeMainTab={onChangeMainTab}
          onNavigate={(path) => {
            navigate(path);
          }}
          refs={{
            feedTab: feedTabRef,
            charactersTab: charactersTabRef,
            searchPanel: searchPanelRef,
            browsingLevel: browsingLevelRef,
            charactersGrid: charactersGridRef
          }}
        />
      )}
    </div>
  );
}
