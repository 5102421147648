import React from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import styles from '../Chat.module.css';

export const MessageText = ({ text }) => {
    const editor = useEditor({
        extensions: [
            Document,
            Paragraph,
            Text,
            Bold,
            Italic,
        ],
        content: text,
        editable: false,
    });

    return (
        <div className={styles.messageText}>
            <EditorContent editor={editor} />
        </div>
    );
}; 