import React from 'react';
import { useTranslation } from "react-i18next";
import { WithContext as ReactTags } from "react-tag-input";

import styles from './field.module.css';

export function Tags({ tags, handleDelete, handleAddition, handleDrag, onTagUpdate, onClearAll, error, generate }) {
    const { t } = useTranslation();
    
    return (
        <>
            <div className={styles.field}>
                <ReactTags
                    autoFocus={false}
                    allowUnique={true}
                    autocomplete={false}
                    placeholder={t('character.form.tags.placeholder')}
                    labelField="name"
                    valueField="id"
                    idField="id"
                    tags={tags.map((tag) => ({ id: tag.id.toString(), name: tag.name }))}
                    inputFieldPosition="top"
                    handleDelete={handleDelete}
                    handleAddition={handleAddition}
                    handleDrag={handleDrag}
                    onTagUpdate={onTagUpdate}
                    editable={false}
                    clearAll
                    onClearAll={onClearAll}
                    maxTags={10}
                    allowAdditionFromPaste
                />
            </div>

            <span className={styles.text}>{t('character.form.tags.description')}</span>
        </>
    )
}