import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import styles from '../Chat.module.css';

export const MessageTyping = ({ character, onAvatarClick }) => {
    const { t } = useTranslation();
    const [dots, setDots] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            setDots(prev => prev.length >= 3 ? '' : prev + '.');
        }, 500);

        return () => clearInterval(interval);
    }, []);

    return <div className={styles.message + ' ' + styles.botMessage + ' ' + styles.typing}>
        <div className={styles.avatar} onClick={onAvatarClick}>
            <div style={character?.avatar_url ? { backgroundImage: `url(${character?.avatar_url})` } : { 'backgroundColor': '#18373a' }}> {character?.avatar_url ? '' : character?.name[0]}</div>
        </div>
        <div className={styles.messageContent}>
            <div className={styles.messageText}>{t('chat.messages.typing')}{dots}</div>
        </div>
    </div>
} 