import React, { useCallback, useState } from "react";
import { CommentText } from "../Comments/CommentText";

import styles from "./CommentsModal.module.css";

export function Comment({ comment, onReply, renderReply, onShowMoreReplies }) {
  const [loading, setLoading] = useState(false);

  const handleShowMoreReplies = useCallback(async () => {
    setLoading(true);
    await onShowMoreReplies(comment);
    setLoading(false);
  }, [comment, onShowMoreReplies]);

  return (
    <div className={styles.comment}>
      <div className={styles.commentHeader}>
        <span className={styles.commentAuthor}>@{comment.author.nickname}</span>
        <span className={styles.commentDate}>
          {new Date(comment.created_at).toLocaleDateString()}
        </span>
      </div>
      <CommentText text={comment.content} onReply={() => onReply(comment)} />
      {comment.children.length > 0 && (
        <div className={styles.replies}>
          {comment.children.map((reply) => renderReply(reply, comment))}
        </div>
      )}
      {comment._count.children > comment.children.length && (
        <div className={styles.commentFooter}>
          <span
            onClick={handleShowMoreReplies}
            className={styles.commentReplies}
          >
            {loading ? "Loading..." : `Show ${comment._count.children - comment.children.length} more replies`}
          </span>
        </div>
      )}
    </div>
  );
}
