import React from 'react';
import { useTranslation } from "react-i18next";
import { Input } from './Input'

export function Name({ value, onChange, error }) {
    const { t } = useTranslation();
    
    return (
        <Input
            value={value}
            onChange={onChange}
            maxLength={25}
            placeholder={t('character.form.name.placeholder')}
            text={t('character.form.name.description')}
            error={error}
        />
    )
}