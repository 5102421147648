import React, { useMemo, useCallback, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { UserContext } from '../../../components/UserProvider';
import styles from '../Chat.module.css';

export const MessageImageGenerationConfirmation = ({ aspect_ratio, style, kind, request, handlePicGenerate }) => {
    const { t } = useTranslation();
    const { user } = useContext(UserContext);

    const handleConfirm = useCallback(async () => {
        handlePicGenerate(aspect_ratio, style, kind, request);
    }, [handlePicGenerate, aspect_ratio, style, kind, request]);

    const cleanRequest = useMemo(() => request.replace(/<[^>]*>/g, ''), [request]);

    return (
        <div className={`${styles.message} ${styles.systemMessage}`}>
            <div className={styles.messageContent}>
                <div className={styles.buyCreditsMessage}>
                    <div className={styles.buyCreditsTitle}>{t('chat.imageGeneration.title')}</div>
                    <div className={styles.buyCreditsDescription}>
                        {t('chat.imageGeneration.description')}
                        <br />
                        <strong>{cleanRequest}</strong>
                        <br />
                        {t('chat.imageGeneration.balance', { stars: user.stars })}
                    </div>
                    <div className={styles.creditPacks}>
                        <button
                            className={styles.creditPack}
                            onClick={handleConfirm}
                        >
                            <div className={styles.creditAmount}>{t('chat.imageGeneration.button')}</div>
                            <div className={styles.creditPrice}>{t('chat.imageGeneration.cost')}</div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}; 