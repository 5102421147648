import React from 'react';
import { useTranslation } from "react-i18next";
import { Textarea } from './Textarea'

export function Scenario({ value, onChange, error, generate }) {
    const { t } = useTranslation();
    
    return (
        <Textarea
            name="scenario"
            text={t('character.form.scenario.description')}
            placeholder={t('character.form.scenario.placeholder')}
            onChange={onChange}
            maxLength={1000}
            value={value}
            error={error}
            generate={generate}
        />
    )
}