import { useEffect, useCallback, useRef } from 'react';

export const useLongPolling = ({
    fetch,
    onSuccess,
    onError,
    interval = 1000,
    maxRetries = 15,
    enabled = true,
    dependencies = []
}) => {
    const retryCountRef = useRef(maxRetries);
    const timeoutRef = useRef(null);

    const poll = useCallback(async () => {
        try {
            const response = await fetch();

            if (response.status === 'ok') {
                onSuccess(response);
                retryCountRef.current = maxRetries;
                timeoutRef.current = setTimeout(poll, interval);
            } else {
                throw new Error('Polling failed');
            }
        } catch (error) {
            if (retryCountRef.current > 0) {
                retryCountRef.current--;
                timeoutRef.current = setTimeout(poll, interval);
            } else {
                onError?.(error);
            }
        }
    }, [...dependencies]);

    useEffect(() => {
        if (!enabled) return;

        poll();

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [enabled, interval, onSuccess, onError, maxRetries, ...dependencies]);
}; 