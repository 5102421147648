import React, { useRef, useCallback, useContext, useMemo, useState } from 'react';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { MenuContext } from '../contexts/MenuContext';
import { MessageText } from './MessageText';
import { MessageImageGeneration } from './MessageImageGeneration';
import { MessageImageGenerationConfirmation } from './MessageImageGenerationConfirmation';
import { SystemMessage } from './SystemMessage';
import { MessageContextMenu } from './MessageContextMenu';
import styles from '../Chat.module.css';

const tg = window.Telegram.WebApp;

function formatText(text) {
    return text.replace(/«/g, '').replace(/»/g, '').replace(/"/g, '').replace(/'/g, '');
}

export const Message = ({
    message: _message,
    character,
    onAvatarClick,
    fetchInvoice,
    messagesContainerRef,
    handlePicGenerate,
    isSelectionMode,
    isSelected,
    onSelect,
    setIsSelectionMode,
    selectedImageMessage,
    onSuccessImageGeneration
}) => {
    const { openMenu, setOpenMenu } = useContext(MenuContext);
    const messageRef = useRef(null);
    const [timer, setTimer] = useState(null);

    const handleContextMenu = useCallback((event) => {
        event.preventDefault();
        const x = event.clientX || event.touches?.[0]?.clientX;
        const y = event.clientY || event.touches?.[0]?.clientY;
        setOpenMenu({ messageId: _message.id, position: { x, y } });
    }, [_message.id, setOpenMenu]);

    const handleTouchStart = useCallback((event) => {
        event.preventDefault();

        setOpenMenu(null);

        setTimer(setTimeout(() => {
            const touch = event.touches[0];
            tg.HapticFeedback.impactOccurred('light');
            setOpenMenu({ messageId: _message.id, position: { x: touch.clientX, y: touch.clientY } });
        }, 300));

        return () => clearTimeout(timer);
    }, [_message.id, setOpenMenu, timer]);

    const handleTouchEnd = useCallback(() => {
        clearTimeout(timer);
        setTimer(null);
    }, [timer]);

    const onClose = useCallback(() => {
        setOpenMenu(null);
    }, [setOpenMenu]);

    const message = useMemo(() => {
        if (_message.role === 'USER') {
            return {
                ..._message,
                selectable: _message.kind !== 'TOOL_CALL'
            };
        }

        try {
            const content = JSON.parse(_message.text);

            if (content.tool === 'pic_confirmation') {
                return {
                    selectable: false,
                    kind: 'pic_confirmation',
                    id: _message.id,
                    role: _message.role,
                    character: character,
                    created_at: _message.created_at,
                    args: {
                        aspect_ratio: content.arguments.aspect_ratio,
                        style: content.arguments.style,
                        kind: content.arguments.kind,
                        request: content.arguments.request
                    }
                };
            }

            if (content.tool === 'pic' && content.uuid) {
                return {
                    kind: 'tool',
                    id: _message.id,
                    role: _message.role,
                    character: character,
                    created_at: _message.created_at,
                    uuid: content.uuid,
                    status: content.status,
                    message: content.message,
                    image_url: content.image_url,
                    rating: content.rating,
                    isPicGeneration: true,
                    selectable: selectedImageMessage === null || selectedImageMessage === _message.id
                };
            }

            let text = '';

            if (content.game_master && content.game_master.description) {
                text += `<i>${formatText(content.game_master.description)}</i>\n\n`;
                text += `<p><i>${formatText(content.game_master.place)}</i></p>\n\n`;
                text += `<p><i>${formatText(content.game_master.relation)}</i></p>\n\n`;
            }

            if (content.line) {
                text += `<p>${formatText(content.line.text)}</p>`
            } else if (typeof content === 'string') {
                text += `<p>${formatText(content)}</p>`
            }

            return {
                id: _message.id,
                role: _message.role,
                character: character,
                created_at: _message.created_at,
                kind: _message.kind,
                selectable: _message.kind !== 'TOOL_CALL',
                text: formatText(text)
            };
        } catch (error) {
            console.warn(error)
            return {
                ..._message,
                selectable: true,
                text: formatText(_message.text)
            };
        }
    }, [_message, character, selectedImageMessage]);

    const isUser = useMemo(() => message.role === 'USER', [message]);

    const handleSelectFromMenu = useCallback((message) => {
        setIsSelectionMode(true);
        onSelect(message);
    }, [onSelect]);

    if (message.role === 'SYSTEM' && !message.isPicGeneration) {
        if (message.kind === 'pic_confirmation') {
            return <MessageImageGenerationConfirmation 
                uuid={message.uuid}
                aspect_ratio={message.args.aspect_ratio}
                style={message.args.style}
                kind={message.args.kind}
                request={message.args.request}
                handlePicGenerate={handlePicGenerate}
            />;
        }

        return <SystemMessage message={message} fetchInvoice={fetchInvoice} />;
    }

    return (
        <>
            <div 
                ref={messageRef}
                className={`${styles.message} ${isUser ? styles.userMessage : styles.botMessage} ${
                    openMenu?.messageId === _message.id ? styles.activeMessage : ''
                } ${styles[message.kind]} ${isSelected ? styles.selected : ''}`}
                onContextMenu={handleContextMenu}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                onClick={() => isSelectionMode && onSelect && onSelect(message)}
            >
                {isSelectionMode && (message.selectable) && (
                    <div className={styles.selectionOverlay}>
                        <div className={styles.selectionCheckmark}><IoCheckmarkCircleOutline /></div>
                    </div>
                )}
                {!isUser && message.kind !== 'GAME_MASTER' && character && (
                    <div className={styles.avatar} onClick={onAvatarClick}>
                        <div style={character?.avatar_url ? { backgroundImage: `url(${character?.avatar_url})` } : { 'backgroundColor': '#18373a' }}> {character?.avatar_url ? '' : character?.name[0]}</div>
                    </div>
                )}
                <div className={styles.messageContent}>
                    {message.isPicGeneration ? (
                        (
                            <MessageImageGeneration
                                uuid={message.uuid}
                                message={message}
                                onSuccess={onSuccessImageGeneration}
                            />
                        )
                    ) : (
                        <MessageText text={message.text} />
                    )}
                    <div className={styles.messageTime}>
                        {new Date(message.created_at).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit'
                        })}
                    </div>
                </div>
                {openMenu?.messageId === _message.id && (
                    <MessageContextMenu
                        characterId={character.id}
                        x={openMenu.position.x}
                        y={openMenu.position.y}
                        onClose={onClose}
                        message={message}
                        containerRef={messagesContainerRef}
                        messageRef={messageRef}
                        onSelect={handleSelectFromMenu}
                    />
                )}
            </div>
        </>
    );
}; 