import React, { useState, useCallback, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { IoClose } from "react-icons/io5";
import { useTranslation } from "react-i18next";

import { useApi } from "../../hooks/useApi";
import { CreatePostModal } from "../CreatePostModal/CreatePostModal";
import { CommentText } from "../Comments/CommentText";
import { Comment } from "./Comment";

import styles from "./CommentsModal.module.css";

export function CommentsModal({
  isOpen,
  onClose,
  post,
  onAddComment,
  replyTo: initialReplyTo,
}) {
  const { t } = useTranslation();
  
  const [showAddComment, setShowAddComment] = useState(false);
  const [replyTo, setReplyTo] = useState(initialReplyTo);
  const [comments, setComments] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const loaderRef = useRef(null);

  const {
    post: { useComments, useCommentsThread },
  } = useApi({
    baseUrl: process.env.REACT_APP_API_URL,
    requestOptions: {
      headers: {
        "x-init-data": window.Telegram.WebApp.initData,
      },
    },
  });

  const { fetch: fetchComments } = useComments(post.id);
  const { fetch: fetchCommentsThread } = useCommentsThread(post.id);

  const loadComments = useCallback(async () => {
    if (isLoading || !hasMore) return;

    setIsLoading(true);
    try {
      const response = await fetchComments({
        query: {
          page,
          pageSize: 10,
        },
      });

      setComments((prev) =>
        page === 1 ? response.comments : [...prev, ...response.comments]
      );
      setHasMore(response.hasMore);
      setPage((prev) => prev + 1);
    } catch (error) {
      console.error("Failed to load comments:", error);
    } finally {
      setIsLoading(false);
    }
  }, [page, hasMore, isLoading, fetchComments]);

  useEffect(() => {
    if (isOpen) {
      setPage(1);
      setHasMore(true);
      setComments([]);
      loadComments();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadComments();
        }
      },
      { threshold: 1.0 }
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => observer.disconnect();
  }, [loadComments]);

  const handleAddComment = useCallback(
    async (text) => {
      await onAddComment(text, replyTo?.id);
      setShowAddComment(false);
      setReplyTo(null);
      setPage(1);
      setHasMore(true);
      setComments([]);
      loadComments();
    },
    [onAddComment, replyTo, loadComments]
  );

  const handleReply = useCallback((comment) => {
    setReplyTo(comment);
    setShowAddComment(true);
  }, []);

  const onShowMoreReplies = useCallback(
    async (reply) => {
      const response = await fetchCommentsThread({
        query: {
          parent_id: reply.id,
        },
      });

      setComments((prev) => {
        return prev.map((comment) => {
          if (comment.id === reply.id) {
            return { ...comment, children: [...comment.children, ...response.comments] };
          }
          return comment;
        });
      });
    },
    [fetchCommentsThread]
  );

  const renderReply = useCallback(
    (reply) => {
      return (
        <div key={reply.id} className={styles.reply}>
          <div className={styles.commentHeader}>
            <span className={styles.commentAuthor}>
              @{reply.author.nickname}
            </span>
            <span className={styles.commentDate}>
            {new Date(reply.created_at).toLocaleTimeString()} {new Date(reply.created_at).toLocaleDateString()}
            </span>
          </div>
          {reply.reply_to && (
            <div className={styles.replyText}>
              <span className={styles.replyTextHeader}>
                {t('commentsModal.replyTo', { nickname: reply.reply_to.author.nickname })}
              </span>
              <CommentText
                text={reply.reply_to?.content}
                maxLength={60}
              />
            </div>
          )}
          <CommentText
            text={reply.content}
            onReply={() => handleReply(reply)}
          />
        </div>
      );
    },
    [handleReply, t]
  );

  const renderComment = useCallback(
    (comment) => (
      <Comment
        key={comment.id}
        comment={comment}
        onReply={handleReply}
        renderReply={renderReply}
        onShowMoreReplies={onShowMoreReplies}
      />
    ),
    [handleReply, renderReply, onShowMoreReplies]
  );

  if (!isOpen) return null;

  return createPortal(
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
        <div className={styles.modalHeader}>
          <h3>{t('commentsModal.title')}</h3>
          <button className={styles.closeButton} onClick={onClose}>
            <IoClose size={24} />
          </button>
        </div>

        <div className={styles.modalContent}>
         {comments.length === 0 && (
          <div className={styles.noComments}>
            <span>{t('post.comments.noComments')}</span>
          </div>
         )}
          {comments.map(renderComment)}

          {hasMore && (
            <div ref={loaderRef} className={styles.loader}>
              {isLoading ? t('commentsModal.loading') : ""}
            </div>
          )}
        </div>

        <div className={styles.modalFooter}>
          <button
            className={styles.addCommentButton}
            onClick={() => setShowAddComment(true)}
          >
            {t('commentsModal.addComment')}
          </button>
        </div>
      </div>

      <CreatePostModal
        isOpen={showAddComment}
        onClose={() => {
          setShowAddComment(false);
          setReplyTo(null);
        }}
        onSubmit={handleAddComment}
        title={replyTo ? t('commentsModal.replyTitle', { nickname: replyTo.author.nickname }) : t('commentsModal.addCommentTitle')}
        placeholder={replyTo ? t('commentsModal.replyPlaceholder') : t('commentsModal.commentPlaceholder')}
      />
    </div>,
    document.body
  );
}
