import React from 'react';
import { useTranslation } from "react-i18next";
import styles from '../Chat.module.css';

const tg = window.Telegram.WebApp;

export const BuyCreditsMessage = ({ onClose, fetchInvoice }) => {
    const { t } = useTranslation();
    
    const creditPacks = [
        { amount: 1000, price: 10, estimated_cost_in_usd: 2 },
        { amount: 5000, price: 50, estimated_cost_in_usd: 10 },
        { amount: 10000, price: 100, estimated_cost_in_usd: 20 }
    ];

    const handleBuyClick = (amount, price) => {
        fetchInvoice({ query: { amount } }).then((data) => {
            tg.openInvoice(data.link)
        })
    };

    return (
        <div className={styles.buyCreditsMessage}>
            <div className={styles.buyCreditsTitle}>{t('chat.buyCredits.title')}</div>
            <div className={styles.buyCreditsDescription}>
                {t('chat.buyCredits.description')}
            </div>
            <div className={styles.creditPacks}>
                {creditPacks.map(({ amount, price, estimated_cost_in_usd }) => (
                    <button
                        key={amount}
                        className={styles.creditPack}
                        onClick={() => handleBuyClick(amount, price)}
                    >
                        <div className={styles.creditAmount}>{amount}</div>
                        <div className={styles.creditPrice}>{t('chat.buyCredits.for', { price, usd: estimated_cost_in_usd })}</div>
                    </button>
                ))}
            </div>
        </div>
    );
}; 