import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { IoSend, IoClose, IoShareOutline } from 'react-icons/io5';
import { useEditor, EditorContent } from '@tiptap/react'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import { useTranslation } from "react-i18next";

import { useApi } from '../../hooks/useApi';
import { useLongPolling } from '../../hooks/useLongPolling';
import { Loader } from '../../components/Loader';
import { MessageTyping } from './components/MessageTyping';
import { Message } from './components/Message';
import { MenuContext } from './contexts/MenuContext';
import { CreatePostModal } from '../../components/CreatePostModal/CreatePostModal';
import { PayWall } from '../../components/PayWall/PayWall';
import styles from './Chat.module.css';

const tg = window.Telegram.WebApp;

const MAX_SELECTED_MESSAGES = 5;

export const Chat = () => {
    const { id } = useParams();
    return <ChatComponent key={id} />
}

const ChatComponent = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { character_id: characterId, id: chatId } = useParams();

    const messagesContainerRef = useRef(null);
    const containerRef = useRef(null);
    const scrollDebounceTimer = useRef(null);

    const [id, setId] = useState(chatId);
    const [character_id, setCharacter_id] = useState(characterId);
    const [messages, setMessages] = useState(new Map());
    const [intervalId, setIntervalId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [contextId, setContextId] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [sending, setSending] = useState(false);
    const [lastMessageId, setLastMessageId] = useState(0);
    const [height, setHeight] = useState();
    const [showCommands, setShowCommands] = useState(false);
    const [picStage, setPicStage] = useState(null);
    const [picParams, setPicParams] = useState({
        aspect: null,
        style: null,
        kind: null
    });
    const [openMenu, setOpenMenu] = useState(null);
    const [setupMessage, setSetupMessage] = useState(0);
    const [characterData, setCharacter] = useState(null);
    const [loadingMore, setLoadingMore] = useState(false);
    const [isSelectionMode, setIsSelectionMode] = useState(false);
    const [selectedMessages, setSelectedMessages] = useState(new Set());
    const [sharePostModalOpen, setSharePostModalOpen] = useState(false);
    const [sharePostText, setSharePostText] = useState('');
    const [sharePostLoading, setSharePostLoading] = useState(false);
    const [selectedImageMessage, setSelectedImageMessage] = useState(null);
    const [imagePrompt, setImagePrompt] = useState('');
    const [showScrollButton, setShowScrollButton] = useState(false);

    const paymentStatus = searchParams.get('payment');
    const [isPayWallOpen, setIsPayWallOpen] = useState(['success', 'canceled'].includes(paymentStatus));

    const setupMessages = [
        t('chat.setup.preparing'),
        t('chat.setup.environment'), 
        t('chat.setup.story'),
        t('chat.setup.ready')
    ];

    const editor = useEditor({
        extensions: [
            Document,
            Paragraph,
            Text,
            Bold.configure({
                HTMLAttributes: {
                    class: styles.bold,
                },
            }),
            Italic.configure({
                HTMLAttributes: {
                    class: styles.italic,
                },
            }),
        ],
        content: '',
        onUpdate: ({ editor }) => {
            setInputValue(editor.getHTML());
        },
        editorProps: {
            handleKeyDown: (view, event) => {
                if (event.key === 'Enter' && !event.shiftKey) {
                    event.preventDefault();
                    handleSendMessage();
                    return true;
                }
                return false;
            },
        },
    })

    const handlePicOptionSelect = (value) => {
        switch (picStage) {
            case 'aspect':
                setPicParams(prev => ({ ...prev, aspect: value }));
                setPicStage('style');
                break;
            case 'style':
                setPicParams(prev => ({ ...prev, style: value }));
                setPicStage('kind');
                break;
            case 'kind':
                setPicParams(prev => ({ ...prev, kind: value }));
                setPicStage('prompt');
                break;
            default:
                break;
        }
    };

    const handleCloseMenu = useCallback(() => {
        setShowCommands(false);
        setPicStage(null);
    }, []);

    const apiOptions = useMemo(() => ({
        baseUrl: process.env.REACT_APP_API_URL,
        requestOptions: {
            headers: {
                'x-init-data': tg.initData,
            }
        }
    }), []);

    const { user: { useChatMessages, useStartChat, useSendMessage, useMessageUpdates, useGetInvoice, useShareCharacter }, post: { useCreate } } = useApi(apiOptions);
    const { fetch: fetchMessages } = useChatMessages(id);
    const { fetch: messageUpdates } = useMessageUpdates(id);
    const { fetch: sendMessage } = useSendMessage(id);
    const { fetch: startChat } = useStartChat();
    const { fetch: fetchInvoice } = useGetInvoice();
    const { fetch: shareCharacter } = useShareCharacter(character_id);
    const { fetch: createPost } = useCreate(character_id);

    const [showCharacterInfo, setShowCharacterInfo] = useState(false);

    const handleMessagesUpdate = useCallback((data) => {
        if (data.messages.length > 0) {
            setMessages(prev => {
                const newMessages = new Map(prev);
                data.messages.forEach(msg => {
                    newMessages.set(msg.id, msg);
                });
                return newMessages;
            });
            setLastMessageId(data.messages[data.messages.length - 1].id);
            setSending(false);
        }
    }, [id]);

    const handleMessageError = useCallback((error) => {
        console.error('Failed to fetch messages:', error);
        setSending(false);
    }, []);

    const fetchMessagesUpdates = useCallback(() => {
        return messageUpdates({ query: { message_id: lastMessageId } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastMessageId]);

    useLongPolling({
        fetch: fetchMessagesUpdates,
        onSuccess: handleMessagesUpdate,
        onError: handleMessageError,
        enabled: id !== 'new' && lastMessageId > 0,
        dependencies: [lastMessageId, id],
        interval: 3000,
    });

    const fetchMessagesApi = useCallback(() => fetchMessages({ query: { page, limit: 20 } }, id), [page, id]);

    const handleInitialMessages = useCallback((data) => {
        if (data.status !== 'ok') {
            return;
        }

        if (data.messages.length > 0) {
            if (data.messages.length > 0) {
                setMessages(prev => {
                    const newMessages = new Map(prev);
                    data.messages.forEach(msg => {
                        newMessages.set(msg.id, msg);
                    });
                    return newMessages;
                });
                setLastMessageId(data.messages[data.messages.length - 1].id);
                setSending(false);
            }
        }

        setCharacter(data.character);
        setContextId(data.context_id);
        setHasMore(data.pagination?.pages > page);
        setLoading(false);
        setLoadingMore(false);
    }, [page, messagesContainerRef.current]);

    const handleInitialMessageError = useCallback((error) => {
        setError('Failed to load messages');
        setLoading(false);
        setLoadingMore(false);
    }, []);

    useLongPolling({
        fetch: fetchMessagesApi,
        onSuccess: handleInitialMessages,
        onError: handleInitialMessageError,
        enabled: id !== 'new' && (lastMessageId === 0 || loadingMore),
        dependencies: [id, page, loadingMore],
        interval: 3000,
    });

    useEffect(() => {
        tg.BackButton.show()
        tg.BackButton.onClick(() => {
            navigate(`/chats`)
        })

        return () => {
            tg.BackButton.hide()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (tg.isVersionAtLeast('6.9') && tg.platform === 'ios') {
            tg.onEvent('viewportChanged', ({ isStateStable }) => {
                if (isStateStable) {
                    setHeight(tg.viewportStableHeight)
                }
            })
        }

        return () => {
            tg.offEvent('viewportChanged')
        }
    }, [contextId, lastMessageId]);

    useEffect(() => {
        if (id === 'new') {
            startChat({ body: JSON.stringify({ character_id }) })
            .then((data) => {
                navigate(`/chat/${character_id}/${data.chat.id}`, { replace: true })
                setId(data.chat.id);
            }).catch((error) => {
                if (error.message.startsWith('You have reached the maximum number')) {
                    setIsPayWallOpen(true);
                    setLoading(false);
                } else {
                    console.error('Failed to start chat:', error);
                }
            })
        }
    }, [id, character_id, page, messagesContainerRef.current]);

    const commands = useMemo(() => [
        { command: '/start', description: t('chat.commands.start') },
        { command: '/pic', description: t('chat.commands.pic') },
    ], [t]);

    const PIC_OPTIONS = useMemo(() => {
        const options = {
            aspects: [
                { id: 'square', label: t('chat.pictureOptions.aspects.square') },
                { id: 'classic', label: t('chat.pictureOptions.aspects.classic') },
                { id: 'vertical', label: t('chat.pictureOptions.aspects.vertical') },
            ],
            styles: [
                { id: 'anime', label: t('chat.pictureOptions.styles.anime') },
                { id: 'realistic', label: t('chat.pictureOptions.styles.realistic') },
                { id: 'cartoon', label: t('chat.pictureOptions.styles.cartoon') },
            ],
            kinds: [
                { id: 'portrait', label: t('chat.pictureOptions.kinds.portrait') },
                { id: 'fullbody', label: t('chat.pictureOptions.kinds.fullbody') }
            ]
        }

        if (characterData?.is_adult) {
            options.kinds.push({ id: 'adult', label: t('chat.pictureOptions.kinds.adult') })
        }

        return options;
    }, [t, characterData?.is_adult]);
    const handleCommandClick = useCallback((command) => {
        if (command === '/pic') {
            setPicStage('options');
            setPicParams({ 
                aspect: 'vertical', 
                style: ['anime', 'cartoon', 'realistic'].includes(characterData?.kind.toLowerCase()) ? 
                    (characterData?.kind || 'realistic').toLowerCase() : 'realistic', 
                kind: null 
            });
        } else if (command === '/start') {
            navigate(`/chat/${character_id}/new`)
        } else {
            setInputValue(command);
            setShowCommands(false);
        }
    }, [characterData, navigate, character_id]);

    const handleSendMessage = useCallback(async () => {
        if (!editor || sending) return;
        const text = editor.getHTML();
        if (!text.trim()) return;

        const clearText = text.trim().replace(/<[^>]*>/g, '');

        if (clearText.startsWith('/start')) {
            handleCommandClick(clearText);
            return;
        }

        setSending(true);

        try {
            const { message } = await sendMessage({
                body: JSON.stringify({ content: text.trim() })
            });
            
            setMessages(prev => {
                const newMessages = new Map(prev);
                newMessages.set(message.id, message);
                return newMessages;
            });
            setLastMessageId(message.id);
            editor.commands.setContent('');
        } catch (error) {
            if (error.message.startsWith('You have reached the maximum number')) {
                setIsPayWallOpen(true);
            } else {
                console.error('Failed to send message:', error);
            }
            setSending(false);
        }
    }, [editor, sending, sendMessage]);

    const handlePicGenerate = useCallback(async (aspect_ratio, style, kind, request) => {
        const message = `/pic ${aspect_ratio} ${style} ${kind} ${request || ''}`;

        setInputValue(message);
        setPicStage(null);
        setShowCommands(false);

        try {
            const { message: msg } = await sendMessage({ body: JSON.stringify({ content: message }) })

            setMessages(prev => {
                const newMessages = new Map(prev);
                newMessages.set(msg.id, msg);
                return newMessages;
            });

            setInputValue('');
        } catch (error) {
            if (error.message.startsWith('You have reached the maximum number')) {
                setIsPayWallOpen(true);
            } else {
                console.error('Failed to send message:', error);
            }
            setSending(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [picParams]);

    const handleScroll = useCallback(() => {
        if (!messagesContainerRef.current || id === 'new' || loading || loadingMore) return;

        if (scrollDebounceTimer.current) {
            clearTimeout(scrollDebounceTimer.current);
        }

        const shouldShowButton = Math.abs(messagesContainerRef.current.scrollTop) >= messagesContainerRef.current.clientHeight;
        setShowScrollButton(shouldShowButton);

        scrollDebounceTimer.current = setTimeout(() => {
            const container = messagesContainerRef.current;

            if ((Math.abs(container.scrollTop) + container.clientHeight >= container.scrollHeight / 2) && hasMore && !loadingMore) {
                setLoadingMore(true);
                setPage(prev => prev + 1);
            }
        }, 300);
    }, [hasMore, loadingMore, loading, id]);

    const scrollToBottom = useCallback(() => {
        if (!messagesContainerRef.current) return;
        messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
        setShowScrollButton(false);
    }, [messagesContainerRef]);

    useEffect(() => {
        return () => {
            if (scrollDebounceTimer.current) {
                clearTimeout(scrollDebounceTimer.current);
            }
        };
    }, []);

    const toggleCharacterInfo = useCallback(() => {
        setShowCharacterInfo(prev => !prev);
    }, []);

    const handleHeaderClick = useCallback(() => {
        toggleCharacterInfo();
    }, [toggleCharacterInfo]);

    const onFocus = useCallback(() => {
        setTimeout(() => {
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight + 200;
        }, 300)

        const intervalId = setInterval(() => {
            document.documentElement.scrollTop = 0;
        }, 100)

        setIntervalId(intervalId)

        return () => {
            clearInterval(intervalId)
            setIntervalId(null)
        }
    }, []);

    const onBlur = useCallback(() => {
        if (intervalId) {
            clearInterval(intervalId)
            setIntervalId(null)
        }
    }, [intervalId]);

    const onShareClick = useCallback(async () => {
      const { prepared_message } = await shareCharacter()
      await tg.shareMessage(prepared_message.id);
    }, [shareCharacter])

    const sortedMessages = useMemo(() => {
        return Array.from(messages.values()).sort((a, b) =>
            new Date(b.created_at) - new Date(a.created_at)
        );
    }, [messages]);

    const toggleMessageSelection = useCallback((message) => {
        if (!message.selectable) return;

        setSelectedMessages(prev => {
            const newSelection = new Set(prev);
            
            if (newSelection.has(message.id)) {
                if (message.isPicGeneration) {
                    setSelectedImageMessage(null);
                }
                newSelection.delete(message.id);
                return newSelection;
            }

            if (newSelection.size >= MAX_SELECTED_MESSAGES) {
                alert(`You can select maximum ${MAX_SELECTED_MESSAGES} messages`);
                return prev;
            }

            if (message.isPicGeneration) {
                setSelectedImageMessage(message.id);
            }

            newSelection.add(message.id);
            return newSelection;
        });
    }, []);

    const handleShareSelected = () => {
        setSharePostModalOpen(true);
    };

    const handleSharePost = useCallback(async (text) => {
        setSharePostLoading(true);
        try {
            await createPost({ body: JSON.stringify({ messagesList: Array.from(selectedMessages.values()), character_id: characterData.id, content: text }) })
            setTimeout(() => {
                setSharePostModalOpen(false);
            }, 2000);
            setIsSelectionMode(false);
            setSelectedMessages(new Set());
        } catch (error) {
            alert(`Failed to create post: ${error}`);
        } finally {
            setSharePostLoading(false);
        }
    }, [selectedMessages, messages, characterData, sendMessage]);

    const handleImageGenerationSuccess = useCallback((_, task) => {
        const { message } = task
        setMessages(prev => {
            const newMessages = new Map(prev);
            newMessages.set(message.id, message);
            return newMessages;
        });
    }, []);

    const handlePromptSubmit = useCallback(() => {
        setPicStage('confirm');
    }, []);

    const closePayWall = useCallback(() => {
        setIsPayWallOpen(false);
    }, []);

    if (loading && page === 1) {
        return <Loader />
    }

    if (error) {
        return <div className={styles.error}>{error}</div>;
    }

    return (
        <MenuContext.Provider value={{ openMenu, setOpenMenu }}>

            <PayWall 
                isOpen={isPayWallOpen}
                onClose={closePayWall}
                paymentStatus={paymentStatus}
                message="You've reached your daily message limit! Upgrade to achieve thousands of messages and more exclusive features!"
            />
            <div
                className={`${styles.chatContainer} ${styles[tg.platform]} ${
                    openMenu ? styles.hasActiveMenu : ''
                }`}
                ref={containerRef}
                style={{
                    '--height': height ? `${height}px` : '100vh',
                }}
            >
                <div className={styles.chatContent}
                    style={{
                        '--background-image': characterData?.avatar_url
                            ? `url(${characterData?.avatar_url})`
                            : 'none',
                        '--height': height ? `${height}px` : '100vh',
                    }}
                >
                    <div className={styles.characterInfo + ' ' + (showCharacterInfo ? styles.visible : '')}>
                        <button
                            className={styles.closeButton}
                            onClick={toggleCharacterInfo}
                            aria-label="Close character info"
                        >
                            <IoClose size={24} />
                        </button>
                        <div className={styles.actions}>
                            <div className={styles.share}>
                                <button onClick={onShareClick} className={styles.button}>
                                    <IoShareOutline />
                                </button>
                            </div>
                        </div>
                        <div className={styles.characterInfoAvatar} >
                            <div style={characterData?.avatar_url ? { backgroundImage: `url(${characterData?.avatar_url})` } : { 'backgroundColor': '#18373a' }}> {characterData?.avatar_url ? '' : 'no avatar'}</div>
                        </div>
                        <div className={styles.characterInfoName}>{characterData?.name}</div>

                        <div className={styles.characterInfoStats}>
                            <div className={styles.characterInfoStat}>
                                <div className={styles.characterInfoStatValue}>{characterData?._count?.chats || 0}</div>
                                <div className={styles.characterInfoStatLabel}>Chats</div>
                            </div>
                            <div className={styles.characterInfoStat}>
                                <div className={styles.characterInfoStatValue}>{characterData?._count?.likes || 0}</div>
                                <div className={styles.characterInfoStatLabel}>Likes</div>
                            </div>
                            <div className={styles.characterInfoStat}>
                                <div className={styles.characterInfoStatValue}>{characterData?._count?.favorites || 0}</div>
                                <div className={styles.characterInfoStatLabel}>Favs</div>
                            </div>
                        </div>

                        {characterData?.author && (
                            <div className={styles.characterInfoAuthor}>
                                Created by {characterData.author.nickname}
                            </div>
                        )}

                        {characterData?.bio && (
                            <div className={styles.characterInfoSection}>
                                <h3>Bio</h3>
                                <div className={styles.characterInfoDescription}>{characterData.bio}</div>
                            </div>
                        )}

                        {characterData?.fandom && (
                            <div className={styles.characterInfoSection}>
                                <h3>Fandom</h3>
                                <div className={styles.characterInfoDescription}>{characterData.fandom}</div>
                            </div>
                        )}

                        {characterData?.tags?.length > 0 && (
                            <div className={styles.characterInfoSection}>
                                <h3>Tags</h3>
                                <div className={styles.characterInfoTags}>
                                    {characterData.tags.map(tag => (
                                        <span key={tag.id} className={styles.characterInfoTag}>
                                            {tag.name}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        )}

                        {characterData?.telegram_bots?.length > 0 && (
                            <div className={styles.characterInfoSection}>
                                <h3>Available on Telegram</h3>
                                <div className={styles.characterInfoTags}>
                                    {characterData.telegram_bots.map(bot => (
                                        <span key={bot.id} className={styles.characterInfoTag}>
                                            <a href={`https://t.me/${bot.botname}`} target="_blank" rel="noopener noreferrer">
                                                @{bot.botname}
                                            </a>
                                        </span>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={styles.header} onClick={handleHeaderClick}>
                        { characterData?.avatar_url && (
                            <div className={styles.avatar} style={{ cursor: 'pointer' }}>
                                <img src={characterData?.avatar_url} alt={characterData?.name} />
                            </div>
                        )}
                        <div className={styles.title} style={{ cursor: 'pointer' }}>{characterData?.name}</div>
                    </div>
                    <div className={styles.messagesContainer} ref={messagesContainerRef} onScroll={handleScroll}>
                        <div className={styles.messagesList}>
                            {
                                sortedMessages.length > 0 && sortedMessages[0].role === 'USER' && (
                                    <MessageTyping character={characterData} onAvatarClick={toggleCharacterInfo} />
                                )
                            }
                            {
                                (sortedMessages.length === 0 || (sortedMessages[0].role === 'SYSTEM' && sortedMessages.length === 1)) && (
                                    <div className={styles.messagesContainer + ' ' + styles.messagesContainerEmpty}>
                                        <div className={styles.messagesContainerBody}>
                                            {setupMessages[setupMessage]}
                                        </div>
                                        <div className={styles.loadingSpinner}></div>
                                    </div>
                                )
                            }
                            {sortedMessages.map((message) => (
                                <Message
                                    key={message.id}
                                    message={message}
                                    character={characterData}
                                    onAvatarClick={toggleCharacterInfo}
                                    fetchInvoice={fetchInvoice}
                                    messagesContainerRef={messagesContainerRef}
                                    handlePicGenerate={handlePicGenerate}
                                    isSelectionMode={isSelectionMode}
                                    isSelected={selectedMessages.has(message.id)}
                                    onSelect={toggleMessageSelection}
                                    setIsSelectionMode={setIsSelectionMode}
                                    selectedImageMessage={selectedImageMessage}
                                    onSuccessImageGeneration={handleImageGenerationSuccess}
                                />
                            ))}
                            {loadingMore && (
                                <div className={styles.loadingMore}>
                                    <div className={styles.loadingSpinner}></div>
                                    {t('chat.messages.loadingMore')}
                                </div>
                            )}
                        </div>
                    </div>
                    
                    {showScrollButton && (
                        <button 
                            className={`${styles.scrollDownButton} ${showScrollButton ? styles.visible : ''}`} 
                            onClick={scrollToBottom}
                            aria-label="Scroll to bottom"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                <path d="M12 15.5a1 1 0 01-.71-.29l-4-4a1 1 0 111.42-1.42L12 13.1l3.3-3.18a1 1 0 111.38 1.44l-4 3.86a1 1 0 01-.68.28z"/>
                            </svg>
                        </button>
                    )}
                    
                    <div className={styles.footer}>
                        <div className={styles.inputContainer}>
                            <button
                                className={`${styles.menuButton} ${(showCommands || picStage) ? styles.active : ''}`}
                                onClick={() => {
                                    if (picStage) {
                                        handleCloseMenu();
                                    } else {
                                        setShowCommands(!showCommands);
                                    }
                                }}
                            >
                                <span className={styles.menuIcon}>
                                    <span className={styles.menuIconBar}></span>
                                </span>
                            </button>
                            {(showCommands || picStage) && (
                                <div className={styles.commandsMenu}>
                                    {picStage ? (
                                        picStage === 'confirm' ? (
                                            <div className={styles.picConfirm}>
                                                <div className={styles.picConfirmText}>
                                                    You selected {picParams.aspect} format, {picParams.style} style, {picParams.kind} kind.
                                                    <br />
                                                    Prompt: {imagePrompt}
                                                    <br />
                                                    {t('chat.pictureOptions.confirm')}
                                                </div>
                                                <div className={styles.picConfirmButtons}>
                                                    <button
                                                        className={styles.picConfirmButton}
                                                        onClick={() => setPicStage('prompt')}
                                                    >
                                                        Back
                                                    </button>
                                                    <button
                                                        className={styles.picConfirmButton}
                                                        onClick={() => handlePicGenerate(picParams.aspect, picParams.style, picParams.kind, imagePrompt)}
                                                    >
                                                        {t('chat.pictureOptions.generate')}
                                                    </button>
                                                </div>
                                            </div>
                                        ) : picStage === 'prompt' ? (
                                            <div className={styles.promptInput}>
                                                <div className={styles.promptInputText}>
                                                    Describe some additional details:
                                                </div>
                                                <input
                                                    type="text"
                                                    value={imagePrompt}
                                                    onChange={(e) => setImagePrompt(e.target.value)}
                                                    placeholder="Enter your image prompt (optional)"
                                                    className={styles.input}
                                                />
                                                <div className={styles.promptButtons}>
                                                    <button
                                                        className={styles.picConfirmButton}
                                                        onClick={() => setPicStage('options')}
                                                    >
                                                        Back
                                                    </button>
                                                    <button
                                                        className={styles.picConfirmButton}
                                                        onClick={handlePromptSubmit}
                                                    >
                                                        Continue
                                                    </button>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className={styles.picOptions}>
                                                <div className={styles.picOptionSection}>
                                                    <div className={styles.picOptionRow}>
                                                        <div className={styles.toggleGroup}>
                                                            {PIC_OPTIONS.aspects.map((option) => (
                                                                <button
                                                                    key={option.id}
                                                                    className={`${styles.toggleButton} ${picParams.aspect === option.id ? styles.active : ''}`}
                                                                    onClick={() => setPicParams(prev => ({ ...prev, aspect: option.id }))}
                                                                >
                                                                    {option.label}
                                                                </button>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    
                                                    <div className={styles.picOptionRow}>
                                                        <div className={styles.toggleGroup}>
                                                            {PIC_OPTIONS.styles.map((option) => (
                                                                <button
                                                                    key={option.id}
                                                                    className={`${styles.toggleButton} ${picParams.style === option.id ? styles.active : ''}`}
                                                                    onClick={() => setPicParams(prev => ({ ...prev, style: option.id }))}
                                                                >
                                                                    {option.label}
                                                                </button>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    
                                                    <div className={styles.picOptionRow}>
                                                        <div className={styles.toggleGroup}>
                                                            {PIC_OPTIONS.kinds.map((option) => (
                                                                <button
                                                                    key={option.id}
                                                                    className={`${styles.toggleButton} ${picParams.kind === option.id ? styles.active : ''}`}
                                                                    onClick={() => setPicParams(prev => ({ ...prev, kind: option.id }))}
                                                                >
                                                                    {option.label}
                                                                </button>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <button
                                                    className={styles.picConfirmButton}
                                                    onClick={() => setPicStage('prompt')}
                                                    disabled={!picParams.kind}
                                                >
                                                    Continue
                                                </button>
                                            </div>
                                        )
                                    ) : (
                                        commands.map((cmd) => (
                                            <button
                                                key={cmd.command}
                                                className={styles.commandButton}
                                                onClick={() => handleCommandClick(cmd.command)}
                                            >
                                                <span className={styles.commandText}>{cmd.command}</span>
                                                <span className={styles.commandDescription}>{cmd.description}</span>
                                            </button>
                                        ))
                                    )}
                                </div>
                            )}
                            <EditorContent
                                editor={editor}
                                className={styles.input}
                                onFocus={onFocus}
                                onBlur={onBlur}
                            />
                            <button
                                className={styles.sendButton}
                                onClick={handleSendMessage}
                                disabled={sending}
                            >
                                {sending ? 'Sending...' : <IoSend size={20} color="#104248" />}
                            </button>
                        </div>
                    </div>
                </div>

                {isSelectionMode && (
                    <div className={styles.selectionControls}>
                        <button 
                            className={styles.button}
                            onClick={() => {
                                setIsSelectionMode(false);
                                setSelectedMessages(new Set());
                            }}
                        >
                            <IoClose /> Cancel
                        </button>
                        <span className={styles.selectionCount}>
                            {selectedMessages.size}/{MAX_SELECTED_MESSAGES} selected
                        </span>
                        <button
                            className={styles.button}
                            onClick={handleShareSelected}
                            disabled={selectedMessages.size === 0}
                        >
                            Create post
                        </button>
                    </div>
                )}

                <CreatePostModal
                    isOpen={sharePostModalOpen}
                    onClose={() => setSharePostModalOpen(false)}
                    onSubmit={handleSharePost}
                    loading={sharePostLoading}
                    title="Create post from messages"
                    placeholder={`Comment your conversation with ${characterData?.name}`}
                />
            </div>
        </MenuContext.Provider>
    );
};