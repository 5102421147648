import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
  useContext,
} from "react";
import { IoArrowDown, IoPersonOutline } from "react-icons/io5";
import { useSearchParams } from "react-router-dom";
import { IoHeartOutline, IoBookmarkOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";

import styles from "./Feed.module.css";

import { Loader } from "../Loader";
import { useApi } from "../../hooks/useApi";
import { UserContext } from "../UserProvider";
import { Post } from "../Post/Post";

const tg = window.Telegram.WebApp;

const ratings = {
  PG: ["PG"],
  PG_13: ["PG", "PG_13"],
  R: ["PG", "PG_13", "R"],
  X: ["PG", "PG_13", "R", "X"],
  XXX: ["PG", "PG_13", "R", "X", "XXX"],
};

export function Feed({ nickname, setLoadMoreHooks, isFeed = false }) {
  const { t } = useTranslation();
  
  const [searchParams, setSearchParams] = useSearchParams();
  const tagsParam = searchParams.get("tags");
  const tabParam = searchParams.get("feed_tab");
  const isAdultParam = searchParams.get("is_adult");
  const browseLevelsParam = searchParams.get("browse_levels");
  const [loading, setLoading] = useState(true);
  const [tags] = useState([]);
  const [moreTagsOpen, setMoreTagsOpen] = useState(false);
  const [needIncrementPage, setNeedIncrementPage] = useState(false);
  const [posts, setPosts] = useState(new Map());

  const { user } = useContext(UserContext);

  const [requestParams, setRequestParams] = useState({
    nickname: nickname || null,
    page: 1,
    pageSize: 10,
    tags: [],
    tab: null,
    browse_levels: [],
    is_adult: false,
    reset: true,
  });

  const feedRef = useRef(null);

  const apiOptions = useMemo(
    () => ({
      baseUrl: process.env.REACT_APP_API_URL,
      requestOptions: {
        headers: {
          "x-init-data": tg.initData,
        },
      },
    }),
    []
  );

  const {
    post: { useRecommended, useFeed },
  } = useApi(apiOptions);

  const { fetch: getRecommendedFetch, loading: getRecommendedLoading } = useRecommended();
  const { fetch: getFeedFetch, loading: getFeedLoading } = useFeed();

  useEffect(() => {
    if (!needIncrementPage) return;
    setRequestParams((prevParams) => ({
      ...prevParams,
      page: prevParams.page + 1,
      reset: false,
    }));
  }, [needIncrementPage]);

  const loadMore = useCallback(() => {
    const nextPage = Math.ceil(posts.size / requestParams.pageSize) + 1;

    if (nextPage > requestParams.page) {
      setNeedIncrementPage(true);
    }
  }, [posts, requestParams]);

  useEffect(() => {
    setLoadMoreHooks((prev) => ({ ...prev, feed: loadMore }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMore]);

  useEffect(() => {
    setRequestParams({
      nickname: nickname || null,
      page: 1,
      pageSize: 10,
      tags: tagsParam ? tagsParam.split(",").map((tag) => parseInt(tag)) : [],
      feed_tab: tabParam ? tabParam : null,
      browse_levels: browseLevelsParam ? browseLevelsParam.split(",") : [],
      is_adult: isAdultParam === "true",
      reset: true,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!requestParams) return;
    setSearchParams({
      tab: 'feed',
      tags: requestParams.tags.join(","),
      browse_levels: requestParams.browse_levels.join(","),
      feed_tab: requestParams.feed_tab,
    });

    let query = {
      ...requestParams,
      tab: requestParams.feed_tab,
    };

    query = Object.fromEntries(
      Object.entries(query).filter(([_, v]) => !!v && v !== "null")
    );

    const fetch = isFeed ? getFeedFetch : getRecommendedFetch

    fetch({ query }).then((data) => {
      if (requestParams.reset) {
        setPosts(new Map(data.posts.map((post) => [post.id, post])));
      } else {
        setPosts((prevPosts) => {
          const newPosts = new Map(data.posts.map((post) => [post.id, post]));
          return new Map([...prevPosts, ...newPosts]);
        });
      }
      setNeedIncrementPage(false);
      setLoading(false);
    });
  }, [requestParams]);

  const onTagClick = useCallback(
    (tagId) => () => {
      setRequestParams((prevParams) => {
        let newSearchTags = [...prevParams.tags];

        if (prevParams.tags.includes(tagId)) {
          newSearchTags = newSearchTags.filter((id) => id !== tagId);
        } else {
          newSearchTags.push(tagId);
        }

        return { ...prevParams, tags: newSearchTags, page: 1, reset: true };
      });
    },
    []
  );

  const onBrowseLevelClick = useCallback(
    (level) => () => {
      setRequestParams((prevParams) => {
        let newSearchLevels = [...prevParams.browse_levels];

        if (prevParams.browse_levels.includes(level)) {
          newSearchLevels = newSearchLevels.filter((id) => id !== level);
        } else {
          newSearchLevels.push(level.replace("-", "_"));
        }

        return {
          ...prevParams,
          browse_levels: newSearchLevels,
          page: 1,
          reset: true,
        };
      });
    },
    []
  );

  const onTabClick = useCallback(
    (_tab) => () => {
      setRequestParams((prevParams) => {
        if (prevParams.feed_tab === _tab)
          return { ...prevParams, feed_tab: null, page: 1, reset: true };
        return { ...prevParams, feed_tab: _tab, page: 1, reset: true };
      });
    },
    []
  );

  const onMoreTagsClick = useCallback(() => {
    setMoreTagsOpen((prev) => !prev);
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={styles.feed}>
      {(getFeedLoading || getRecommendedLoading) && <div className={styles.spinner} />}
      <header>
        {tags.length > 0 && (
          <div className={styles.tags}>
            {tags.slice(0, moreTagsOpen ? tags.length : 5).map((tag) => (
              <span
                key={tag.id}
                data-tag-id={tag.id}
                className={
                  styles.tag +
                  " " +
                  (requestParams.tags.includes(tag.id) ? styles.tagActive : "")
                }
                onClick={onTagClick(tag.id)}
              >
                {tag.name}
              </span>
            ))}
            {tags.length > 5 && (
              <span
                className={
                  styles.more +
                  " " +
                  styles.tag +
                  " " +
                  (moreTagsOpen ? styles.moreOpen : "")
                }
                onClick={onMoreTagsClick}
              >
                {moreTagsOpen ? t('feed.less') : t('feed.more')} {<IoArrowDown />}
              </span>
            )}
          </div>
        )}

        {user.filter_rating && ratings[user.filter_rating] && (
          <div className={styles.browsingLevel}>
            {ratings[user.filter_rating].map((level) => (
              <div
                key={level}
                className={
                  styles.browsingLevelItem +
                  " " +
                  (requestParams.browse_levels.includes(level)
                    ? styles.active
                    : "")
                }
                onClick={onBrowseLevelClick(level)}
              >
                {level.replace("_", "-")}
              </div>
            ))}
          </div>
        )}

        <div className={styles.tabs}>
          { !isFeed && (
            <div
              data-tab="my"
              className={
                styles.tab +
                " " +
                (requestParams.feed_tab === "my" ? styles.tabActive : "")
              }
              onClick={onTabClick("my")}
            >
              <IoPersonOutline />
              <span>{t('feed.tabs.my')}</span>
            </div>
          )}
          <div
            data-tab="liked"
            className={
              styles.tab +
              " " +
              (requestParams.feed_tab === "liked" ? styles.tabActive : "")
            }
            onClick={onTabClick("liked")}
          >
            <IoHeartOutline />
            <span>{t('feed.tabs.likes')}</span>
          </div>
          <div
            data-tab="favorite"
            className={
              styles.tab +
              " " +
              (requestParams.feed_tab === "favorite" ? styles.tabActive : "")
            }
            onClick={onTabClick("favorite")}
          >
            <IoBookmarkOutline />
            <span>{t('feed.tabs.favorites')}</span>
          </div>
        </div>
      </header>
      <div className={styles.feed} ref={feedRef}>
        {posts.size === 0 && (
          <div className={styles.noPosts}>{t('feed.noPostsFound')}</div>
        )}

        {Array.from(posts.values()).map((post) => (
          <Post key={post.id} post={post} character={post.character} />
        ))}

        {(getFeedLoading || getRecommendedLoading) &&
          <div className={styles.emptyPost} />
        }
      </div>
    </div>
  );
}
