import React, { useMemo } from 'react';
import { MessageText } from './MessageText';
import { MessageImageGeneration } from './MessageImageGeneration';
import styles from '../Chat.module.css';

function formatText(text) {
    return text.replace(/«/g, '').replace(/»/g, '').replace(/"/g, '').replace(/'/g, '');
}

export const MessageView = ({
    message: _message,
    character,
}) => {

    const message = useMemo(() => {
        if (_message.kind === 'GAME_MASTER') {
            try {
                const json = JSON.parse(_message.text);
                let text = '';

                if (json.game_master && json.game_master.description) {
                    text += `<i>${formatText(json.game_master.description)}</i>\n\n`;
                    text += `<p><i>${formatText(json.game_master.place)}</i></p>\n\n`;
                    text += `<p><i>${formatText(json.game_master.relation)}</i></p>\n\n`;
                }

                return {
                    ..._message,
                    text: formatText(text)
                };
            } catch (error) {
                return _message;
            }
        }

        if (_message.kind === 'TOOL_CALL' && _message.role === 'SYSTEM') {
            const content = JSON.parse(_message.text);

            return {
                kind: 'tool',
                id: _message.id,
                role: _message.role,
                character: character,
                created_at: _message.created_at,
                uuid: content.uuid,
                status: content.status,
                message: content.message,
                image_url: content.image_url,
                rating: content.rating,
                isPicGeneration: true
            };
        }

        return _message;
    }, [_message]);

    const isUser = useMemo(() => message.role === 'USER', [message]);

    return (
        <>
            <div 
                className={`${styles.message} ${styles[message.kind]} ${isUser ? styles.userMessage : styles.botMessage}`}
            >
                {!isUser && message.kind !== 'GAME_MASTER' && character && (
                    <div className={styles.avatar}>
                        <div style={character?.avatar_url ? { backgroundImage: `url(${character?.avatar_url})` } : { 'backgroundColor': '#18373a' }}> {character?.avatar_url ? '' : character?.name[0]}</div>
                    </div>
                )}
                <div className={styles.messageContent}>
                    {message.isPicGeneration ? (
                        (
                            <MessageImageGeneration
                                uuid={message.uuid}
                                message={message}
                            />
                        )
                    ) : (
                        <MessageText text={message.text} />
                    )}
                    <div className={styles.messageTime}>
                        {new Date(message.created_at).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit'
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}; 