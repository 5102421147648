
const digest = '1de786bb5151506838f6d20ce9ae3d134c2c6d2d2bce1c96c1816a29fb4c2126';
const css = `._container_18f8u_1 {
	--brand-color: #744ed4;
	--brand-color-dark: #5629c6;
	--color-text-primary: #111111;
	--color-text-primary-inverted: #fff;
	--color-text-link: var(--brand-color);
	--size-large: 24px;
	--size-medium: 16px;
	--size-small: 12px;
	--size-xSmall: 8px;

	--color-white: #fff;
	--color-gray-100: hsla(0, 0%, 93.7%, 0.49019607843137253);
	--color-gray-200: #f2f2f2;
	--color-gray-300: #eaeaea;
	--color-gray-400: #bdbdbd;
	--color-gray-500: #828282;
	--color-gray-600: #7e7e7e;
	--color-gray-700: #8a8f98;
	--color-gray-800: #2d2f36;

	--font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI',
		Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
		'Segoe UI Emoji', 'Segoe UI Symbol';

	background: hsl(0deg 0% 0% / 75%);
	font-family: var(--font-family);
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 999999;
	backdrop-filter: blur(1px);
	display: flex;
	justify-content: center;
	align-items: center;
}

@media only screen and (max-width: 420px) {
	._card_18f8u_41 {
		max-width: 100vw !important;
		height: 100%;
		border-radius: 0;
	}

	._cardContents_18f8u_47 {
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	._form_18f8u_53 {
		height: 100%;
	}

	._textareaLabel_18f8u_57 {
		flex: 1;
	}

	._form_18f8u_53 textarea {
		height: 100%;
	}

	._ad_18f8u_65 {
		display: none;
	}
}

._card_18f8u_41 {
	border-radius: var(--size-xSmall);
	padding: 20px;
	width: 420px;
	max-width: calc(100vw - 1rem);
	background: white;
}

._title_18f8u_78 {
	font-size: 20px;
	color: var(--color-text-primary);
}

._subtitle_18f8u_83 {
	font-size: 13px;
	margin-top: var(--size-medium) !important;
	font-weight: 400;
	color: #8c8b92;
}

._form_18f8u_53 {
	padding-top: var(--size-large);
	display: flex;
	flex-direction: column;
	gap: var(--size-small);
	width: 100%;
}

._form_18f8u_53 label {
	display: flex;
	flex-direction: column;
	font-size: 11px !important;
	font-weight: 500;
	color: var(--color-gray-500);
}

._form_18f8u_53 input {
	height: 28px;
}

._form_18f8u_53 textarea {
	font-family: var(--font-family);
	min-height: 80px;
}

._form_18f8u_53 input,
._form_18f8u_53 textarea {
	background-color: var(--color-white);
	border: 1px solid #dcdbdd;
	border-radius: 6px;
	color: var(--color-gray-500);

	font-size: 13px;
	margin: 8px 0;
	outline: none;
	padding: 4px 6px;
	width: calc(100%);
}

._form_18f8u_53 button {
	align-items: center;
	display: flex;
	justify-content: center;
	height: auto;
	color: var(--color-text-primary-inverted);
	background-color: var(--brand-color);
	text-shadow: none;
	box-shadow: none;
	padding: 4px 6px;
	font-size: 14px;
	border-radius: 6px;
	border: 0;
	cursor: pointer;
}

._form_18f8u_53 button:hover {
	background-color: var(--brand-color-dark);
}

._form_18f8u_53 button[disabled] {
	opacity: 0.5;
	pointer-events: none;
}

._form_18f8u_53 ._closeButton_18f8u_154 {
	background-color: transparent;
	color: #6f6e77 !important;
	font-size: 13px;
	font-family: var(--font-family);
	border: 1px solid var(--color-white);
}

._form_18f8u_53 ._closeButton_18f8u_154:hover {
	background-color: var(--color-gray-100);
	border-color: var(--color-gray-300);
}

._confirmationButton_18f8u_167 {
	float: right;
	margin-top: 3rem;
}

._formFooter_18f8u_172 {
	display: flex;
	flex-direction: row-reverse;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
}

._formActionsContainer_18f8u_180 {
	display: flex;
	flex-direction: row-reverse;
	gap: 6px;
}

._ad_18f8u_65:hover {
	background-color: var(--color-gray-100);
}

._logoContainer_18f8u_190 {
	display: flex;
	align-items: center;
	gap: 6px;

	border: 1px solid var(--color-gray-300);
	border-radius: 6px;
	color: var(--color-gray-500);
	font-size: 13px;
	padding: 4px 8px;
}

._logo_18f8u_190 {
	height: 16px;
}

._buggyButton_18f8u_206 {
	align-items: center;
	background: #5629c6;
	border-radius: 8px;
	border: 1px solid #bdbdbd;
	box-shadow: none;
	color: #fff;
	cursor: pointer;
	display: flex;
	font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI',
		Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
		'Segoe UI Emoji', 'Segoe UI Symbol';
	font-size: 14px;
	height: auto;
	justify-content: center;
	min-height: 40px;
	padding: 4px 16px;
	text-shadow: none;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"container":"_container_18f8u_1","card":"_card_18f8u_41","cardContents":"_cardContents_18f8u_47","form":"_form_18f8u_53","textareaLabel":"_textareaLabel_18f8u_57","ad":"_ad_18f8u_65","title":"_title_18f8u_78","subtitle":"_subtitle_18f8u_83","closeButton":"_closeButton_18f8u_154","confirmationButton":"_confirmationButton_18f8u_167","formFooter":"_formFooter_18f8u_172","formActionsContainer":"_formActionsContainer_18f8u_180","logoContainer":"_logoContainer_18f8u_190","logo":"_logo_18f8u_190","buggyButton":"_buggyButton_18f8u_206"};
export { css, digest };
  