import React, { useState, useCallback, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { IoClose } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { PostCreationStatus } from '../PostCreationStatus/PostCreationStatus';
import styles from './CreatePostModal.module.css';

const EMOJI_LIST = ['😊', '😂', '🥰', '😎', '🤔', '👍', '❤️', '🎉', '🔥', '✨'];

export const CreatePostModal = ({ 
  isOpen, 
  onClose, 
  onSubmit, 
  loading = false,
  initialText = '',
  title,
  placeholder
}) => {
  const { t } = useTranslation();
  
  title = title || t('createPostModal.title');
  placeholder = placeholder || t('createPostModal.placeholder');
  
  const [postText, setPostText] = useState(initialText);
  const [postStatus, setPostStatus] = useState(null);
  const textareaRef = useRef(null);

  const onFocus = useCallback(() => {
    const intervalId = setInterval(() => {
      document.documentElement.scrollTop = 0;
    }, 100);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const insertEmoji = (e) => {
    e.preventDefault();
    
    const textarea = textareaRef.current;
    if (!textarea) return;

    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const text = postText;
    const emoji = e.currentTarget.dataset.emoji;
    
    const newText = text.substring(0, start) + emoji + text.substring(end);
    setPostText(newText);

    const newPosition = start + emoji.length;
    textarea.setSelectionRange(newPosition, newPosition);
  };

  const handleSubmit = async () => {
    if (!postText.trim() || loading) return;
    
    try {
      setPostStatus('creating');
      await onSubmit(postText);
      setPostStatus('success');
      setPostText('');
      
      setTimeout(() => {
        setPostStatus(null);
        onClose();
      }, 2000);
    } catch (error) {
      setPostStatus(null);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setPostStatus(null);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return createPortal(
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modal} onClick={e => e.stopPropagation()}>
        <div className={styles.modalHeader}>
          <h3>{title}</h3>
          <button 
            className={styles.closeButton}
            onClick={onClose}
          >
            <IoClose size={24} />
          </button>
        </div>
        <div className={styles.modalContent}>
          {postStatus ? (
            <PostCreationStatus status={postStatus} />
          ) : (
            <>
              <textarea
                ref={textareaRef}
                value={postText}
                onChange={e => setPostText(e.target.value)}
                onFocus={onFocus}
                placeholder={placeholder}
                maxLength={280}
                className={styles.postInput}
                autoFocus
              />
              <div className={styles.emojiBar}>
                {EMOJI_LIST.map((emoji, index) => (
                  <button
                    key={index}
                    className={styles.emojiButton}
                    onMouseDown={insertEmoji}
                    data-emoji={emoji}
                    type="button"
                  >
                    {emoji}
                  </button>
                ))}
              </div>
              <div className={styles.modalFooter}>
                <span className={styles.charCount}>
                  {t('createPostModal.charCount', { current: postText.length, max: 280 })}
                </span>
                <button 
                  onClick={handleSubmit}
                  disabled={!postText.trim() || loading}
                  className={styles.submitButton}
                >
                  {loading ? t('createPostModal.posting') : t('createPostModal.post')}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>,
    document.body
  );
}; 