import { useState, useCallback, useRef, useEffect, useMemo } from 'react';

export class ApiError extends Error {
  constructor(message, status) {
    super(message);
    this.status = status;
  }
}

function createApiHook(endpoint, method = 'GET', defaultRequestOptions = {}) {
  return (...routeOptions) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    
    const abortControllerRef = useRef(null);
    const timeoutRef = useRef();
    
    const fetchApi = useCallback(async (fetchRequestOptions) => {
        const promise = new Promise(async (resolve, reject) => {
            try {
                if (abortControllerRef.current) {
                    abortControllerRef.current.abort();
                }
                
                abortControllerRef.current = new AbortController();
                
                setLoading(true);
                setError(null);

                const { query, ..._fetchOptions } = fetchRequestOptions || {}

                const url = `${endpoint.replace(/(?<=\/):(\w+)/g, (_, param) => {
                    const index = endpoint.split('/').filter(p => p.startsWith(':')).indexOf(`:${param}`);
                    return routeOptions[index];
                })}${query ? `?${new URLSearchParams(query).toString()}&t=${new Date().getTime()}` : '?t=' + new Date().getTime()}`

                const response = await fetch(url, {
                    ...defaultRequestOptions,
                    ..._fetchOptions,
                    method,
                    signal: abortControllerRef.current.signal,
                });

                if (!response.ok) {
                    const { error } = await response.json()
                    throw new Error(error);
                }

                const result = await response.json();

                setData(result);
                resolve(result);
            } catch (err) {
                if (err.name === 'AbortError') {
                    return;
                }
                setError(err);
                reject(err);
            } finally {
                setLoading(false);
            }
        });

        return promise;
    }, [routeOptions]);

    const debouncedFetch = useCallback((...args) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        
        return new Promise((resolve, reject) => {
            timeoutRef.current = setTimeout(() => {
                return fetchApi(...args).then((result) => {
                    return resolve(result)
                }).catch((error) => {
                    return reject(error)
                });
            }, 300);
        });
    }, [fetchApi]);

    const abort = useCallback(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    }, []);

    useEffect(() => {
        return () => {
            abort();
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [abort]);

    return { fetch: debouncedFetch, loading, data, error, abort };
  };
}

export function useApi(options) {
  const [baseUrl, setBaseUrl] = useState(options.baseUrl);
  const [defaultRequestOptions, setDefaultRequestOptions] = useState(options.requestOptions || {});

  const createEndpoint = (path) => {
    return `${baseUrl}${path}`;
  };

  return useMemo(() => ({
    character: {
      useFollow: createApiHook(createEndpoint('/character/:id/follow'), 'POST', defaultRequestOptions),
      useUnfollow: createApiHook(createEndpoint('/character/:id/unfollow'), 'POST', defaultRequestOptions),
      useGet: createApiHook(createEndpoint('/characters'), defaultRequestOptions),
      useList: createApiHook(createEndpoint('/character'), 'GET', defaultRequestOptions),
      useSearch: createApiHook(createEndpoint('/character/search'), 'GET', defaultRequestOptions),
      useCreate: createApiHook(createEndpoint('/character/create'), 'POST', defaultRequestOptions),
      useGet: createApiHook(createEndpoint('/character/:id'), 'GET', defaultRequestOptions),
      useUpdateProfile: createApiHook(createEndpoint('/character/:id/profile'), 'PUT', defaultRequestOptions),
      useConnectTgBot: createApiHook(createEndpoint('/character/tg/connect/:id'), 'POST', defaultRequestOptions),
      useDisconnectTgBot: createApiHook(createEndpoint('/character/tg/disconnect'), 'POST', defaultRequestOptions),
      useFavorite: createApiHook(createEndpoint('/character/:id/favorite'), 'POST', defaultRequestOptions),
      useLike: createApiHook(createEndpoint('/character/:id/like'), 'POST', defaultRequestOptions),
      useAvatarUpload: createApiHook(createEndpoint('/character/:id/avatar'), 'POST', defaultRequestOptions),
      useBots: createApiHook(createEndpoint('/character/:id/bots'), 'GET', defaultRequestOptions),
      useImport: createApiHook(createEndpoint('/character/import'), 'POST', defaultRequestOptions),
      useGenerate: createApiHook(createEndpoint('/character/generate'), 'POST', defaultRequestOptions),
      useSearchLora: createApiHook(createEndpoint('/character/:id/models/search'), 'GET', defaultRequestOptions),
      useUpdateLora: createApiHook(createEndpoint('/character/:id/lora'), 'PUT', defaultRequestOptions),
    },
    user: {
      useMe: createApiHook(createEndpoint('/user/me'), 'GET', defaultRequestOptions),
      useInvoice: createApiHook(createEndpoint('/user/invoice'), 'POST', defaultRequestOptions),
      useUpdateNickname: createApiHook(createEndpoint('/user/nickname'), 'POST', defaultRequestOptions),
      useBots: createApiHook(createEndpoint('/user/bots'), 'GET', defaultRequestOptions),
      useChats: createApiHook(createEndpoint('/user/chats'), 'GET', defaultRequestOptions),
      useSetLanguage: createApiHook(createEndpoint('/user/language'), 'POST', defaultRequestOptions),
      useEnableAdult: createApiHook(createEndpoint('/user/enable-adult'), 'POST', defaultRequestOptions),
      useBrowsingLevel: createApiHook(createEndpoint('/user/browsing-level'), 'POST', defaultRequestOptions),
      useChatMessages: createApiHook(createEndpoint('/user/chat/:id/messages'), 'GET', defaultRequestOptions),
      useStartChat: createApiHook(createEndpoint('/user/chat/start'), 'POST', defaultRequestOptions),
      useSendMessage: createApiHook(createEndpoint('/user/chat/:id/message'), 'POST', defaultRequestOptions),
      useMessageUpdates: createApiHook(createEndpoint('/user/chat/:id/updates'), 'GET', defaultRequestOptions),
      useImageGenerationTask: createApiHook(createEndpoint('/user/image-generation-task/:id'), 'GET', defaultRequestOptions),
      useGetInvoice: createApiHook(createEndpoint('/user/invoice'), 'GET', defaultRequestOptions),
      useShareMessage: createApiHook(createEndpoint('/user/share/message/:id'), 'GET', defaultRequestOptions),
      useShareCharacter: createApiHook(createEndpoint('/user/share/character/:id'), 'GET', defaultRequestOptions),
      useShareImage: createApiHook(createEndpoint('/user/share/image/:uuid'), 'GET', defaultRequestOptions),
      useProfile: createApiHook(createEndpoint('/user/profile/:nickname'), 'GET', defaultRequestOptions),
      useFollow: createApiHook(createEndpoint('/user/follow/:nickname'), 'POST', defaultRequestOptions),
      useUnfollow: createApiHook(createEndpoint('/user/unfollow/:nickname'), 'POST', defaultRequestOptions),
      useAvatarUpload: createApiHook(createEndpoint('/user/avatar'), 'POST', defaultRequestOptions),
      useTransactions: createApiHook(createEndpoint('/user/transactions'), 'GET', defaultRequestOptions),
      useActivities: createApiHook(createEndpoint('/user/activities'), 'GET', defaultRequestOptions),
      useLeaderboard: createApiHook(createEndpoint('/user/leaderboard'), 'GET', defaultRequestOptions),
    },
    post: {
      useRecommended: createApiHook(createEndpoint('/post/recommended'), 'GET', defaultRequestOptions),
      useFeed: createApiHook(createEndpoint('/post/feed'), 'GET', defaultRequestOptions),
      useCreate: createApiHook(createEndpoint('/post'), 'POST', defaultRequestOptions),
      useReact: createApiHook(createEndpoint('/post/:id/react'), 'POST', defaultRequestOptions),
      useFavorite: createApiHook(createEndpoint('/post/:id/favorite'), 'POST', defaultRequestOptions),
      useComment: createApiHook(createEndpoint('/post/:id/comment'), 'POST', defaultRequestOptions),
      useSharePost: createApiHook(createEndpoint('/post/:id/share'), 'GET', defaultRequestOptions),
      useComments: createApiHook(createEndpoint('/post/:id/comments'), 'GET', defaultRequestOptions),
      useCommentsThread: createApiHook(createEndpoint('/post/:id/comments/thread'), 'GET', defaultRequestOptions),
    },
    subscription: {
      usePlans: createApiHook(createEndpoint('/subscription/plans'), 'GET', defaultRequestOptions),
      useInvoice: createApiHook(createEndpoint('/subscription/invoice'), 'POST', defaultRequestOptions),
      useCreateCheckoutSession: createApiHook(createEndpoint('/subscription/create-checkout-session'), 'POST', defaultRequestOptions),
      useCreatePortalSession: createApiHook(createEndpoint('/subscription/create-portal-session'), 'POST', defaultRequestOptions),
      useUpdate: createApiHook(createEndpoint('/subscription/update'), 'POST', defaultRequestOptions),
    },
  }), [baseUrl, defaultRequestOptions]);
}
