import React, { useEffect, useState, useMemo, useCallback, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { IoHomeOutline, IoChatbubbleOutline, IoAddCircleOutline, IoPersonOutline } from 'react-icons/io5';
import { FaTelegramPlane } from 'react-icons/fa';
import { PiHandCoinsLight } from "react-icons/pi";

import { useApi } from '../../hooks/useApi';
import { NotFound } from '../../components/NotFound';
import { Loader } from '../../components/Loader';
import { UserContext } from '../../components/UserProvider';

import styles from './Chats.module.css';

const tg = window.Telegram.WebApp;

export function Chats() {
    const navigate = useNavigate();
    const chatsListRef = useRef(null);
    const { t } = useTranslation();

    const { user } = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [allChats, setAllChats] = useState([]);

    const apiOptions = useMemo(() => ({
        baseUrl: process.env.REACT_APP_API_URL,
        requestOptions: {
            headers: {
                'x-init-data': tg.initData,
            }
        }
    }), [])

    const { user: { useChats } } = useApi(apiOptions)
    const { fetch: fetchChats } = useChats();

    const loadMore = useCallback(() => {
        if (!hasMore || loading || loadingMore) return;
        setLoadingMore(true);
        setPage(prev => prev + 1);
    }, [hasMore, loading, loadingMore]);

    const handleScroll = useCallback((e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            loadMore();
        }
    }, [loadMore]);

    const onBotClick = useCallback((botname) => () => {
        tg.openTelegramLink(`https://t.me/${botname}`)
    }, [])

    useEffect(() => {
        tg.expand()
        tg.BackButton.show()
        tg.BackButton.onClick(() => navigate(`/`))
        tg.disableVerticalSwipes()

    }, [navigate])

    useEffect(() => {
        if (!user) return;

        const rowCount = (Math.round((window.innerHeight / 75)))
        const limit = rowCount;

        fetchChats({ query: { page, limit }})
            .then(response => {
                setAllChats(prev => {
                    if (page === 1) return response.chats;
                    return [...prev, ...response.chats];
                });
                setHasMore(response.chats.length < response.total);
                setLoading(false);
                setLoadingMore(false);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, page]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString({
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    };

    const onChatClick = useCallback((chat) => () => {
        navigate(`/chat/${chat.character.id}/${chat.id}`)
    }, [navigate])

    const onHomeClick = useCallback(() => navigate(`/`), [navigate])
    const onAddCharacterClick = useCallback(() => navigate(`/character/create`), [navigate])
    const onProfileClick = useCallback(() => navigate(`/profile`), [navigate])

    const onEarnClick = useCallback(() => {
      navigate('/earn')
    }, [navigate])

    if (loading) {
        return <Loader />;
    }

    return (
        <div className={styles.chats + ' ' + styles[tg.platform] + ' '}>
            <div className={styles.container}>
                <div className={styles.chatsList} ref={chatsListRef} onScroll={handleScroll}>
                    {
                        allChats.length === 0 && !loading && (
                            <NotFound>
                                <div className={styles.empty}>{t('chats.notFound')}</div>
                            </NotFound>
                        )
                    }
                    {allChats.map((chat) => (
                        <div className={styles.chat} key={chat.id} onClick={onChatClick(chat)} >
                            <div className={styles.avatar} style={chat.character.avatar_url ? { backgroundImage: `url(${chat.character.avatar_url})` } : { 'backgroundColor': '#000' }}> {chat.character.avatar_url ? '' : 'no avatar'} </div>
                            <div className={styles.info}>
                                <div className={styles.chatName}>{chat.character.name}</div>
                                <div className={styles.chatInfo}>
                                    <span className={styles.messages}><IoChatbubbleOutline /> {chat._count.messages}</span>
                                    <span className={styles.date}>{formatDate(chat.last_message_date)}</span>
                                </div>
                            </div>
                            <div className={styles.actions}>
                                { chat.telegram_bot &&
                                    <button onClick={onBotClick(chat.telegram_bot.botname)} className={styles.button}>
                                        <FaTelegramPlane size={22} />
                                    </button>
                                }
                                <button onClick={onChatClick(chat)} className={styles.button}>
                                    <IoChatbubbleOutline size={22} />
                                </button>
                            </div>
                        </div>
                    ))}
                    {(loading || loadingMore) && <div className={styles.loading}><Loader /></div>}
                </div>

                <button className={styles.newChatButton} onClick={() => {
                    navigate('/?tab=characters')
                }}>
                    {t('chats.startNewChat')}
                </button>

                <footer className={styles.footer}>
                    <div className={styles.footerTabs}>
                        <button onClick={onHomeClick}><IoHomeOutline /></button>
                        <button><IoChatbubbleOutline /></button>
                        <button className={styles.earnButton} onClick={onEarnClick}>
                            <PiHandCoinsLight />
                            <label>{t('buttons.earn')}</label>
                        </button>
                        <button onClick={onAddCharacterClick}><IoAddCircleOutline /></button>
                        <button onClick={onProfileClick}><IoPersonOutline /></button>
                    </div>
                </footer>
            </div>
        </div>
    );
}
