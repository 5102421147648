import React from 'react';
import { IoCheckmarkCircle, IoTime } from 'react-icons/io5';
import styles from './PostCreationStatus.module.css';

export const PostCreationStatus = ({ status = 'creating' }) => {
    return (
        <div className={styles.container}>
            {status === 'creating' ? (
                <>
                    <div className={styles.spinner}>
                        <IoTime size={48} />
                    </div>
                    <h3>Creating your post...</h3>
                    <p>Just a moment while we process your request</p>
                </>
            ) : (
                <>
                    <div className={styles.success}>
                        <IoCheckmarkCircle size={48} />
                    </div>
                    <h3>Post created successfully!</h3>
                    <p>Your post has been shared with the community</p>
                </>
            )}
        </div>
    );
}; 