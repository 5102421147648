export const formatNumber = (num) => {
  if (num === null || num === undefined) return '0';
  
  if (num < 1000) return num.toString();
  
  const units = ['', 'K', 'M', 'B'];
  const order = Math.floor(Math.log10(num) / 3);
  const unitname = units[order];
  const value = num / Math.pow(1000, order);
  
  const formatted = value < 10 ? 
    Math.round(value * 10) / 10 : 
    Math.round(value);
    
  return formatted + unitname;
}; 