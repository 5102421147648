import React from 'react';
import { useTranslation } from "react-i18next";
import { MessageText } from './MessageText';
import { BuyCreditsMessage } from './BuyCreditsMessage';
import styles from '../Chat.module.css';

export const SystemMessage = ({ message, fetchInvoice }) => {
    const { t } = useTranslation();
    
    if (message.text === 'Not enough credits') {
        return (
            <div className={`${styles.message} ${styles.systemMessage}`}>
                <div className={styles.messageContent}>
                    <BuyCreditsMessage fetchInvoice={fetchInvoice} />
                    <div className={styles.messageTime}>
                        {new Date(message.created_at).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit'
                        })}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={`${styles.message} ${styles.systemMessage}`}>
            <div className={styles.messageContent}>
                <MessageText text={message.text} />
                <div className={styles.messageTime}>
                    {new Date(message.created_at).toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit'
                    })}
                </div>
            </div>
        </div>
    );
}; 