import React, { useCallback } from 'react'
import { IoClose } from 'react-icons/io5'
import { useTranslation } from "react-i18next"

import { Textarea } from '../inputs/Textarea'
import { useGenerateContext } from '../../../hooks/useGenerate'

import styles from './GenerateModal.module.css'

export function GenerateModal({ onClose, onGenerateFetch, onApply }) {
    const { t } = useTranslation()
    
    const {
        fieldName, fieldValue, fieldMaxLength, setFieldValue,
        prompt, setPrompt,
        isLoading, setIsLoading
    } = useGenerateContext()

    const handleChange = useCallback((e) => {
        setPrompt(e.target.value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChangeValue = useCallback((e) => {
        setFieldValue(e.target.value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleApply = useCallback((e) => {
        onApply(fieldName, fieldValue)
        onClose()
    }, [onApply, fieldName, fieldValue, onClose])

    const handleGenerate = useCallback(() => {
        setIsLoading(true)
        onGenerateFetch(fieldName, fieldValue, prompt)
            .then((value) => {
                setFieldValue(value)
            }).finally(() => {
                setIsLoading(false)
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prompt, fieldName, fieldValue, setIsLoading])

    return (
        <div className={styles.overlay} onClick={onClose}>
            {
                (isLoading) && (
                    <div className={styles.spinnerOverlay} onClick={(e) => e.stopPropagation()}>
                        <span>{t('character.generate.generating')}</span>
                        <div className={styles.spinner} />
                    </div>
                )
            }
            <div className={styles.container} onClick={(e) => e.stopPropagation()}>
                <div className={styles.close} onClick={onClose}><IoClose size={40} /></div>
                <Textarea disabled={isLoading} value={fieldValue} onChange={handleChangeValue} maxLength={fieldMaxLength} />
                <button disabled={isLoading} className={styles.button} onClick={handleApply}>{t('character.generate.apply')}</button>
                <Textarea disabled={isLoading} placeholder={t('character.generate.prompt')} onChange={handleChange} value={prompt} maxLength={256} />
                <button disabled={isLoading} className={styles.button} onClick={handleGenerate}>
                    {t('character.generate.button')} ({t('character.generate.credits', { amount: 50 })})
                </button>
            </div>
        </div>
    )
}