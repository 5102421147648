import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useContext,
  useRef
} from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { createPortal } from "react-dom";
import {
  IoAddCircleOutline,
  IoHeart,
  IoBookmark,
  IoHeartOutline,
  IoBookmarkOutline,
  IoChatbubbleOutline,
  IoEyeOutline,
  IoEyeOffOutline,
  IoTrash,
  IoShareOutline,
  IoPersonOutline,
  IoPencilOutline
} from "react-icons/io5";
import { FaTelegramPlane } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import styles from "./Show.module.css";

import { formatNumber } from "../../utils/formatNumber";
import { CharacterPreview } from "../../components/CharacterPreview/CharacterPreview";
import { UserContext } from "../../components/UserProvider";
import { Loader } from "../../components/Loader";
import { useApi } from "../../hooks/useApi";
import { Post } from "../../components/Post/Post";
import { CreatePostModal } from '../../components/CreatePostModal/CreatePostModal';
import { Tutorial } from "../../components/Tutorial/Tutorial";

const TUTORIAL_COMPLETED_KEY = "tutorial_completed";

const tg = window.Telegram.WebApp;

export const Show = () => {
  const { id } = useParams();
  return <ShowComponent key={id} id={id} />
}

export function ShowComponent({ id }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultTab = searchParams.get("tab");

  const containerRef = useRef(null)

  const [character, setCharacter] = useState(null);
  const [errors, setErrors] = useState([]);
  const [tab, setTab] = useState(defaultTab || "profile");

  const [openedBot, setOpenedBot] = useState(null);

  const [botDeleteBotnames, setBotDeleteBotnames] = useState({});
  const [botDeleteTimeouts, setBotDeleteTimeouts] = useState({});

  const [botsData, setBotsData] = useState(null);

  const [getCharacterError, setGetCharacterError] = useState(null);
  const [getCharacterLoading, setGetCharacterLoading] = useState(false);

  const [botsLoading, setBotsLoading] = useState(false);
  const [likeLoading, setLikeLoading] = useState(false);
  const [favoriteLoading, setFavoriteLoading] = useState(false);

  const [disconnectTgBotLoading, setDisconnectTgBotLoading] = useState(false);

  const [menuOpen, setMenuOpen] = useState(false);

  const [postModalOpen, setPostModalOpen] = useState(false);
  const [postLoading, setPostLoading] = useState(false);

  const [feed, setFeed] = useState(null);

  const apiOptions = useMemo(
    () => ({
      baseUrl: process.env.REACT_APP_API_URL,
      requestOptions: {
        headers: {
          "x-init-data": tg.initData,
        },
      },
    }),
    []
  );

  const {
    character: {
      useGet: getCharacter,
      useFavorite,
      useLike,
      useBots,
      useDisconnectTgBot,
      useFollow,
      useUnfollow
    },
    user: { useShareCharacter },
    post: { useCreate, useFeed },
  } = useApi(apiOptions);

  const { fetch: feedFetch } = useFeed();
  const { fetch: getCharacterFetch } = getCharacter(id);
  const { fetch: favoriteFetch } = useFavorite(id);
  const { fetch: likeFetch } = useLike(id);
  const { fetch: botsFetch } = useBots(id);
  const { fetch: disconnectTgBotFetch } = useDisconnectTgBot();
  const { fetch: shareCharacterFetch } = useShareCharacter(id);
  const { fetch: follow } = useFollow(id);
  const { fetch: unfollow } = useUnfollow(id);
  const { fetch: createPostFetch } = useCreate();

  const { user } = useContext(UserContext);

  const startChatButtonRef = useRef(null);

  const [showTutorial, setShowTutorial] = useState(() => {
    const tutorialStep = localStorage.getItem("tutorial_current_step");
    return tutorialStep && parseInt(tutorialStep) >= 5;
  });

  const handleTutorialComplete = useCallback(() => {
    setShowTutorial(false);
    localStorage.removeItem("tutorial_current_step");
    localStorage.setItem(TUTORIAL_COMPLETED_KEY, "true");
  }, []);

  useEffect(() => {
    tg.expand();
    tg.disableVerticalSwipes();
    tg.BackButton.show();
    tg.BackButton.onClick(() => navigate(`/`));

    return () => {
      tg.BackButton.offClick();
    };
  }, [navigate]);

  useEffect(() => {
    if (!user || character) return;
    setGetCharacterLoading(true);

    feedFetch({
      query: {
        character_id: id,
        page: 1,
        pageSize: 10,
      },
    }).then((data) => {
      setFeed(data);
    });

    getCharacterFetch()
      .then((data) => {
        setCharacter(data);
        setGetCharacterLoading(false);
      })
      .catch((error) => {
        setGetCharacterLoading(false);
        setGetCharacterError(error);
      });

    setBotsLoading(true);
    botsFetch()
      .then((data) => {
        setBotsData(data);
        setBotsLoading(false);
      })
      .catch((error) => {
        setBotsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, id]);

  const onLikeClick = useCallback(async () => {
    if (!user || !character || likeLoading) return;
    setCharacter({
      ...character,
      is_liked: !character.is_liked,
      _count: {
        ...character._count,
        likes: !character.is_liked
          ? character._count.likes + 1
          : character._count.likes - 1,
      },
    });
    setLikeLoading(true);
    await likeFetch()
      .then(() => {
        setLikeLoading(false);
      })
      .catch((error) => {
        setLikeLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, character, likeLoading]);

  const onFavoriteClick = useCallback(async () => {
    if (!user || !character || favoriteLoading) return;
    setCharacter({
      ...character,
      is_favorite: !character.is_favorite,
      _count: {
        ...character._count,
        favorites: !character.is_favorite
          ? character._count.favorites + 1
          : character._count.favorites - 1,
      },
    });
    setFavoriteLoading(true);
    await favoriteFetch()
      .then(() => {
        setFavoriteLoading(false);
      })
      .catch((error) => {
        setFavoriteLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, character, favoriteLoading]);

  const onStartChat = useCallback(() => {
    // setMenuOpen(true)
    navigate(`/chat/${id}/new`);
  }, [navigate, id]);

  const onBotClick = useCallback(
    (botname) => () => {
      tg.openTelegramLink(`https://t.me/${botname}`);
      setMenuOpen(false);
    },
    []
  );

  const onOpenBotClick = useCallback(
    (botname) => () => {
      if (botDeleteBotnames[botname]) return;
      if (openedBot === botname) {
        setOpenedBot(null);
      } else {
        setOpenedBot(botname);
      }
    },
    [openedBot, botDeleteBotnames]
  );

  const onBotDelete = useCallback(
    (botname) => async () => {
      if (botDeleteBotnames[botname]) return;
      setBotDeleteBotnames({ ...botDeleteBotnames, [botname]: true });

      setBotDeleteTimeouts({
        ...botDeleteTimeouts,
        [botname]: setTimeout(() => {
          setDisconnectTgBotLoading(true);
          disconnectTgBotFetch({
            body: JSON.stringify({ id, telegram_botname: botname }),
          })
            .then(() => {
              setDisconnectTgBotLoading(false);
              botsFetch();
              getCharacterFetch();
              setBotDeleteBotnames({ ...botDeleteBotnames, [botname]: false });
              setBotDeleteTimeouts({ ...botDeleteTimeouts, [botname]: null });
              setOpenedBot(null);
            })
            .catch((error) => {
              setDisconnectTgBotLoading(false);
              alert(`Bot ${botname} deletion failed: ${error}`);
            });
        }, 3100),
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [id, botDeleteBotnames]
  );

  const onCancelBotDelete = useCallback(
    (botname) => () => {
      if (disconnectTgBotLoading) return;
      setBotDeleteBotnames({ ...botDeleteBotnames, [botname]: false });
      clearTimeout(botDeleteTimeouts[botname]);
      setBotDeleteTimeouts({ ...botDeleteTimeouts, [botname]: null });
    },
    [botDeleteBotnames, botDeleteTimeouts, disconnectTgBotLoading]
  );

  const onChangeTab = useCallback(
    (tab) => () => {
      setTab(tab);
      setSearchParams({ tab });
    },
    [setTab, setSearchParams]
  );

  const onCharacterClick = useCallback(
    (id) => () => {
      tg.BackButton.show();
      navigate(`/character/${id}`, { replace: false });
    },
    [navigate, containerRef]
  );

  const onShareClick = useCallback(async () => {
    const { prepared_message } = await shareCharacterFetch();
    await tg.shareMessage(prepared_message.id);
  }, []);

  const onPostSubmit = useCallback(async (text) => {
    setPostLoading(true);
    try {
      await createPostFetch({
        body: JSON.stringify({ character_id: id, content: text })
      });
      
      setCharacter(prev => ({
        ...prev,
        _count: {
          ...prev._count,
          posts: prev._count.posts + 1
        }
      }));
    } catch (error) {
      alert(`Failed to create post: ${error}`);
    } finally {
      setPostLoading(false);
    }
  }, [id, createPostFetch]);

  const onFollowClick = useCallback(() => {
    follow().then(() => {
      setCharacter((prev) => {
        return {
          ...prev,
          followers: [...prev.followers, user.id],
          _count: {
            ...prev._count,
            followers: prev._count.followers + 1
          }
        }
      })
    })
  }, [id, follow])

  const onUnfollowClick = useCallback(() => {
    unfollow().then(() => {
      setCharacter((prev) => {
        return {
          ...prev,
          followers: [],
          _count: {
            ...prev._count,
            followers: prev._count.followers - 1
          }
        }
      })
    })
  }, [id, unfollow])

  useEffect(() => {
    const tutorialStep = localStorage.getItem("tutorial_current_step");
    if (tutorialStep && parseInt(tutorialStep) === 7 && character) {
      const timeoutId = setTimeout(() => {
        if (startChatButtonRef.current) {
          startChatButtonRef.current.scrollIntoView({ 
            behavior: 'smooth',
            block: 'center'
          });
        }
      }, 500);

      return () => clearTimeout(timeoutId);
    }
  }, [showTutorial, character]);

  if (character === null) {
    return <Loader />;
  }

  return (
    <div
      className={
        styles.show +
        " " +
        styles[tg.platform] +
        " " +
        (menuOpen ? styles.menuOpen : "")
      }
      ref={containerRef}
    >
      {errors.length > 0 && (
        <div className={styles.error}>
          {errors.map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </div>
      )}

      {menuOpen && (
        <div onClick={() => setMenuOpen(false)} className={styles.overlay} />
      )}

      <div className={styles.container}>
        <div className={styles.tabs}>
          <div
            data-tab="profile"
            className={
              styles.tab + " " + (tab === "profile" ? styles.active : "")
            }
            onClick={onChangeTab("profile")}
          >
            <span>{t('show.tabs.profile')}</span>
          </div>
          <div
            data-tab="posts"
            className={
              styles.tab + " " + (tab === "posts" ? styles.active : "")
            }
            onClick={onChangeTab("posts")}
          >
            <span>{t('show.tabs.posts')}</span>
          </div>
          <div
            data-tab="bots"
            className={styles.tab + " " + (tab === "bots" ? styles.active : "")}
            onClick={onChangeTab("bots")}
          >
            <span>{t('show.tabs.bots')}</span>
          </div>
        </div>

        {tab === "profile" && (
          <>
            <div className={styles.avatarContainer}>
              <div className={styles.actions}>
                {character?.is_author && (
                  <div className={styles.edit}>
                    <button
                      onClick={() => navigate(`/character/edit/${id}`)}
                      className={styles.button}
                    >
                      <IoPencilOutline />
                    </button>
                  </div>
                )}

                <div className={styles.share}>
                  <button onClick={onShareClick} className={styles.button}>
                    <IoShareOutline />
                  </button>
                </div>
              </div>

              {character?.rating && (
                <div className={styles.field + " " + styles.ratingContainer}>
                  <span
                    className={styles.rating + " " + styles[character?.rating]}
                  >
                    {character?.rating.replace("_", "-")}
                  </span>
                  {user?.is_adult && character.is_adult && (
                    <span className={styles.isAdult}>
                      {
                        <>
                          <IoHeartOutline /> <span>18+</span>
                        </>
                      }
                    </span>
                  )}
                </div>
              )}

              <span className={styles.name}>{character?.name}</span>
              <div className={styles.author}>
                {t('show.author')} @{character?.author.nickname}
              </div>
              <div
                className={styles.avatar}
                style={
                  character.avatar_url
                    ? { backgroundImage: `url(${character.avatar_url})` }
                    : { backgroundColor: "#000" }
                }
              >
                {" "}
                {character.avatar_url ? "" : t('show.noAvatar')}{" "}
              </div>
              <div className={styles.stats}>
                <div className={styles.follow} onClick={character?.followers?.length > 0 ? onUnfollowClick : onFollowClick}>
                  <span>{formatNumber(character?._count.followers)}</span>
                  <IoPersonOutline />{character?.followers?.length > 0 ? t('show.unfollow') : t('show.follow')}
                </div>

                { character.is_public && (
                  <div onClick={() => setPostModalOpen(true)}>
                    <IoAddCircleOutline />
                    <span>{formatNumber(character?._count.posts)}</span>
                  </div>
                )}

                <div onClick={onLikeClick}>
                  {character?.is_liked ? <IoHeart /> : <IoHeartOutline />}
                  <span>{formatNumber(character?._count.likes)}</span>
                </div>

                <div onClick={onFavoriteClick}>
                  {character?.is_favorite ? (
                    <IoBookmark />
                  ) : (
                    <IoBookmarkOutline />
                  )}
                  <span>{formatNumber(character?._count.favorites)}</span>
                </div>
              </div>
            </div>

            <button 
              ref={startChatButtonRef}
              className={styles.button} 
              onClick={onStartChat}
            >
              {t('show.startInAppChat')}
            </button>

            <div className={styles.tags}>
              {character?.tags.map((tag) => (
                <div key={tag.id} className={styles.tag}>
                  {tag.name}
                </div>
              ))}
            </div>

            <div className={styles.field}>
              <span className={styles.bio}>{character?.bio}</span>
            </div>

            {createPortal(
              <div
                className={`${styles.menu} ${menuOpen ? styles.menuOpen : ""}`}
              >
                {character?.telegram_bots.length === 0 ? (
                  <div className={styles.menuTitle}>
                    {t('show.noPublicBots')}
                  </div>
                ) : (
                  character?.telegram_bots.map((bot) => (
                    <div key={bot.id} className={styles.bot}>
                      <button
                        onClick={onBotClick(bot.botname)}
                        className={styles.button}
                      >
                        {t('show.openBot')} @{bot.botname}
                      </button>
                    </div>
                  ))
                )}
                <button
                  onClick={() => navigate(`/character/tg/connect/${id}`)}
                  className={styles.button}
                >
                  {t('show.connectNewBot')}
                </button>
                <button
                  onClick={() => setMenuOpen(false)}
                  className={`${styles.button} ${styles.close}`}
                >
                  {t('show.close')}
                </button>
              </div>,
              document.body
            )}

            {character?.telegram_bots?.length > 0 && (
              <div className={styles.characterInfoSection}>
                <h3>{t('show.availableOnTelegram')}</h3>
                <div className={styles.characterInfoTags}>
                  {character.telegram_bots.map((bot) => (
                    <span key={bot.id} className={styles.characterInfoTag}>
                      <a
                        href={`https://t.me/${bot.botname}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        @{bot.botname}
                      </a>
                    </span>
                  ))}
                </div>
              </div>
            )}

            {character &&
              character?.characters &&
              character?.characters.length > 0 && (
                <div className={styles.field}>
                  <span className={styles.recommendedTitle}>
                    {t('show.recommendedTitle')}
                  </span>

                  <div className={styles.characters}>
                    {character?.characters.map((c) => (
                      <div
                        key={c.id}
                        className={styles.character}
                        onClick={onCharacterClick(c.id)}
                      >
                        <CharacterPreview user={user} character={c} />
                      </div>
                    ))}
                  </div>
                </div>
              )}
          </>
        )}

        {tab === "posts" && (
          <div className={styles.posts}>
            {getCharacterLoading ? (
              <div className={styles.spinner} />
            ) : (
              <>
                {feed?.posts.map((post) => (
                  <Post key={post.id} post={post} character={character} />
                ))}
                {feed?.posts.length === 0 && (
                  <div className={styles.emptyState}>{t('show.noPosts')}</div>
                )}
              </>
            )}
          </div>
        )}

        {botsLoading && <div className={styles.spinner} />}

        {tab === "bots" && (
          <div className={styles.bots}>
            {botsData?.bots.length === 0 && (
              <div className={styles.menuTitle}>
                <p>
                  {t('show.noBots')}
                </p>
              </div>
            )}
            <button
              onClick={() => navigate(`/character/tg/connect/${id}`)}
              className={styles.button + " " + styles.connect}
            >
              {t('show.connectNewBot')}
            </button>
            {botsData?.bots.map((bot) => (
              <div
                key={bot.botname}
                className={
                  styles.bot +
                  " " +
                  (openedBot === bot.botname ? styles.botOpened : "") +
                  " " +
                  (botDeleteBotnames[bot.botname] ? styles.botDeleting : "")
                }
                onClick={onOpenBotClick(bot.botname)}
              >
                <div className={styles.botDeletingOverlay} />
                {!disconnectTgBotLoading && (
                  <div
                    className={styles.botDeletingText}
                    onClick={onCancelBotDelete(bot.botname)}
                  >
                    {t('show.tapToCancel')}
                  </div>
                )}
                <div className={styles.botContent}>
                  <div className={styles.botHeader}>
                    <div>
                      <span className={styles.botname}>@{bot.botname}</span>
                    </div>
                    <div>
                      <span className={styles.botAuthor}>
                        @{bot.user.nickname}
                      </span>
                    </div>
                  </div>
                  <div className={styles.botFooter}>
                    <div className={styles.botVisability}>
                      {bot.is_public ? <IoEyeOutline /> : <IoEyeOffOutline />}
                    </div>
                    <div className={styles.botBubble}>
                      <IoChatbubbleOutline />
                    </div>
                  </div>
                </div>
                <div className={styles.botActions}>
                  <button
                    onClick={onBotClick(bot.botname)}
                    className={styles.button}
                  >
                    <FaTelegramPlane size={22} />
                  </button>
                  <button
                    onClick={onBotDelete(bot.botname)}
                    className={styles.button}
                  >
                    <IoTrash size={22} />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <CreatePostModal 
        title={t('show.createPost')}
        placeholder={t('show.whatDoYouThink', { name: character?.name })}
        isOpen={postModalOpen}
        onClose={() => setPostModalOpen(false)}
        onSubmit={onPostSubmit}
        loading={postLoading}
      />

      {showTutorial && (
        <Tutorial
          onComplete={handleTutorialComplete}
          refs={{
            button: startChatButtonRef
          }}
        />
      )}
    </div>
  );
}
