import React, { useCallback, useEffect, useState, useMemo, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import styles from './ConnectTgBot.module.css';

import { Loader } from '../../components/Loader';
import { useApi, ApiError } from '../../hooks/useApi';
import { UserContext } from '../../components/UserProvider';

const tg = window.Telegram.WebApp;

export function ConnectTgBot() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id } = useParams();

  const [character, setCharacter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [connectBotOpen, setConnectBotOpen] = useState(false);
  const [botToken, setBotToken] = useState('');
  const [botTokenError, setBotTokenError] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [connectTgBotLoading, setConnectTgBotLoading] = useState(false);

  const apiOptions = useMemo(() => ({
    baseUrl: process.env.REACT_APP_API_URL,
    requestOptions: {
      headers: {
        'x-init-data': tg.initData,
      }
    }
  }), [])

  const { character: { useGet: getCharacter, useConnectTgBot: connectTgBot } } = useApi(apiOptions)
  const { fetch: getCharacterFetch } = getCharacter(id)
  const { fetch: connectTgBotFetch } = connectTgBot(id)

  const { user } = useContext(UserContext);

  useEffect(() => {
    tg.BackButton.show()
    tg.BackButton.onClick(() => navigate(`/character/${id}`))
    tg.expand()

    return () => {
      tg.BackButton.offClick()
    }
  }, [id, navigate]);

  useEffect(() => {
    if (!user) return
    getCharacterFetch().then((data) => {
      setCharacter(data)
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const onChangeBotToken = useCallback((e) => {
    setBotToken(e.target.value)
  }, [])

  const onConnectBot = useCallback(() => {
    const isValidToken = /^[0-9]{8,10}:[a-zA-Z0-9_-]{35}$/.test(botToken);

    if (!isValidToken) {
      setBotTokenError(true)
      return
    }

    setBotTokenError(false)
    setConnectTgBotLoading(true)

    connectTgBotFetch({ body: JSON.stringify({ token: botToken, is_public: isPublic }) })
      .then(() => {
        setConnectTgBotLoading(false)
        navigate(`/character/${id}`)
      })
      .catch((e) => {
        setConnectTgBotLoading(false)
        if (e instanceof ApiError) {
          setErrors([...errors, e.response.error])
        } else {
          setErrors([...errors, t('connectTgBot.errors.somethingWentWrong')])
        }
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botToken, isPublic])

  const onChangeIsPublic = useCallback((e) => {
    setIsPublic(e.target.checked)
  }, [])

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={styles.connectTgBot + ' ' + styles[tg.platform]}>
      {errors.length > 0 && (
        <div className={styles.error}>
          {errors.map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </div>
      )}

      <div className={styles.container}>
        <div className={styles.avatarContainer}>
          <div className={styles.avatar} style={character.avatar_url ? { backgroundImage: `url(${character.avatar_url})` } : { 'backgroundColor': '#18373a' }}> {character.avatar_url ? '' : t('show.noAvatar')} </div>
          <div className={styles.nameContainer}>
            <span className={styles.name}>{character?.name}</span>
          </div>
        </div>

        <div className={styles.field}>
          <p><b>{t('connectTgBot.howToConnectTitle')}</b></p>
          <p>{t('connectTgBot.howToConnectDescription')}</p>
          <p>{t('connectTgBot.alreadyHaveToken')}</p>
          {
            connectBotOpen ? (<>
              <div className={styles.connectBotInput}>
                <input disabled={connectTgBotLoading} className={botTokenError ? styles.inputError : ''} type="text" placeholder={t('connectTgBot.tokenPlaceholder')} value={botToken} onChange={onChangeBotToken} />
              </div>
              <div className={styles.connectBotIsPublic}>
                <input id='connectBotIsPublic' type="checkbox" checked={isPublic} onChange={onChangeIsPublic} />
                <label htmlFor='connectBotIsPublic'>{t('connectTgBot.makePublic')}</label>
              </div>
              <button disabled={connectTgBotLoading} className={styles.button} onClick={onConnectBot}>{t('connectTgBot.connect')}</button>
              </>
            ) : (
              <button onClick={() => setConnectBotOpen(true)} className={styles.button}>{t('show.connectNewBot')}</button>
            )
          }
        </div>

        <div className={styles.field}>
          <p><b>{t('connectTgBot.createBotTitle')}</b></p>
          <p>{t('connectTgBot.createBotDescription')}</p>
          <ol>
            <li>
              {t('connectTgBot.steps.step1')}
              <button onClick={() => tg.openTelegramLink('https://t.me/BotFather')} className={styles.button}>{t('connectTgBot.openBotFather')}</button>
            </li>
            <li>{t('connectTgBot.steps.step2')}</li>
            <li>{t('connectTgBot.steps.step3')}</li>
            <li>{t('connectTgBot.steps.step4')}</li>
            <ul>
              <li>{t('connectTgBot.steps.step4a')}</li>
              <li>{t('connectTgBot.steps.step4b')}</li>
            </ul>
            <li>{t('connectTgBot.steps.step5')}</li>
            <ul>
              <li>{t('connectTgBot.steps.step5a')}</li>
              <li>{t('connectTgBot.steps.step5b')}</li>
            </ul>
          </ol>
        </div>
      </div>
    </div>
  )
}
