import {
  useMemo,
  useCallback,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { IoPersonOutline, IoPencil, IoStar, IoEgg } from "react-icons/io5";
import { RiVipCrownFill } from "react-icons/ri";

import { Feed } from "../Feed";
import { useApi } from "../../hooks/useApi";
import { formatNumber } from "../../utils/formatNumber";
import { UserContext } from "../UserProvider";
import { CropAvatar } from "../CropAvatar";
import { useTranslation } from "react-i18next";

import styles from "./Profile.module.css";

const tg = window.Telegram.WebApp;

// Add this component for the subscription badge
const SubscriptionBadge = ({ tier }) => {
  const tierName = tier.name?.toLowerCase();

  // Get tier-specific attributes for styling
  const getTierColor = useCallback((tierName) => {
    const colors = {
      basic: "rgb(166, 103, 255)", // Professional blue - trustworthy, stable
      premium: "rgb(255, 215, 0)", // Classic gold - premium, luxury
      diamond: "rgb(81, 255, 101)", // Bright turquoise - exclusive, modern
    };
    return colors[tierName] || "rgb(166, 103, 255)";
  }, []);

  // Define styles and icons based on tier
  const getBadgeProps = () => {
    switch (tierName) {
      case "basic":
        return {
          icon: <IoEgg size={10} />,
          backgroundColor: getTierColor("basic"),
          label: "",
        };
      case "premium":
        return {
          icon: <IoStar size={10} />,
          backgroundColor: getTierColor("premium"),
          label: "",
        };
      case "diamond":
        return {
          icon: <RiVipCrownFill size={10} />,
          backgroundColor: getTierColor("diamond"),
          label: "",
        };
      default:
        return null;
    }
  };

  const badgeProps = getBadgeProps();

  if (!badgeProps) return null;
  if (!tier) return null;

  return (
    <div
      className={styles.subscriptionBadge}
      style={{ backgroundColor: badgeProps.backgroundColor }}
    >
      <span className={styles.badgeIcon}>{badgeProps.icon}</span>
    </div>
  );
};

export function Profile({
  user,
  setUser,
  itsMe,
  onPayWallOpen,
}) {
  const apiOptions = useMemo(
    () => ({
      baseUrl: process.env.REACT_APP_API_URL,
      requestOptions: {
        headers: {
          "x-init-data": tg.initData,
        },
      },
    }),
    []
  );

  const loaderRef = useRef(null);

  const {
    user: { useFollow, useUnfollow, useAvatarUpload },
  } = useApi(apiOptions);
  const { fetch: avatarUploadFetch } = useAvatarUpload();
  const { fetch: fetchFollow } = useFollow(user.nickname);
  const { fetch: fetchUnfollow } = useUnfollow(user.nickname);

  const { user: me } = useContext(UserContext);

  const avatarFileRef = useRef(null);

  const [errors, setErrors] = useState([]);
  const [avatarUploadLoading, setAvatarUploadLoading] = useState(false);
  const [newAvatar, setNewAvatar] = useState(null);
  const [loading, setLoading] = useState(false);

  const [loadMoreHooks, setLoadMoreHooks] = useState({
    feed: null,
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (!loaderRef.current) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && loadMoreHooks.feed) {
          loadMoreHooks.feed();
        }
      },
      { threshold: 1.0 }
    );

    observer.observe(loaderRef.current);
    return () => observer.disconnect();
  }, [loaderRef.current, loadMoreHooks]);

  const onAvatarUpload = useCallback(() => {
    avatarFileRef.current.click();
  }, [avatarFileRef]);

  const onAvatarChange = useCallback((e) => {
    const file = e.target.files[0];

    let numberOfBytes = 0;
    for (const file of e.target.files) {
      numberOfBytes += file.size;
    }

    const allowedMimeTypes = ["image/jpeg", "image/png", "image/webp"];
    if (!allowedMimeTypes.includes(file.type)) {
      setErrors((state) => [
        ...state,
        "Invalid file type. Only JPEG, PNG, and WebP are allowed.",
      ]);
      return;
    }

    if (numberOfBytes > 1024 * 1024 * 2) {
      setErrors((state) => [...state, "File size must be less than 2MB"]);
      return;
    }

    file.arrayBuffer().then((buffer) => {
      setNewAvatar(
        URL.createObjectURL(new Blob([buffer], { type: file.type }))
      );
    });
  }, []);

  const onAvatarSaveClick = useCallback(
    (file) => {
      const body = new FormData();

      body.append("file", file);

      setAvatarUploadLoading(true);
      avatarUploadFetch({ body })
        .then((res) => {
          setUser({
            ...user,
            avatar_url: res.avatar_url,
          });
          setNewAvatar(null);
          setAvatarUploadLoading(false);
        })
        .catch((e) => {
          setErrors((state) => [...state, e.message]);
          setAvatarUploadLoading(false);
        });

      avatarFileRef.current.value = "";

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [user]
  );

  const onAvatarCancelClick = useCallback(() => {
    setNewAvatar(null);
  }, []);

  const onFollowClick = useCallback(() => {
    if (loading) return;
    setLoading(true);
    fetchFollow({ nickname: user.nickname })
      .then(() => {
        setUser((prev) => {
          return {
            ...prev,
            followers: [...prev.followers, { nickname: me.nickname }],
          };
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [fetchFollow, user.nickname, setUser]);

  const onUnfollowClick = useCallback(() => {
    if (loading) return;
    setLoading(true);
    fetchUnfollow({ nickname: user.nickname })
      .then(() => {
        setUser((prev) => ({
          ...prev,
          followers: prev.followers.filter((follower) => {
            return follower.nickname !== me.nickname;
          }),
        }));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [fetchUnfollow, user.nickname, setUser]);

  const onAvatarEditClick = useCallback(() => {
    if (!itsMe) return;
    if (!user.avatar_url) {
      avatarFileRef.current.click();
      return;
    }

    try {
      const image = new Image();

      image.addEventListener(
        "load",
        () => {
          const canvas = document.createElement("canvas");

          canvas.width = image.width;
          canvas.height = image.height;

          const ctx = canvas.getContext("2d");

          ctx.drawImage(image, 0, 0);

          canvas.toBlob((blob) => {
            const reader = new FileReader();

            reader.onload = () => {
              const buffer = reader.result;
              setNewAvatar(
                URL.createObjectURL(new Blob([buffer], { type: "image/png" }))
              );
            };
            reader.readAsArrayBuffer(blob);
          });
        },
        false
      );

      image.crossOrigin = "anonymous";
      image.src = user.avatar_url + "?t=" + new Date().getTime();
    } catch (e) {
      setErrors((state) => [...state, "Error loading avatar"]);
    }
  }, [user]);

  const closePayWall = useCallback(() => {
    setIsPayWallOpen(false);
  }, []);

  return (
    <div className={styles.profile}>
      {errors.length > 0 && (
        <div className={styles.error}>
          {errors.map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </div>
      )}

      {newAvatar && (
        <div className={styles.field}>
          <div className={styles.crop}>
            <CropAvatar
              src={newAvatar}
              loading={avatarUploadLoading}
              onAvatarSaveClick={onAvatarSaveClick}
              onAvatarCancelClick={onAvatarCancelClick}
              onAvatarUpload={onAvatarUpload}
            />
          </div>
        </div>
      )}
      <div className={styles.header}>
        <div
          className={styles.avatarContainer}
          style={newAvatar ? { display: "none" } : {}}
        >
          <div
            className={styles.avatar}
            style={
              user.avatar_url
                ? { backgroundImage: `url(${user.avatar_url})` }
                : { backgroundColor: "#18373a" }
            }
            onClick={onAvatarEditClick}
          >
            {" "}
            {user.avatar_url ? "" : "no avatar"}
            {itsMe && (
              <div className={styles.avatarEdit}>
                <IoPencil size={20} />
              </div>
            )}
            <input
              ref={avatarFileRef}
              type="file"
              accept="image/jpeg, image/png, image/webp"
              onChange={onAvatarChange}
              style={{ display: "none" }}
            />
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.nameSection}>
            <div className={styles.name}>@{user.nickname}</div>
            {user.subscriptions?.some((sub) => sub.status === "ACTIVE") && (
              <SubscriptionBadge
                tier={
                  user.subscriptions.find((sub) => sub.status === "ACTIVE").tier
                }
              />
            )}
          </div>
          <div className={styles.stats}>
            <div className={styles.stat}>
              <b>{formatNumber(user._count.posts)}</b>{" "}
              <span className={styles.stat_label}>{t('profile.stats.posts')}</span>
            </div>
            <div className={styles.stat}>
              <b>{formatNumber(user._count.followers)}</b>{" "}
              <span className={styles.stat_label}>{t('profile.stats.followers')}</span>
            </div>
            <div className={styles.stat}>
              <b>{formatNumber(user._count.following)}</b>{" "}
              <span className={styles.stat_label}>{t('profile.stats.following')}</span>
            </div>
          </div>
        </div>
      </div>

      {itsMe && (
        <button
          className={styles.premiumButton}
          onClick={onPayWallOpen}
        >
          {user.subscriptions.length > 0 ? t('profile.buttons.yourSubscription') : t('profile.buttons.unlockPremium')}
        </button>
      )}

      {!itsMe && (
        <div className={styles.actions}>
          <button
            className={styles.action}
            onClick={
              user.followers.length > 0 ? onUnfollowClick : onFollowClick
            }
          >
            {loading ? (
              <div className={styles.loader} />
            ) : (
              <>
                <IoPersonOutline />{" "}
                {user.followers.length > 0 ? t('show.unfollow') : t('show.follow')}
              </>
            )}
          </button>
        </div>
      )}

      <div className={styles.feed}>
        <Feed
          nickname={user.nickname}
          setLoadMoreHooks={setLoadMoreHooks}
          isFeed={true}
        />
      </div>

      <div ref={loaderRef} style={{ height: "20px" }} />
    </div>
  );
}
