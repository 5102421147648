import React from 'react';
import { useTranslation } from "react-i18next";
import { Textarea } from './Textarea'

export function Bio({ value, onChange, error, generate }) {
    const { t } = useTranslation();
    
    return (
        <Textarea
            name="bio"
            text={t('character.form.bio.description')}
            placeholder={t('character.form.bio.placeholder')}
            onChange={onChange}
            maxLength={300}
            value={value}
            error={error}
            generate={generate}
        />
    )
}