import React, { useEffect, useState, useCallback, useMemo, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { IoHomeOutline, IoChatbubbleOutline, IoAddCircleOutline, IoPersonOutline } from 'react-icons/io5';
import { PiHandCoinsLight } from "react-icons/pi";

import { useApi } from '../../hooks/useApi';
import { Loader } from '../../components/Loader';
import { Profile as UserProfile } from '../../components/Profile';
import { UserContext } from '../../components/UserProvider';

import styles from './User.module.css';

const tg = window.Telegram.WebApp;

export function User() {
    const navigate = useNavigate();
    const { nickname } = useParams();

    const { user } = useContext(UserContext);

    const [profile, setProfile] = useState(null);

    const apiOptions = useMemo(() => ({
        baseUrl: process.env.REACT_APP_API_URL,
        requestOptions: {
            headers: {
                'x-init-data': tg.initData,
            }
        }
    }), [])

    const { user: { useProfile } } = useApi(apiOptions)
    const { fetch: fetchProfile } = useProfile(nickname)

    useEffect(() => {
        tg.expand()
        tg.BackButton.show()
        tg.BackButton.onClick(() => navigate(`/`))
        tg.disableVerticalSwipes()

        if (user.nickname === nickname) {
            setProfile(user)
        } else {
            fetchProfile().then(({ user }) => {
                setProfile(user)
            })
        }
    }, [navigate])

    const onAddCharacterClick = useCallback(() => navigate(`/character/create`), [navigate])
    const onChatsClick = useCallback(() => navigate(`/chats`), [navigate])
    const onHomeClick = useCallback(() => navigate(`/`), [navigate])

    const onProfileClick = useCallback(() => {
      navigate('/profile')
    }, [navigate])

    const onEarnClick = useCallback(() => {
      navigate('/earn')
    }, [navigate])

    if (!profile) {
        return <Loader />;
    }

    return (
        <div className={styles.profile + ' ' + styles[tg.platform]}>
            <div className={styles.container}>
                <UserProfile user={profile} setUser={setProfile} itsMe={user.nickname === nickname} />
            </div>

            <footer className={styles.footer}>
                <div className={styles.footerTabs}>
                    <button onClick={onHomeClick}><IoHomeOutline /></button>
                    <button onClick={onChatsClick}><IoChatbubbleOutline /></button>
                    <button className={styles.earnButton} onClick={onEarnClick}>
                        <PiHandCoinsLight />
                        <label>earn</label>
                    </button>
                    <button onClick={onAddCharacterClick}><IoAddCircleOutline /></button>
                    <button onClick={onProfileClick}><IoPersonOutline /></button>
                </div>
            </footer>
        </div>
    );
}
