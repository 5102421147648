import React, { useState, useCallback } from 'react';
import { IoArrowForward } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import styles from './Comments.module.css';
import { CreatePostModal } from '../CreatePostModal/CreatePostModal';
import { CommentsModal } from '../CommentsModal/CommentsModal';
import { CommentText } from './CommentText';

export function Comments({ post, onAddComment, onShowComments, showAllComments }) {
  const { t } = useTranslation();
  const [showAddComment, setShowAddComment] = useState(false);
  const [replyTo, setReplyTo] = useState(null);

  const handleAddComment = useCallback(async (text) => {
    await onAddComment(text, replyTo?.id);
    setShowAddComment(false);
  }, [onAddComment, replyTo]);

  const handleReply = useCallback((comment) => {
    console.log("comment", comment);
    setReplyTo(comment);
    setShowAddComment(true);
  }, []);

  return (
    <div className={styles.comments}>
      {post.comments.length > 0 && (
        <div className={styles.commentsList}>
          {post.comments.map((comment) => (
            <div key={comment.id} className={styles.comment}>
              <div className={styles.commentHeader}>
                <span className={styles.commentAuthor}>
                @{comment.author.nickname}
                </span>
                <span className={styles.commentDate}>
                {new Date(comment.created_at).toLocaleDateString()}
                </span>
            </div>
              <CommentText
                text={comment.content}
                onReply={() => handleReply(comment)}
              />
            </div>
          ))}
        </div>
      )}
      
      <div className={styles.commentsActions}>
        {post._count?.comments > 3 && (
          <button 
            className={styles.viewAllButton}
            onClick={() => onShowComments(true)}
          >
            {t('post.comments.showAll', { count: post._count.comments })}
          </button>
        )}
        
        <button 
          className={styles.addCommentButton}
          onClick={() => setShowAddComment(true)}
        >
          {t('post.comments.addComment')} <IoArrowForward />
        </button>
      </div>

      <CreatePostModal
        isOpen={showAddComment}
        onClose={() => setShowAddComment(false)}
        onSubmit={handleAddComment}
        title={replyTo ? t('commentsModal.replyTitle', { nickname: replyTo.author.nickname }) : t('commentsModal.addCommentTitle')}
        placeholder={replyTo ? t('commentsModal.replyPlaceholder') : t('commentsModal.commentPlaceholder')}
      />

      <CommentsModal
        isOpen={showAllComments}
        onClose={() => onShowComments(false)}
        post={post}
        onAddComment={onAddComment}
        replyTo={replyTo}
      />
    </div>
  );
} 