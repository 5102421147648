import React, { useEffect, useState, useMemo, useCallback, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { IoHomeOutline, IoChatbubbleOutline, IoAddCircleOutline, IoPersonOutline, IoEyeOutline, IoEyeOffOutline, IoTrash } from 'react-icons/io5';
import { FaTelegramPlane } from 'react-icons/fa';
import { PiHandCoinsLight } from "react-icons/pi";

import { useApi } from '../../hooks/useApi';
import { Loader } from '../../components/Loader';
import { UserContext } from '../../components/UserProvider';
import { Profile as UserProfile } from '../../components/Profile';
import { formatNumber } from '../../utils/formatNumber';
import { PayWall } from '../../components/PayWall/PayWall';

import styles from './Profile.module.css';

const tg = window.Telegram.WebApp;

const BROWSE_LEVELS = ['PG', 'PG_13', 'R', 'X', 'XXX']

const TransactionsList = () => {
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10
  });

  const apiOptions = useMemo(() => ({
    baseUrl: process.env.REACT_APP_API_URL,
    requestOptions: {
      headers: {
        'x-init-data': window.Telegram.WebApp.initData,
      }
    }
  }), []);

  const { user: { useTransactions } } = useApi(apiOptions);
  const { fetch: fetchTransactions, loading, data } = useTransactions();

  const { t } = useTranslation();

  const fetchPage = useCallback(async (page = 1) => {
    try {
      await fetchTransactions({ 
        query: { 
          page, 
          limit: pagination.limit 
        } 
      });
    } catch (error) {
      console.error('Failed to fetch transactions:', error);
    }
  }, [fetchTransactions, pagination.limit]);

  useEffect(() => {
    fetchPage(pagination.page);
  }, [pagination.page]);

  const handlePageChange = (event, page) => {
    setPagination(prev => ({ ...prev, page }));
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString({
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  const getPageNumbers = useCallback((currentPage, totalPages) => {
    const delta = 1;
    const left = currentPage - delta;
    const right = currentPage + delta;
    const range = [];
    
    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        (i >= left && i <= right)
      ) {
        range.push(i);
      }
    }
    
    const result = [];
    let prev = 0;
    
    for (const i of range) {
      if (prev + 1 < i) {
        result.push('...');
      }
      result.push(i);
      prev = i;
    }
    
    return result;
  }, []);

  if (loading) {
    return <div className="flex justify-center p-4">{t('profile.loading')}</div>;
  }

  const transactions = data?.transactions || [];
  const paginationData = data?.pagination;

  return (
    <div className={styles.transactionsContainer}>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>{t('profile.transactions.date')}</th>
              <th>{t('profile.transactions.kind')}</th>
              <th>{t('profile.transactions.amount')}</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction) => (
              <tr key={transaction.id}>
                <td>{formatDate(transaction.created_at)}</td>
                <td>{t(`profile.transactions.types.${transaction.kind.replace(/ /g, '_')}`)}</td>
                <td className={transaction.amount < 0 ? styles.red : styles.green}>{transaction.amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {paginationData?.pages > 1 && (
        <div className={styles.pagination}>
          <button 
            className={styles.paginationButton}
            disabled={paginationData.page === 1}
            onClick={() => handlePageChange(null, paginationData.page - 1)}
          >
            {t('profile.transactions.pagination.previous')}
          </button>
          
          <div className={styles.paginationPages}>
            {getPageNumbers(paginationData.page, paginationData.pages).map((pageNum, idx) => (
              pageNum === '...' ? (
                <span key={`dots-${idx}`} className={styles.paginationDots}>...</span>
              ) : (
                <button
                  key={pageNum}
                  onClick={() => handlePageChange(null, pageNum)}
                  className={`${styles.paginationPage} ${pageNum === paginationData.page ? styles.active : ''}`}
                >
                  {pageNum}
                </button>
              )
            ))}
          </div>

          <button
            className={styles.paginationButton}
            disabled={paginationData.page === paginationData.pages}
            onClick={() => handlePageChange(null, paginationData.page + 1)}
          >
            {t('profile.transactions.pagination.next')}
          </button>
        </div>
      )}
    </div>
  );
};

const languages = {
  en: { code: 'en', name: 'English' },
  ru: { code: 'ru', name: 'Русский' },
  fr: { code: 'fr', name: 'Français' },
  es: { code: 'es', name: 'Español' },
  de: { code: 'de', name: 'Deutsch' }
};

const tabs = ['profile', 'settings', 'credits', 'bots']

export function Profile() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const defaultTab = searchParams.get('tab');

    const { user, setUser } = useContext(UserContext);

    const [tab, setTab] = useState(defaultTab === 'subscription' ? 'profile' : tabs.includes(defaultTab) ? defaultTab : 'profile');
    const [fullscreen] = useState(false);
    const [amount, setAmount] = useState(5000);
    const [invoiceError, setInvoiceError] = useState(null);
    const [nicknameIsChanged, setNicknameIsChanged] = useState(false);
    const [nicknameError, setNicknameError] = useState(null);
    const [openedBot, setOpenedBot] = useState(null);
    const [botDeleteBotnames, setBotDeleteBotnames] = useState({});
    const [botDeleteTimeouts, setBotDeleteTimeouts] = useState({});
    const [success, setSuccess] = useState(null);
    const [browsingLevel, setBrowsingLevel] = useState(null);
    const [ratings, setRatings] = useState({
        'PG': ['PG', 'PG_13'],
        'PG_13': ['PG', 'PG_13'],
    });
    
    const paymentStatus = searchParams.get('payment');
    const [isPayWallOpen, setIsPayWallOpen] = useState(['success', 'canceled'].includes(paymentStatus) || defaultTab === 'subscription');

    const [username, setUsername] = useState(null);
    const [language, setLanguage] = useState(user.language_code);
    const [isAdult, setIsAdult] = useState(user.is_adult);

    const apiOptions = useMemo(() => ({
        baseUrl: process.env.REACT_APP_API_URL,
        requestOptions: {
            headers: {
                'x-init-data': tg.initData,
            }
        }
    }), [])

    const { user: { useGetInvoice, useUpdateNickname, useBots, useSetLanguage, useEnableAdult, useBrowsingLevel }, character: { useDisconnectTgBot } } = useApi(apiOptions)
    const { fetch: fetchInvoice } = useGetInvoice();
    const { fetch: fetchUpdateNickname, loading: updateNicknameLoading } = useUpdateNickname();
    const { fetch: botsFetch, data: botsData } = useBots();
    const { fetch: disconnectTgBotFetch, loading: disconnectTgBotLoading } = useDisconnectTgBot()
    const { fetch: fetchBrowsingLevel } = useBrowsingLevel()
    const { fetch: fetchSetLanguage, loading: setLanguageLoading } = useSetLanguage()
    const { fetch: fetchEnableAdult } = useEnableAdult()

    useEffect(() => {
        if (!isAdult) {
            setRatings({
                'PG': ['PG', 'PG_13'],
                'PG_13': ['PG', 'PG_13'],
            })
        } else {
            setRatings({
                'PG': ['PG', 'PG_13', 'R', 'X', 'XXX'],
                'PG_13': ['PG', 'PG_13', 'R', 'X', 'XXX'],
                'R': ['PG', 'PG_13', 'R', 'X', 'XXX'],
                'X': ['PG', 'PG_13', 'R', 'X', 'XXX'],
                'XXX': ['PG', 'PG_13', 'R', 'X', 'XXX'],
            })
        }
    }, [isAdult])

    useEffect(() => {
        tg.expand()
        tg.BackButton.show()
        tg.BackButton.onClick(() => navigate(`/`))
        tg.disableVerticalSwipes()
    }, [navigate])

    useEffect(() => {
        if (!user) return;
        botsFetch()
        setLanguage(user.language_code)
        setIsAdult(user.is_adult)
        setBrowsingLevel(BROWSE_LEVELS.indexOf(user.filter_rating))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    const onChangeAmount = useCallback((value) => {
        setInvoiceError(null)
        setAmount(value)
    }, [])

    const onAddCharacterClick = useCallback(() => navigate(`/character/create`), [navigate])
    const onChatsClick = useCallback(() => navigate(`/chats`), [navigate])
    const onHomeClick = useCallback(() => navigate(`/`), [navigate])

    const [isInvoicePending, setIsInvoicePending] = useState(false);

    const onSendInvoice = useCallback((amount) => {
        setIsInvoicePending(true)

        if (amount < 100) {
            setAmount(100);
            setInvoiceError(t('profile.errors.minimumAmount'));
            return;
        }

        if (amount % 100 !== 0) {
            setAmount(Math.ceil(amount / 100) * 100);
            setInvoiceError(t('profile.errors.multipleOf100'));
            return;
        }

        if (amount > 10000000) {
            setAmount(10000000);
            setInvoiceError(t('profile.errors.maximumAmount'));
            return;
        }

        fetchInvoice({ query: { amount } }).then((data) => {
            tg.openInvoice(data.link)
            setIsInvoicePending(false)
        }).catch(() => {
            setIsInvoicePending(false)
        })
    }, [t])

    const onChangeNickname = useCallback((e) => {
        setNicknameError(null)
        setUsername(e.target.value)
        setNicknameIsChanged(user.nickname !== e.target.value)
    }, [user.nickname])

    const onSaveNickname = useCallback(() => {
        if (!username) {
            setNicknameError(t('profile.errors.nicknameRequired'))
            return
        }

        if (username.length > 32) {
            setNicknameError(t('profile.errors.nicknameTooLong'))
            return
        }

        if (username.length < 3) {
            setNicknameError(t('profile.errors.nicknameTooShort'))
            return
        }

        if (!/^[a-zA-Z0-9_]+$/.test(username)) {
            setNicknameError(t('profile.errors.nicknameInvalid'))
            return
        }

        fetchUpdateNickname({ body: JSON.stringify({ nickname: username }) })
            .then(() => {
                setUser({
                    ...user,
                    nickname: username
                })
                setNicknameIsChanged(false)
                setSuccess(t('profile.success.nicknameSaved'))

                setTimeout(() => {
                    setSuccess(null)
                }, 3000)
            })
            .catch(() => {
                setNicknameError(t('profile.errors.nicknameInUse'))
            })
    }, [fetchUpdateNickname, setUser, t, user, username])

    const onChangeTab = useCallback((tab) => () => {
        setTab(tab)
        setSearchParams({ tab })
    }, [setTab, setSearchParams])

    const onBotClick = useCallback((botname) => () => {
        tg.openTelegramLink(`https://t.me/${botname}`)
    }, [])

    const onOpenBotClick = useCallback((botname) => () => {
        if (botDeleteBotnames[botname]) return;
        if (openedBot === botname) {
            setOpenedBot(null)
        } else {
            setOpenedBot(botname)
        }
    }, [openedBot, botDeleteBotnames])

    const onBotDelete = useCallback((character_id, botname) => async () => {
        if (botDeleteBotnames[botname]) return;
        setBotDeleteBotnames({ ...botDeleteBotnames, [botname]: true })

          setBotDeleteTimeouts({ ...botDeleteTimeouts, [botname]: setTimeout(() => {
            disconnectTgBotFetch({ body: JSON.stringify({ id: character_id, telegram_botname: botname }) }).then(() => {
              botsFetch()
              setBotDeleteBotnames({ ...botDeleteBotnames, [botname]: false })
              setBotDeleteTimeouts({ ...botDeleteTimeouts, [botname]: null })
              setOpenedBot(null)
            }).catch((error) => {
              alert(`Bot ${botname} deletion failed: ${error}`)
            })
          }, 3100) })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [botDeleteBotnames])

    const onCancelBotDelete = useCallback((botname) => () => {
        if (disconnectTgBotLoading) return;
        setBotDeleteBotnames({ ...botDeleteBotnames, [botname]: false })
        clearTimeout(botDeleteTimeouts[botname])
        setBotDeleteTimeouts({ ...botDeleteTimeouts, [botname]: null })
    }, [botDeleteBotnames, botDeleteTimeouts, disconnectTgBotLoading])

    const onChangeLanguage = useCallback((e) => {
        setLanguage(e.target.value)
        i18n.changeLanguage(e.target.value in languages ? languages[e.target.value].code : 'en');
        setUser((prevUser) => ({ ...prevUser, language_code: e.target.value }))
        fetchSetLanguage({ body: JSON.stringify({ language: e.target.value }) }).then((data) => {
            setSuccess('Language changed successfully')
            setTimeout(() => {
                setSuccess(null)
            }, 3000)
        }).catch((error) => {
            console.error(error)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onChangeAdult = useCallback((e) => {
        setIsAdult(e.target.checked)
        setUser((prevUser) => ({ ...prevUser, is_adult: e.target.checked, filter_rating: e.target.checked ? 'R' : 'PG' }))
        fetchEnableAdult({ body: JSON.stringify({ is_adult: e.target.checked }) }).then((data) => {
            setSuccess('Adult content is ' + (e.target.checked ? 'enabled' : 'disabled'))
            setTimeout(() => {
                setSuccess(null)
            }, 3000)
            setIsAdult(data.is_adult)
        }).catch((error) => {})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAdult])

    const onChangeBrowsingLevel = useCallback((level) => () => {
        setBrowsingLevel(level)
        setUser((prevUser) => ({ ...prevUser, filter_rating: BROWSE_LEVELS[level] }))
        fetchBrowsingLevel({ body: JSON.stringify({ browsing_level: BROWSE_LEVELS[level] }) }).then((data) => {
            setSuccess('Browsing level changed successfully')
            setTimeout(() => {
                setSuccess(null)
            }, 3000)
        }).catch((error) => {})

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [browsingLevel])

    const onProfileClick = useCallback(() => {
      navigate('/profile')
    }, [navigate])

    const onEarnClick = useCallback(() => {
      navigate('/earn')
    }, [navigate])

    const closePayWall = useCallback(() => {
        setIsPayWallOpen(false);
    }, []);

    if (!user) {
        return <Loader />;
    }

    return (
        <div className={styles.profile + ' ' + styles[tg.platform] + ' ' + (fullscreen ? styles.fullscreen : '')}>
            <div className={styles.container}>
                <PayWall
                    isOpen={isPayWallOpen}
                    onClose={closePayWall}
                    paymentStatus={paymentStatus}
                    message="Upgrade to achieve thousands of messages and more exclusive features!"
                />
                <div className={styles.tabs}>
                    <div data-tab="profile" className={styles.tab + ' ' + (tab === 'profile' ? styles.active : '')} onClick={onChangeTab('profile')}>
                        <span>{t('profile.tabs.profile')}</span>
                    </div>
                    <div data-tab="settings" className={styles.tab + ' ' + (tab === 'settings' ? styles.active : '')} onClick={onChangeTab('settings')}>
                        <span>{t('profile.tabs.settings')}</span>
                    </div>
                    <div data-tab="credits" className={styles.tab + ' ' + (tab === 'credits' ? styles.active : '')} onClick={onChangeTab('credits')}>
                        <span>{t('profile.tabs.credits')}</span>
                    </div>
                    <div data-tab="bots" className={styles.tab + ' ' + (tab === 'bots' ? styles.active : '')} onClick={onChangeTab('bots')}>
                        <span>{t('profile.tabs.bots')}</span>
                    </div>
                </div>

                {success && (
                    <div className={styles.success}>
                        <div>{success}</div>
                    </div>
                )}

                {tab === 'profile' && (
                    <UserProfile user={user} setUser={setUser} itsMe={true} onPayWallOpen={() => setIsPayWallOpen(true)} />
                )}

                {tab === 'settings' && (
                    <>
                        <div className={styles.field}>
                            <div className={styles.fieldLabel}>
                                {t('profile.fields.nickname')}
                            </div>
                            <div className={styles.fieldValue + ' ' + styles.nickname + ' ' + (nicknameError ? styles.fieldError : '')}>
                                <input disabled={updateNicknameLoading} value={username || user.nickname} onChange={onChangeNickname} />
                                {nicknameIsChanged && <button className={styles.saveNickname} disabled={updateNicknameLoading} onClick={onSaveNickname}>{t('profile.buttons.save')}</button>}
                            </div>
                            {nicknameError && <div className={styles.errorMessage}>{nicknameError}</div>}
                        </div>

                        <div className={styles.field}>
                            <div className={styles.fieldLabel}>
                                {t('profile.fields.language')}
                            </div>
                            <div className={styles.fieldValue}>
                                <div className={styles.language}>
                                    <select disabled={setLanguageLoading || setLanguageLoading} value={language} onChange={onChangeLanguage}>
                                        {Object.values(languages).map((lang) => (
                                            <option key={lang.code} value={lang.code}>{lang.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className={styles.field}>
                            <div className={styles.fieldLabel}>
                                <div className={styles.fieldLabel}>{t('profile.fields.enableNSFW')}</div>
                                <label className={styles.switch}>
                                    <input type="checkbox" checked={isAdult || false} onChange={onChangeAdult} value={isAdult} />
                                    <span className={styles.switcher}></span>
                                </label>
                            </div>
                        </div>

                        {
                            <div className={styles.field}>
                                <div className={styles.fieldLabel}>
                                    {t('profile.fields.browsingLevel')}
                                </div>
                                <div className={styles.fieldValue}>
                                    <div className={styles.browsingLevel}>
                                        { user.filter_rating in ratings && ratings[user.filter_rating].map((rating, index) => (
                                            <div onClick={onChangeBrowsingLevel(index)} className={browsingLevel >= index ? styles.active : ''}>{rating.replace('_', '-')}</div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                )}

                {tab === 'credits' && (
                    <div className={styles.credits}>
                        <div className={styles.field + ' ' + styles.balance}>
                            <div className={styles.fieldLabel}>
                                {t('profile.fields.balance')}
                            </div>
                            <div className={styles.fieldValue}>
                                <span>{formatNumber(user.stars)} {t('profile.credits')}</span>
                            </div>
                        </div>

                        <button
                            className={styles.premiumButton}
                            onClick={() => setIsPayWallOpen(true)}
                        >
                            {user.subscriptions.length > 0 ? t('profile.buttons.yourSubscription') : t('profile.buttons.unlockPremium')}
                        </button>

                        <div className={styles.field + ' ' + styles.buyStars + ' ' + (invoiceError ? styles.fieldError : '')}>
                            {isInvoicePending && <div className={styles.invoicePending}>{t('profile.buttons.invoicePending')}</div>}
                            <div>
                                <span>{t('profile.buyCredits')}</span>
                                <span>{t('profile.buyCreditsInfo')}</span>
                            </div>
                            <div className={styles.buyStarsButtons}>
                                <button onClick={() => onSendInvoice(5000)}>{t('profile.creditsAmount.predefinedAmounts.small')}</button>
                                <button onClick={() => onSendInvoice(10000)}>{t('profile.creditsAmount.predefinedAmounts.medium')}</button>
                                <button onClick={() => onSendInvoice(15000)}>{t('profile.creditsAmount.predefinedAmounts.large')}</button>
                                <button onClick={() => onSendInvoice(20000)}>{t('profile.creditsAmount.predefinedAmounts.xlarge')}</button>
                            </div>
                            <div>
                                <span>{t('profile.creditsAmount.amount')}</span>
                                <input value={amount || 0} onChange={(e) => onChangeAmount(e.target.value)} />
                                <button onClick={() => onSendInvoice(amount)}>{t('profile.buttons.buy')}</button>
                            </div>
                            {invoiceError && <div className={styles.errorMessage}>{invoiceError}</div>}
                        </div>

                        <div className={styles.field + ' ' + styles.transactions}>
                            <div className={styles.fieldLabel}>
                                {t('profile.fields.transactionHistory')}
                            </div>
                            <TransactionsList />
                        </div>
                    </div>
                )}

                {tab === 'bots' && (
                    <div className={styles.bots}>
                        {
                            botsData?.bots.length === 0 && (
                                <div className={styles.menuTitle}><p>{t('profile.noBots')}</p></div>
                            )
                        }
                        {
                            botsData?.bots.map((bot) => (
                                <div key={bot.botname} className={styles.bot + ' ' + (openedBot === bot.botname ? styles.botOpened : '') + ' ' + (botDeleteBotnames[bot.botname] ? styles.botDeleting : '')} onClick={onOpenBotClick(bot.botname)}>
                                    <div className={styles.botDeletingOverlay} />
                                    {!disconnectTgBotLoading && <div className={styles.botDeletingText} onClick={onCancelBotDelete(bot.character.id, bot.botname)}>{t('profile.buttons.tapToConfirm')}</div>}
                                    <div className={styles.botContent}>
                                        <div className={styles.botHeader}>
                                            <div>
                                                <span className={styles.botname}>@{bot.botname}</span>
                                            </div>
                                            <div>
                                                <span className={styles.botAuthor}>@{bot.user.nickname}</span>
                                            </div>
                                        </div>
                                        <div className={styles.botFooter}>
                                            <div className={styles.botVisability}>
                                                {bot.is_public ? <IoEyeOutline /> : <IoEyeOffOutline />}
                                            </div>
                                            <div className={styles.botBubble}>
                                                <IoChatbubbleOutline />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.botActions}>
                                        <button onClick={onBotClick(bot.botname)} className={styles.button}>
                                            <FaTelegramPlane size={22} />
                                        </button>
                                        <button onClick={onBotDelete(bot.character.id, bot.botname)} className={styles.button}>
                                            {botDeleteBotnames[bot.botname] ? <IoTrash size={22} /> : t('profile.buttons.delete')}
                                        </button>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                )}
            </div>

            <footer className={styles.footer}>
                <div className={styles.footerTabs}>
                    <button onClick={onHomeClick}><IoHomeOutline /></button>
                    <button onClick={onChatsClick}><IoChatbubbleOutline /></button>
                    <button className={styles.earnButton} onClick={onEarnClick}>
                        <PiHandCoinsLight />
                        <label>{t('buttons.earn')}</label>
                    </button>
                    <button onClick={onAddCharacterClick}><IoAddCircleOutline /></button>
                    <button onClick={onProfileClick}><IoPersonOutline /></button>
                </div>
            </footer>
        </div>
    );
}
