import { useEffect, createContext, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useApi } from '../hooks/useApi';
import { useLongPolling } from '../hooks/useLongPolling';
import { Loader } from './Loader/Loader';

import styles from './UserProvider.module.css'

export const UserContext = createContext(null);

const tg = window.Telegram.WebApp

const BROWSE_LEVELS = ['PG', 'PG_13', 'R', 'X', 'XXX']

const languages = {
    en: { code: 'en', name: 'English' },
    ru: { code: 'ru', name: 'Русский' },
    fr: { code: 'fr', name: 'Français' },
    es: { code: 'es', name: 'Español' },
    de: { code: 'de', name: 'Deutsch' }
};

export const UserProvider = ({ children }) => {
    const { i18n } = useTranslation();

    const apiOptions = useMemo(() => ({
        baseUrl: process.env.REACT_APP_API_URL,
        requestOptions: {
            headers: {
                'x-init-data': tg.initData,
            }
        }
    }), [])

    const [user, setUser] = useState(null);
    const [isAdult, setIsAdult] = useState(null);
    const [browsingLevel, setBrowsingLevel] = useState(null);
    const { user: { useMe, useEnableAdult, useBrowsingLevel } } = useApi(apiOptions)
    const { fetch: fetchEnableAdult } = useEnableAdult()
    const { fetch: fetchBrowsingLevel } = useBrowsingLevel()
    const { fetch: fetchMe } = useMe()

    const onChangeAdult = useCallback((value) => () => {
        fetchEnableAdult({
            body: JSON.stringify({ is_adult: value })
        }).then(() => {
            setIsAdult(value)
            setUser({ ...user, is_adult: value })
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    const onChangeBrowsingLevel = useCallback((level) => () => {
        setBrowsingLevel(level)
        fetchBrowsingLevel({ body: JSON.stringify({ browsing_level: BROWSE_LEVELS[level] }) }).then((data) => {
            setTimeout(() => {
                fetchMe().then((data) => {
                    const { telegram_user, ...userData } = data
                    setUser({ ...user, ...userData.user, ...telegram_user })
                    setBrowsingLevel(userData.filter_rating)
                })
            }, 1000)
        }).catch((error) => {
            console.error(error)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSuccess = useCallback((data) => {
        const { user: { telegram_user, ...userData } } = data
        setUser((prev) => {
            i18n.changeLanguage(telegram_user.language_code in languages ? languages[telegram_user.language_code].code : 'en');
            return { ...prev, ...userData, ...telegram_user }
        })
        setBrowsingLevel(userData.filter_rating)
    }, [])

    const onError = useCallback((error) => {
        console.error(error)
    }, [])
    
    const fetchMeCall = useCallback(() => {
        return fetchMe()
    }, [])

    useLongPolling({
        fetch: fetchMeCall,
        onSuccess,
        onError,
        interval: 10000,
        maxRetries: 15,
        enabled: true,
        dependencies: []
    })

    if (!user) {
        return <Loader />
    }

    return <UserContext.Provider value={{ user, setUser, fetchMeCall, onSuccess, onError }}>
            {
                user && user.filter_rating && user.filter_rating === 'NOT_RATED' ? (
                    <>
                        <div className={styles.overlay}>
                            <div className={styles.container}>
                                { isAdult === null ? (
                                    <>
                                        <p>Hello @{user.nickname}, to make your experience better and safe, please answer a few questions</p>
                                        <div className={styles.question}>Do you want to see NSFW/adult content?</div>
                                        <div className={styles.buttons}>
                                            <button className={styles.button} onClick={onChangeAdult(true)}>Yes</button>
                                            <button className={styles.button} onClick={onChangeAdult(false)}>No</button>
                                        </div>
                                    </>
                                ) : (
                                    isAdult && browsingLevel === 'NOT_RATED' ? (
                                        <>
                                            <p>Hello @{user.nickname}, to make your experience better and safe, please answer a few questions</p>
                                            <div className={styles.field}>
                                                <div className={styles.fieldLabel}>
                                                    What rating of content is appropriate for you?
                                                </div>
                                                <div className={styles.fieldValue}>
                                                    <div className={styles.browsingLevel}>
                                                        <div onClick={onChangeBrowsingLevel(1)} className={browsingLevel >= 1 ? styles.active : ''}>PG-13</div>
                                                        <div onClick={onChangeBrowsingLevel(2)} className={browsingLevel >= 2 ? styles.active : ''}>R</div>
                                                        <div onClick={onChangeBrowsingLevel(3)} className={browsingLevel >= 3 ? styles.active : ''}>X</div>
                                                        <div onClick={onChangeBrowsingLevel(4)} className={browsingLevel >= 4 ? styles.active : ''}>XXX</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <div className={styles.question}>Thanks for your answers. Enjoy!</div>
                                    )
                                )}

                                <div className={styles.footer}>
                                <p>By continuing to use this service, you confirm that you are 18 or older and have read and agree to our <a href="https://blabber.live/terms-of-service" target="_blank" rel="noopener noreferrer">Terms of Service</a> and <a href="https://blabber.live/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
                                </div>
                            </div>
                        </div>
                        <div className={styles.blur}>
                            { children }
                        </div>
                    </>
            ) : (children)
        }

    </UserContext.Provider>;
}