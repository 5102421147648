import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useContext,
} from "react";
import { IoStar, IoLogoUsd, IoClose } from "react-icons/io5";
import { motion } from "framer-motion";
import { createPortal } from "react-dom";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { loadStripe } from "@stripe/stripe-js";

import { useApi } from "../../hooks/useApi";
import { UserContext } from "../UserProvider";

import styles from "./PayWall.module.css";

const tg = window.Telegram.WebApp;
const TG_STARS_COST = 0.025;

async function loadStripeWithRetry(retries = 10) {
  try {
    return loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  } catch (error) {
    console.error(error);
    if (retries > 0) {
      return loadStripeWithRetry(retries - 1);
    }
    return null;
  }
}

export const PayWall = ({
  isOpen,
  onClose,
  paymentStatus,
  message = "You've reached your message limit for today",
}) => {
  const { t } = useTranslation();

  const apiOptions = useMemo(
    () => ({
      baseUrl: process.env.REACT_APP_API_URL,
      requestOptions: {
        headers: {
          "x-init-data": tg.initData,
        },
      },
    }),
    []
  );

  const { user, fetchMeCall, onSuccess, onError } = useContext(UserContext);
  const { subscription } = useApi(apiOptions);
  const {
    fetch: fetchPlans,
    data: plansData,
    loading: plansLoading,
  } = subscription.usePlans();

  const {
    fetch: fetchInvoice,
    loading: invoiceLoading,
  } = subscription.useInvoice();

  const {
    fetch: fetchCreateCheckoutSession,
    loading: createCheckoutSessionLoading,
  } = subscription.useCreateCheckoutSession();

  const {
    fetch: fetchCreatePortalSession,
    loading: portalSessionLoading,
  } = subscription.useCreatePortalSession();

  const {
    fetch: fetchUpdateSubscription,
    loading: updateSubscriptionLoading,
  } = subscription.useUpdate();

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("stripe");
  const [selectedTier, setSelectedTier] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showPaymentMethods, setShowPaymentMethods] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [hasHighestSubscription, setHasHighestSubscription] = useState(false);

  // Add state to track previous values
  const [prevValues, setPrevValues] = useState({
    rewards: 0,
    messages: 0,
    credits: 0,
    multiplier: 1,
    images: 0,
  });

  const [isUpgradeMode, setIsUpgradeMode] = useState(false);
  const [currentSubscription, setCurrentSubscription] = useState(null);

  useEffect(() => {
    setIsUpgradeMode((prev) => {
      const newIsUpgradeMode = user?.subscriptions?.some(
        (sub) => sub.status === "ACTIVE"
      );
      if (prev === newIsUpgradeMode) {
        return prev;
      }
      return newIsUpgradeMode;
    });

    setHasHighestSubscription(user?.subscriptions?.some(
      (sub) => sub.status === "ACTIVE" && sub.tier.name === "Diamond" && sub.plan.name === "Annual"
    ));

    let newCurrentSubscription = user?.subscriptions?.find(
      (sub) => sub.status === "ACTIVE"
    );

    setCurrentSubscription((prev) => {
      if (
        prev &&
        prev.plan.name === newCurrentSubscription.plan.name &&
        prev.tier.name === newCurrentSubscription.tier.name
      ) {
        return prev;
      }
      return newCurrentSubscription;
    });

    setSelectedTier((prev) => {
      if (prev) {
        return prev;
      }

      if (!newCurrentSubscription || newCurrentSubscription.tier.name === 'Promo') {
        return plansData?.tiers[1];
      }

      return newCurrentSubscription?.tier;
    });

    setSelectedPlan((prev) => {
      if (prev) {
        return prev;
      }

      if (!newCurrentSubscription || newCurrentSubscription.tier.name === 'Promo') {
        return plansData?.tiers[1].plans[1];
      }

      return newCurrentSubscription?.plan;
    });
  }, [plansData]);

  // First, add a helper function to check if a plan is the user's current plan
  const isCurrentPlan = useCallback(
    (plan, tier) => {
      if (!isUpgradeMode || !currentSubscription) return false;

      return (
        tier.name === currentSubscription.tier.name &&
        plan.name === currentSubscription.plan.name
      );
    },
    [isUpgradeMode, currentSubscription]
  );

  const calculateUpgradeFinalPrice = useCallback(
    (newPlanPrice, unusedValue) => {
      return Math.max(0, newPlanPrice - unusedValue);
    },
    []
  );

  // Add helper functions for upgrade calculations
  const calculateUnusedValue = useCallback((subscription, plan) => {
    if (!subscription || !plan) return 0;

    // Calculate days remaining
    const now = new Date();
    const expiryDate = new Date(subscription.expires_at);
    const startDate = new Date(subscription.starts_at || now);

    // Calculate remaining percentage
    const remainingDays = Math.max(
      0,
      Math.ceil((expiryDate - now) / (1000 * 60 * 60 * 24))
    );
    const totalDays = Math.ceil(
      (expiryDate - startDate) / (1000 * 60 * 60 * 24)
    );
    const percentRemaining = remainingDays / totalDays;

    // Calculate unused value
    const price = selectedPaymentMethod === 'telegram_stars' ? plan.price : plan.stripe_price / 1000;
    return parseFloat((price * percentRemaining).toFixed(2));
  }, []);

  // Add a function to check if a plan is lower than current plan within the same tier
  const isPlanLowerOrEqual = useCallback(
    (plan, tier) => {
      if (!isUpgradeMode || !currentSubscription) {
        return false;
      }

      const currentPlan = currentSubscription.plan;

      // If current plan has longer duration, this plan is lower
      if (
        tier.name === currentSubscription.tier.name &&
        plan.duration_months <= currentPlan.duration_months
      ) {
        return true;
      }

      // Calculate final price for upgrade
      const unusedValue = calculateUnusedValue(
        currentSubscription,
        currentPlan
      );

      const price = selectedPaymentMethod === 'telegram_stars' ? plan.price : plan.stripe_price / 1000;
      const finalPrice = calculateUpgradeFinalPrice(price , unusedValue);

      // If final price is 0, this plan is not available for upgrade
      if (finalPrice === 0) {
        return true;
      }

      // Otherwise, this is a valid upgrade within the same tier
      return false;
    },
    [isUpgradeMode, currentSubscription]
  );

  const plans = useMemo(() => {
    return {
      [plansData?.tiers[0].name]: plansData?.tiers[0].plans,
      [plansData?.tiers[1].name]: plansData?.tiers[1].plans,
      [plansData?.tiers[2].name]: plansData?.tiers[2].plans,
    };
  }, [plansData]);

  const availablePlans = useMemo(() => {
    if (selectedTier && plans[selectedTier.name]) {
      return plans[selectedTier.name].filter(
        (plan) => !isPlanLowerOrEqual(plan, selectedTier) || isCurrentPlan(plan, selectedTier)
      );
    }
  }, [selectedTier, plans, isPlanLowerOrEqual, isCurrentPlan]);

  const isTierDisabled = useCallback((tier) => {
    const availablePlans = plans[tier.name].filter(
      (plan) => !isPlanLowerOrEqual(plan, tier) || isCurrentPlan(plan, tier)
    );
    return !availablePlans.length;
  }, [plans]);

  useEffect(() => {
    if (selectedTier && plans[selectedTier.name]) {
      // If there are available plans and current plan is not available, select first available
      setSelectedPlan(availablePlans[0]);
    }
  }, [selectedTier, isPlanLowerOrEqual]);

  // Modify the header message based on subscription status
  const headerMessage = useMemo(() => {
    if (isUpgradeMode) {
      return t("paywall.upgradeMessage");
    }
    return message;
  }, [isUpgradeMode, message, t]);

  // Update previous values when tier or plan changes
  useEffect(() => {
    if (selectedTier) {
      setPrevValues((prev) => ({
        ...prev,
        rewards: prev.rewards || 0,
        multiplier: prev.multiplier || 1,
      }));
    }
  }, [selectedTier?.id]);

  useEffect(() => {
    if (selectedPlan) {
      setPrevValues((prev) => ({
        ...prev,
        messages: prev.messages || 0,
        credits: prev.credits || 0,
        images: prev.images || 0,
      }));
    }
  }, [selectedPlan?.id]);

  // When fetching plans, select initial tier and plan appropriately
  useEffect(() => {
    fetchPlans();
  }, []);

  // Add new states for payment tracking and congratulation
  const [showCongratulation, setShowCongratulation] = useState(
    paymentStatus === "success"
  );
  const [paymentPlan, setPaymentPlan] = useState(null);

  useEffect(() => {
    window.Telegram.WebView.onEvent("invoice_closed", (event, data) => {
      if (data.status === "cancelled") {
        setShowCongratulation(false);
      } else if (data.status === "paid") {
        fetchMeCall().then(async (data) => {
          await onSuccess(data)
          setCurrentSubscription(data.user.subscriptions[0])
          setShowCongratulation(true);
        }).catch(onError)
      }
    });
  }, [fetchMeCall, onSuccess, onError]);

  const handleSubscribeClick = useCallback(() => {
    const invoiceRequestBody = {
      planId: selectedPlan.id,
      redirectUrl: `${window.location.origin}${window.location.pathname}`,
    };

    if (selectedPaymentMethod === "stripe") {
      const stripePromise = loadStripeWithRetry(10);

      if (isUpgradeMode && currentSubscription.payment_method === 'STRIPE') {
        fetchUpdateSubscription({
          body: JSON.stringify({
            planId: selectedPlan.id,
          }),
        }).then((data) => {
          setCurrentSubscription(data.subscription)
          setShowCongratulation(true);
        });
      } else {
        fetchCreateCheckoutSession({
          body: JSON.stringify(invoiceRequestBody),
        }).then((data) => {
          stripePromise.then((stripe) => {
            tg.BackButton.onClick(() => {
              window.location.href = `${window.location.origin}${window.location.pathname}`;
            })
            tg.setBackgroundColor("#ffffff");
            tg.MainButton.hide();
            stripe.redirectToCheckout({ sessionId: data.session.id });
          });
        });
      }
    } else if (selectedPaymentMethod === "telegram_stars") {
      fetchInvoice({
        body: JSON.stringify(invoiceRequestBody),
      }).then((data) => {
        // Store the selected plan for the congratulation message
        setPaymentPlan(selectedPlan);

        // Open the invoice
        tg.openInvoice(data.link);
      });
    }
  }, [selectedPaymentMethod, selectedPlan]);

  const handleBackClick = (e) => {
    e.stopPropagation(); // Prevent event from bubbling up
    setShowPaymentMethods(false);
  };

  const handleTierChange = (tier) => {
    if (isTierDisabled(tier)) {
      return;
    }
    setSelectedTier(tier);
  };

  // Prevent background scrolling when modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  const getMonthlyPrice = useCallback(
    (plan) => {
      return (
        (selectedPaymentMethod === "telegram_stars"
          ? plan.price * TG_STARS_COST
          : plan.stripe_price / 1000) / plan.duration_months
      ).toFixed(2);
    },
    [selectedPaymentMethod]
  );

  // Get tier-specific attributes for styling
  const getTierColor = useCallback((tier) => {
    const colors = {
      basic: "rgb(166, 103, 255)", // Professional blue - trustworthy, stable
      premium: "rgb(255, 215, 0)", // Classic gold - premium, luxury
      diamond: "rgb(81, 255, 101)", // Bright turquoise - exclusive, modern
    };
    return colors[tier?.name?.toLowerCase()] || "rgb(166, 103, 255)";
  }, []);

  // Add this function inside the component body, before the return statement
  const calculateMonthlyDiscount = useCallback((plans, currentPlan) => {
    if (!plans || plans.length === 0 || !currentPlan) return null;

    // Find the monthly plan (assuming it's the one with duration_months = 1)
    const monthlyPlan = plans.find((p) => p.duration_months === 1);

    // If there's no monthly plan or current plan is the monthly plan, no discount
    if (!monthlyPlan || currentPlan.id === monthlyPlan.id) return null;

    // Calculate monthly price for both plans
    const monthlyPlanPrice = monthlyPlan.price * TG_STARS_COST;
    const currentPlanMonthlyPrice =
      (currentPlan.price * TG_STARS_COST) / currentPlan.duration_months;

    // Calculate discount percentage
    const discountPercentage =
      ((monthlyPlanPrice - currentPlanMonthlyPrice) / monthlyPlanPrice) * 100;

    return Math.round(discountPercentage);
  }, []);

  // Add handler for opening/closing instructions
  const toggleInstructions = useCallback(() => {
    setShowInstructions(!showInstructions);
  }, [showInstructions]);

  // Pass the tierColor as a CSS variable to the instruction modal
  const tierColor = getTierColor(selectedTier);

  // Add state for upgrade calculations
  const [upgradeDetails, setUpgradeDetails] = useState(null);

  // Calculate upgrade benefits when tier or plan changes
  useEffect(() => {
    if (isUpgradeMode && selectedTier && selectedPlan && currentSubscription) {
      // Get current plan details
      const currentPlan = currentSubscription.plan;
      const currentTier = currentSubscription.tier;

      // Calculate days remaining
      const now = new Date();
      const expiryDate = new Date(currentSubscription.expires_at);
      const startDate = new Date(currentSubscription.starts_at || now);

      // If already expired, no remaining days
      const remainingDays = Math.max(
        0,
        Math.ceil((expiryDate - now) / (1000 * 60 * 60 * 24))
      );
      const totalDays = Math.ceil(
        (expiryDate - startDate) / (1000 * 60 * 60 * 24)
      );
      const percentRemaining = remainingDays / totalDays;

      // Calculate unused value from current subscription
      const price = selectedPaymentMethod === 'telegram_stars' ? currentPlan.price : currentPlan.stripe_price / 1000;
      const unusedValue = parseFloat((price * percentRemaining).toFixed(2));

      // Calculate unused credits
      const unusedCredits = parseFloat((percentRemaining * currentPlan.monthly_credits).toFixed(2));

      // Calculate additional credits
      const additionalCredits = Math.round(
        selectedPlan.monthly_credits > currentPlan.monthly_credits
          ? selectedPlan.monthly_credits - unusedCredits
          : 0);

      // Calculate final price
      const _price = selectedPaymentMethod === 'telegram_stars' ? selectedPlan.price : selectedPlan.stripe_price / 1000;
      const finalPrice = Math.max(0, _price - unusedValue);

      // Calculate new expiry date
      const newExpiryDate = new Date();
      newExpiryDate.setMonth(
        newExpiryDate.getMonth() + selectedPlan.duration_months
      );

      setUpgradeDetails({
        currentPlan,
        currentTier,
        selectedPlan,
        selectedTier,
        remainingDays,
        totalDays,
        percentRemaining,
        unusedValue,
        unusedCredits,
        additionalCredits,
        finalPrice,
        newExpiryDate,
        messagingLimitChange:
          selectedPlan.messaging_limit - currentPlan.messaging_limit,
        imageGenerationLimitChange:
          selectedPlan.image_generation_limit -
          currentPlan.image_generation_limit,
        monthlyCreditsChange:
          selectedPlan.monthly_credits - currentPlan.monthly_credits,
        rewardsChange:
          selectedTier.author_rewards_pct - currentTier.author_rewards_pct,
        multiplierChange:
          selectedTier.social_rewards_multiplier -
          currentTier.social_rewards_multiplier,
      });
    }
  }, [isUpgradeMode, selectedTier, selectedPlan, currentSubscription, selectedPaymentMethod]);

  useEffect(() => {
    if (invoiceLoading && tg.MainButton.isVisible) {
      tg.MainButton.hide();
    } else if (!invoiceLoading && isOpen && !tg.MainButton.isVisible && !isCurrentPlan(selectedPlan, selectedTier)) {
      tg.MainButton.show();
    }
  }, [invoiceLoading]);

  useEffect(() => {
    if (isOpen && selectedPlan && selectedTier) {
      // Only show the button if there are available plans and user doesn't have highest subscription
      if (!hasHighestSubscription) {
        // Configure the MainButton
        const buttonText = isUpgradeMode
          ? t("paywall.buttons.upgrade", {
              price: upgradeDetails?.finalPrice || selectedPlan.price,
              usd: (
                (upgradeDetails?.finalPrice || selectedPlan.price) *
                TG_STARS_COST
              ).toFixed(2),
            })
          : t("paywall.buttons.subscribe", {
              price: selectedPlan.price,
              usd: (selectedPlan.price * TG_STARS_COST).toFixed(2),
            });

        // Set button text and show it
        tg.MainButton.setText(buttonText);

        // Get the tier color
        const tierColor = getTierColor(selectedTier);

        // Set params for the button including the color
        tg.MainButton.setParams({
          text: buttonText,
          text_color: "#000000",
          color: tierColor,
          has_shine_effect: true,
        });

        // Show the button
        if (!tg.MainButton.isVisible && !isCurrentPlan(selectedPlan, selectedTier)) {
          tg.MainButton.show();
        }

        // Add event listener for the button click
        tg.MainButton.onClick(handleSubscribeClick);
      } else {
        // Hide the button when there are no available plans
        if (tg.MainButton.isVisible) {
          tg.MainButton.hide();
        }
      }

      return () => {
        // Clean up when component unmounts or dependencies change
        tg.MainButton.offClick(handleSubscribeClick);
        if (tg.MainButton.isVisible) {
          tg.MainButton.hide();
        }
      };
    } else {
      // Hide the button when modal is closed or no plan is selected
      if (tg.MainButton.isVisible) {
        tg.MainButton.hide();
      }
    }
  }, [
    isOpen,
    selectedPlan,
    selectedTier,
    upgradeDetails,
    isUpgradeMode,
    hasHighestSubscription,
    handleSubscribeClick
  ]);

  const handleManageSubscription = () => {
    fetchCreatePortalSession({
      body: JSON.stringify({
        redirectUrl: `${window.location.origin}${window.location.pathname}`,
      }),
    }).then((data) => {
      tg.MainButton.hide();
      window.location.href = data.session.url;
    });
  };

  // Add congratulation modal component
  const congratulationModal = createPortal(
    <div
      className={styles.overlay}
      onClick={() => setShowCongratulation(false)}
    >
      <div
        className={styles.congratulationModal}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.congratsContent}>
          <div className={styles.congratsIcon}>🎉</div>
          <h2 className={styles.congratsTitle}>
            {t("paywall.congrats.title")}
          </h2>
          <p className={styles.congratsMessage}>
            {t("paywall.congrats.message", { tier: paymentPlan?.name || "" })}
          </p>

          <motion.button
            className={styles.ctaButton}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setShowCongratulation(false)}
          >
            {t("paywall.congrats.startUsing")}
          </motion.button>
        </div>
      </div>
    </div>,
    document.body
  );

  if (!isOpen && !showCongratulation) return null;

  // Render paywall content in a separate portal
  const paywallContent = createPortal(
    <div className={styles.overlay} onClick={onClose}>
      <div
        className={styles.paywall}
        onClick={(e) => e.stopPropagation()}
        style={{ "--tier-color": tierColor }}
      >
        {showPaymentMethods ? (
          <button className={styles.closeButton} onClick={handleBackClick}>
            {t("paywall.buttons.back")}
          </button>
        ) : (
          <button className={styles.closeButton} onClick={onClose}>
            {t("general.close")}
          </button>
        )}

        {showPaymentMethods ? (
          <>
            <div className={styles.header}>
              <h2>{t("paywall.paymentMethods.title")}</h2>
              <p className={styles.limitMessage}>
                {t("paywall.paymentMethods.chooseMethod")}
              </p>
            </div>

            <div className={styles.paymentMethods}>
              <button
                className={`${styles.paymentMethod} ${
                  selectedPaymentMethod === "stripe"
                    ? styles.selectedPaymentMethod
                    : ""
                }`}
                onClick={() => setSelectedPaymentMethod("stripe")}
              >
                <div className={styles.radio}>
                  <div
                    className={
                      selectedPaymentMethod === "stripe"
                        ? styles.radioInner
                        : ""
                    }
                  />
                </div>
                <div className={styles.paymentIcon}>
                  <IoLogoUsd size={24} />
                </div>
                <div className={styles.paymentInfo}>
                  <div className={styles.paymentTitle}>Stripe</div>
                  <div className={styles.paymentDescription}>
                    {t("paywall.paymentMethods.creditCard")}
                  </div>
                </div>
              </button>

              <button
                className={`${styles.paymentMethod} ${
                  selectedPaymentMethod === "telegram_stars"
                    ? styles.selectedPaymentMethod
                    : ""
                }`}
                onClick={() => setSelectedPaymentMethod("telegram_stars")}
              >
                <div className={styles.radio}>
                  <div
                    className={
                      selectedPaymentMethod === "telegram_stars"
                        ? styles.radioInner
                        : ""
                    }
                  />
                </div>
                <div className={styles.paymentIcon}>
                  <IoStar size={24} />
                </div>
                <div className={styles.paymentInfo}>
                  <div className={styles.paymentTitle}>Telegram Stars</div>
                  <div className={styles.paymentDescription}>
                    {t("paywall.paymentMethods.payWithStars")}
                  </div>
                </div>
              </button>
            </div>
          </>
        ) : (
          <>
            {!hasHighestSubscription && (
              <div className={styles.header}>
                {selectedTier && (
                  <motion.div
                    key={selectedTier.id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3 }}
                    className={styles.tierHeader}
                    style={{ color: tierColor }}
                  >
                    <h3>
                      {isUpgradeMode
                        ? isCurrentPlan(selectedPlan, selectedTier)
                          ? t("paywall.currentTier", {
                              tier: selectedTier.name,
                            })
                          : t("paywall.upgradeToTier", {
                              tier: selectedTier.name,
                            })
                        : t("paywall.tierSubscription", {
                            tier: selectedTier.name,
                          })}
                    </h3>
                    <p className={styles.tierDescription}>
                      {t(
                        `paywall.tierDescriptions.${selectedTier.name.toLowerCase()}`
                      )}
                    </p>
                  </motion.div>
                )}
                <p className={styles.limitMessage}>{headerMessage}</p>

                {isUpgradeMode && currentSubscription && (
                  <div className={styles.currentPlan}>
                    <p>
                      Current plan:{" "}
                      <strong>
                        {currentSubscription.tier.name}{" "}
                        {currentSubscription.plan.name}
                      </strong>
                    </p>
                    <p>
                      Expires:{" "}
                      {new Date(
                        currentSubscription.expires_at
                      ).toLocaleDateString()}
                    </p>
                    { user.stripe_customer && user.stripe_customer.stripe_id && currentSubscription.payment_method === 'STRIPE' &&
                      <a href="#" className={styles.manageSubscription} onClick={handleManageSubscription}>Manage Subscription</a>
                    }
                  </div>
                )}
              </div>
            )}

            {plansLoading || createCheckoutSessionLoading || updateSubscriptionLoading || portalSessionLoading || invoiceLoading ? (
              <div className={styles.loadingSubscriptionContainer}>
                <div className={styles.loadingIcon}>⭐️</div>
                <div className={styles.loadingContent}>
                  {/* <h3 className={styles.loadingTitle}>{t("paywall.loading.title")}</h3>
                  <p className={styles.loadingDescription}>{t("paywall.loading.subscriptionOptions")}</p> */}
                  <div className={styles.loadingAnimation}>
                    <div className={styles.loadingDot}></div>
                    <div className={styles.loadingDot}></div>
                    <div className={styles.loadingDot}></div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {/* Show special message for highest subscription */}
                {hasHighestSubscription ? (
                  <div className={styles.bestSubscriptionMessage}>
                    <div className={styles.crownIcon}>👑</div>
                    <div className={styles.bestPlanHeader}>
                      <h3 className={styles.bestPlanTitle}>
                        {t("paywall.bestPlan.title")}
                      </h3>
                      <p className={styles.bestPlanDescription}>
                        {t("paywall.bestPlan.message")}
                      </p>
                    </div>
                    <div className={styles.featureShowcase}>
                      <div className={styles.showcaseItem}>
                        <div className={styles.showcaseIcon}>💎</div>
                        <div className={styles.showcaseText}>
                          <div>{t("paywall.bestPlan.diamondTier")}</div>
                          <div className={styles.showcaseDetail}>
                            {t("paywall.bestPlan.maximumRewards")}
                          </div>
                        </div>
                      </div>
                      <div className={styles.showcaseItem}>
                        <div className={styles.showcaseIcon}>📅</div>
                        <div className={styles.showcaseText}>
                          <div>{t("paywall.bestPlan.annualPlan")}</div>
                          <div className={styles.showcaseDetail}>
                            {t("paywall.bestPlan.bestValue")}
                          </div>
                        </div>
                      </div>
                      <div className={styles.showcaseItem}>
                        <div className={styles.showcaseIcon}>🌟</div>
                        <div className={styles.showcaseText}>
                          <div>{t("paywall.bestPlan.vipStatus")}</div>
                          <div className={styles.showcaseDetail}>
                            {t("paywall.bestPlan.priorityFeatures")}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={styles.expiryInfo}>
                      {t("paywall.bestPlan.validUntil")}:
                      <div className={styles.expiryDate}>
                        {new Date(
                          currentSubscription.expires_at
                        ).toLocaleDateString()}
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {/* Tier Tabs */}
                    {plansData?.tiers && plansData.tiers.length > 0 && (
                      <div className={styles.tierTabs}>
                        {plansData.tiers.map((tier) => {
                          return (
                            <button
                              key={tier.id}
                              className={`${styles.tierTab}`}
                              style={{
                                borderColor:
                                  selectedTier?.name === tier.name
                                    ? tierColor
                                    : "transparent",
                                color:
                                  selectedTier?.name === tier.name
                                    ? tierColor
                                    : "inherit",
                              }}
                              disabled={isTierDisabled(tier)}
                              onClick={() => handleTierChange(tier)}
                            >
                              {tier.name}
                              {currentSubscription?.tier?.name ===
                                tier.name && (
                                <span className={styles.tierNoteLabel}>
                                  Current
                                </span>
                              )}
                            </button>
                          );
                        })}
                      </div>
                    )}

                    {/* Payment Methods */}
                    <div className={styles.paymentMethods}>
                      <button
                        className={`${styles.paymentMethod} ${
                          selectedPaymentMethod === "stripe"
                            ? styles.selectedPaymentMethod
                            : ""
                        }`}
                        onClick={() => setSelectedPaymentMethod("stripe")}
                      >
                        <div className={styles.radio}>
                          <div
                            className={`${styles.radioInner} ${
                              selectedPaymentMethod === "stripe"
                                ? styles.selected
                                : ""
                            }`}
                          />
                        </div>
                        <div className={styles.paymentIcon}>
                          <IoLogoUsd size={24} />
                        </div>
                        <div className={styles.paymentInfo}>
                          <div className={styles.paymentTitle}>Stripe</div>
                          <div className={styles.paymentDescription}>
                            {t("paywall.paymentMethods.creditCard")}
                          </div>
                        </div>
                      </button>

                      <button
                        className={`${styles.paymentMethod} ${
                          selectedPaymentMethod === "telegram_stars"
                            ? styles.selectedPaymentMethod
                            : ""
                        }`}
                        onClick={() =>
                          setSelectedPaymentMethod("telegram_stars")
                        }
                      >
                        <div className={styles.radio}>
                          <div
                            className={`${styles.radioInner} ${
                              selectedPaymentMethod === "telegram_stars"
                                ? styles.selected
                                : ""
                            }`}
                          />
                        </div>
                        <div className={styles.paymentIcon}>
                          <IoStar size={24} />
                        </div>
                        <div className={styles.paymentInfo}>
                          <div className={styles.paymentTitle}>
                            Telegram Stars
                          </div>
                          <div className={styles.paymentDescription}>
                            {t("paywall.paymentMethods.payWithStars")}
                          </div>
                        </div>
                      </button>
                    </div>

                    {/* Plans for Selected Tier */}
                    {selectedTier && plans[selectedTier.name] && (
                      <div className={styles.planContainer}>
                        {plans[selectedTier.name].map((plan) => {
                          const monthlyDiscount = calculateMonthlyDiscount(
                            plans[selectedTier.name],
                            plan
                          );
                          const isPlanActive = isCurrentPlan(
                            plan,
                            selectedTier
                          );
                          const isPlanDisabled =
                            isPlanActive ||
                            isPlanLowerOrEqual(plan, selectedTier);

                          // Calculate upgrade price for each plan when in upgrade mode
                          let upgradePriceForPlan = selectedPaymentMethod === 'telegram_stars' ? plan.price : plan.stripe_price / 1000;
                          if (
                            isUpgradeMode &&
                            currentSubscription &&
                            !isPlanDisabled
                          ) {
                            // Calculate days remaining
                            const now = new Date();
                            const expiryDate = new Date(
                              currentSubscription.expires_at
                            );
                            const startDate = new Date(
                              currentSubscription.starts_at || now
                            );

                            // Calculate remaining percentage
                            const remainingDays = Math.max(
                              0,
                              Math.ceil(
                                (expiryDate - now) / (1000 * 60 * 60 * 24)
                              )
                            );
                            const totalDays = Math.ceil(
                              (expiryDate - startDate) / (1000 * 60 * 60 * 24)
                            );
                            const percentRemaining = remainingDays / totalDays;

                            // Calculate unused value from current subscription
                            const unusedValue = parseFloat((
                              (selectedPaymentMethod === 'telegram_stars' ? currentSubscription.plan.price : currentSubscription.plan.stripe_price / 1000) * percentRemaining
                            ).toFixed(2));

                            // Calculate final price for this plan
                            upgradePriceForPlan = Math.max(
                              0,
                              (selectedPaymentMethod === 'telegram_stars' ? plan.price : (plan.stripe_price / 1000)) - unusedValue
                            );
                          }

                          return (
                            <div
                              onClick={() => setSelectedPlan(plan)}
                              key={plan.id}
                              className={`${styles.plan} ${
                                selectedPlan?.id === plan.id
                                  ? styles.selected
                                  : ""
                              } ${
                                isPlanDisabled && !isPlanActive
                                  ? styles.currentActivePlan
                                  : ""
                              }`}
                            >
                              {!isPlanDisabled && (
                                <div className={styles.radio}>
                                  <div className={styles.radioInner} />
                                </div>
                              )}

                              {isPlanActive && (
                                <div className={styles.currentPlanBadge}>
                                  Current
                                </div>
                              )}

                              {!isPlanActive && isPlanDisabled && (
                                <div
                                  className={styles.currentPlanBadge}
                                  style={{
                                    backgroundColor: "rgba(255,255,255,0.3)",
                                  }}
                                >
                                  Lower
                                </div>
                              )}

                              <div className={styles.planName}>{plan.name}</div>

                              <div className={styles.priceInfo}>
                                {/* Show calculated monthly discount if available */}
                                {monthlyDiscount > 0 && (
                                  <span className={styles.discount}>
                                    Save {monthlyDiscount}%
                                  </span>
                                )}

                                {/* Show upgrade price when in upgrade mode */}
                                {isUpgradeMode &&
                                !isPlanDisabled ? (
                                  <>
                                    <span className={styles.price}>
                                      {selectedPaymentMethod ===
                                      "telegram_stars"
                                        ? `${upgradePriceForPlan} ⭐️`
                                        : `$${(
                                            upgradePriceForPlan
                                          ).toFixed(2)}`}
                                    </span>
                                    <span className={styles.originalPrice}>
                                      {selectedPaymentMethod ===
                                      "telegram_stars"
                                        ? `${plan.price} ⭐️`
                                        : `$${(
                                            plan.stripe_price / 1000
                                          ).toFixed(2)}`}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <span className={styles.price}>
                                      {selectedPaymentMethod ===
                                      "telegram_stars"
                                        ? `${plan.price} ⭐️`
                                        : `$${(
                                            plan.stripe_price / 1000
                                          ).toFixed(2)}`}
                                    </span>
                                    {plan.original_price > 0 &&
                                      selectedPaymentMethod ===
                                        "telegram_stars" && (
                                        <span className={styles.originalPrice}>
                                          ${plan.original_price}
                                        </span>
                                      )}
                                  </>
                                )}

                                {plan.duration_months > 0 && (
                                  <div className={styles.perMonth}>
                                    ${getMonthlyPrice(plan)}/mo
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}

                    {selectedPlan &&
                      selectedPaymentMethod === "telegram_stars" && (
                        <>
                          {/* Add the "Buy Stars Cheaper" link */}
                          <div
                            className={styles.buyStarsLink}
                            onClick={toggleInstructions}
                          >
                            {t("paywall.buyStarsCheaper")}
                          </div>
                        </>
                      )}

                    {/* Features with Animated Values */}
                    {selectedTier && selectedPlan && (
                      <div className={styles.features}>
                        <div className={styles.feature}>
                          <div className={styles.featureIcon}>💰</div>
                          <div className={styles.featureInfo}>
                            <div className={styles.featureTitle}>
                              {t("paywall.features.earnCredits", {
                                tier: selectedTier.name,
                              })}
                            </div>
                            <div className={styles.featureDescription}>
                              <p>
                                🔥{" "}
                                {t(
                                  "paywall.features.authorEarnings.createCharacters"
                                )}
                              </p>
                              <p>
                                💬{" "}
                                {t("paywall.features.authorEarnings.usersChat")}
                              </p>
                              <p>
                                💎{" "}
                                {t(
                                  "paywall.features.authorEarnings.earnPercentagePrefix"
                                )}{" "}
                                <mark>
                                  <CountUp
                                    key={`rewards-${selectedTier.id}`}
                                    start={prevValues.rewards}
                                    end={selectedTier.author_rewards_pct || 0}
                                    duration={1}
                                    suffix="%"
                                    onEnd={() =>
                                      setPrevValues((prev) => ({
                                        ...prev,
                                        rewards:
                                          selectedTier.author_rewards_pct || 0,
                                      }))
                                    }
                                  />
                                </mark>{" "}
                                {t(
                                  "paywall.features.authorEarnings.earnPercentageSuffix"
                                )}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className={styles.feature}>
                          <div className={styles.featureIcon}>📱</div>
                          <div className={styles.featureInfo}>
                            <div className={styles.featureTitle}>
                              {t("paywall.features.increasedMessageLimits")}
                            </div>
                            <div className={styles.featureDescription}>
                              <p>
                                {t("paywall.features.messageDetailsPrefix")}{" "}
                                <mark>
                                  <CountUp
                                    key={`messages-${selectedPlan.id}`}
                                    start={prevValues.messages}
                                    end={selectedPlan.messaging_limit || 0}
                                    duration={1}
                                    separator=","
                                    onEnd={() =>
                                      setPrevValues((prev) => ({
                                        ...prev,
                                        messages:
                                          selectedPlan.messaging_limit || 0,
                                      }))
                                    }
                                  />
                                </mark>{" "}
                                {t("paywall.features.messageDetailsMid")}{" "}
                                <mark>
                                  <b>
                                    <CountUp
                                      key={`credits-${selectedPlan.id}`}
                                      start={prevValues.credits}
                                      end={selectedPlan.monthly_credits || 0}
                                      duration={1}
                                      separator=","
                                      onEnd={() =>
                                        setPrevValues((prev) => ({
                                          ...prev,
                                          credits:
                                            selectedPlan.monthly_credits || 0,
                                        }))
                                      }
                                    />
                                  </b>
                                </mark>{" "}
                                {t("paywall.features.messageDetailsSuffix")}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className={styles.feature}>
                          <div className={styles.featureIcon}>
                            {selectedTier.social_rewards_multiplier === 1
                              ? "1️⃣"
                              : selectedTier.social_rewards_multiplier === 2
                              ? "2️⃣"
                              : selectedTier.social_rewards_multiplier === 3
                              ? "3️⃣"
                              : selectedTier.social_rewards_multiplier === 4
                              ? "4️⃣"
                              : selectedTier.social_rewards_multiplier === 5
                              ? "5️⃣"
                              : selectedTier.social_rewards_multiplier === 6
                              ? "6️⃣"
                              : selectedTier.social_rewards_multiplier === 7
                              ? "7️⃣"
                              : ""}
                          </div>
                          <div className={styles.featureInfo}>
                            <div className={styles.featureTitle}>
                              {t("paywall.features.rewardMultiplier")}
                            </div>
                            <div className={styles.featureDescription}>
                              <p>
                                <mark>
                                  X
                                  <CountUp
                                    key={`multiplier-${selectedTier.id}`}
                                    start={prevValues.multiplier}
                                    end={
                                      selectedTier.social_rewards_multiplier ||
                                      1
                                    }
                                    duration={1}
                                    decimals={1}
                                    decimal="."
                                    onEnd={() =>
                                      setPrevValues((prev) => ({
                                        ...prev,
                                        multiplier:
                                          selectedTier.social_rewards_multiplier ||
                                          1,
                                      }))
                                    }
                                  />
                                </mark>{" "}
                                {t(
                                  "paywall.features.socialRewardsMultiplierText"
                                )}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className={styles.feature}>
                          <div className={styles.featureIcon}>🎨</div>
                          <div className={styles.featureInfo}>
                            <div className={styles.featureTitle}>
                              {t("paywall.features.imageGeneration")}
                            </div>
                            <div className={styles.featureDescription}>
                              <p>
                                {t("paywall.features.imageGenerationPrefix")}{" "}
                                <mark>
                                  <b>
                                    <CountUp
                                      key={`images-${selectedTier.id}`}
                                      start={prevValues.images}
                                      end={
                                        selectedPlan.image_generation_limit ||
                                        100
                                      }
                                      duration={1}
                                      separator=","
                                      onEnd={() =>
                                        setPrevValues((prev) => ({
                                          ...prev,
                                          images:
                                            selectedPlan.image_generation_limit ||
                                            100,
                                        }))
                                      }
                                    />
                                  </b>
                                </mark>{" "}
                                {t("paywall.features.imageGenerationSuffix")}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div
                          className={`${styles.feature} ${styles.comingSoonFeature}`}
                        >
                          <div className={styles.featureIcon}>🎭</div>
                          <div className={styles.featureInfo}>
                            <div className={styles.featureTitle}>
                              {t("paywall.features.characterCustomization")}
                              <span className={styles.comingSoonBadge}>
                                {t("paywall.features.comingSoon")}
                              </span>
                            </div>
                            <div className={styles.featureDescription}>
                              {t("paywall.features.customizeAppearance")}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* Add this new Upgrade Benefits section */}
                    {isUpgradeMode &&
                      upgradeDetails &&
                      !isCurrentPlan(selectedPlan, selectedTier) && (
                        <div className={styles.upgradeBenefits}>
                          <h3 className={styles.upgradeTitle}>
                            {t("paywall.upgrade.title")}
                          </h3>

                          <div className={styles.benefitCalculation}>
                            <div className={styles.calcRow}>
                              <div className={styles.calcLabel}>
                                {t("paywall.upgrade.newPlanPrice")}
                              </div>
                              <div className={styles.calcValue}>
                                {selectedPaymentMethod === "telegram_stars"
                                  ? `${selectedPlan.price} ⭐️`
                                  : `$${(
                                      selectedPlan.stripe_price / 1000
                                    ).toFixed(2)}`}
                              </div>
                            </div>

                            <div className={styles.calcRow}>
                              <div className={styles.calcLabel}>
                                <span>
                                  {t("paywall.upgrade.unusedValue")}
                                </span>
                                <div className={styles.calcDetail}>
                                  ({upgradeDetails.remainingDays} of{" "}
                                  {upgradeDetails.totalDays} days remaining
                                  -{" "}
                                  {Math.round(
                                    upgradeDetails.percentRemaining * 100
                                  )}
                                  %)
                                </div>
                              </div>
                              <div className={styles.calcValue}>
                                {selectedPaymentMethod === "telegram_stars"
                                  ? `-${upgradeDetails.unusedValue} ⭐️`
                                  : `-$${(
                                      upgradeDetails.unusedValue
                                    ).toFixed(2)}`}
                              </div>
                            </div>

                            <div className={styles.calcDivider}></div>

                            <div className={styles.calcRow}>
                              <div className={styles.calcLabel}>
                                <strong>
                                  {t("paywall.upgrade.finalPrice")}
                                </strong>
                              </div>
                              <div className={styles.calcValue}>
                                <strong>
                                  {selectedPaymentMethod === "telegram_stars"
                                    ? `${upgradeDetails.finalPrice} ⭐️`
                                    : `$${(
                                        upgradeDetails.finalPrice
                                      ).toFixed(2)}`}
                                </strong>
                              </div>
                            </div>

                            <div className={styles.benefitCards}>
                              <div className={styles.benefitCard}>
                                <div className={styles.benefitIcon}>📅</div>
                                <div className={styles.benefitInfo}>
                                  <div className={styles.benefitTitle}>
                                    {t("paywall.upgrade.newExpiryDate")}
                                  </div>
                                  <div className={styles.benefitValue}>
                                    {upgradeDetails.newExpiryDate.toLocaleDateString()}
                                  </div>
                                  <div className={styles.benefitCompare}>
                                    {t("paywall.upgrade.currentExpiry")}:{" "}
                                    {new Date(
                                      currentSubscription.expires_at
                                    ).toLocaleDateString()}
                                  </div>
                                </div>
                              </div>

                              <div className={styles.benefitCard}>
                                <div className={styles.benefitIcon}>💰</div>
                                <div className={styles.benefitInfo}>
                                  <div className={styles.benefitTitle}>
                                    {t("paywall.upgrade.additionalCredits")}
                                  </div>
                                  <div className={styles.benefitValue}>
                                    {upgradeDetails.additionalCredits.toLocaleString()}
                                  </div>
                                  <div className={styles.benefitDetail}>
                                    {t("paywall.upgrade.unusedCredits", {
                                      count: upgradeDetails.unusedCredits,
                                    })}
                                    :{" "}
                                    {upgradeDetails.unusedCredits.toLocaleString()}
                                  </div>
                                </div>
                              </div>

                              {upgradeDetails.monthlyCreditsChange > 0 && (
                                <div className={styles.benefitCard}>
                                  <div className={styles.benefitIcon}>💎</div>
                                  <div className={styles.benefitInfo}>
                                    <div className={styles.benefitTitle}>
                                      {t("paywall.upgrade.monthlyCredits")}
                                    </div>
                                    <div className={styles.benefitValue}>
                                      {selectedPlan.monthly_credits.toLocaleString()}
                                    </div>
                                    <div className={styles.benefitCompare}>
                                      {t("paywall.upgrade.creditsMonthly", {
                                        count:
                                          upgradeDetails.monthlyCreditsChange,
                                      })}
                                    </div>
                                  </div>
                                </div>
                              )}

                              {upgradeDetails.messagingLimitChange > 0 && (
                                <div className={styles.benefitCard}>
                                  <div className={styles.benefitIcon}>📝</div>
                                  <div className={styles.benefitInfo}>
                                    <div className={styles.benefitTitle}>
                                      {t("paywall.upgrade.messageLimit")}
                                    </div>
                                    <div className={styles.benefitValue}>
                                      {selectedPlan.messaging_limit.toLocaleString()}
                                    </div>
                                    <div className={styles.benefitCompare}>
                                      {t("paywall.upgrade.additionalMessages", {
                                        count:
                                          upgradeDetails.messagingLimitChange,
                                      })}
                                    </div>
                                  </div>
                                </div>
                              )}

                              {upgradeDetails.imageGenerationLimitChange >
                                0 && (
                                <div className={styles.benefitCard}>
                                  <div className={styles.benefitIcon}>🎨</div>
                                  <div className={styles.benefitInfo}>
                                    <div className={styles.benefitTitle}>
                                      {t("paywall.upgrade.imageGeneration")}
                                    </div>
                                    <div className={styles.benefitValue}>
                                      {selectedPlan.image_generation_limit.toLocaleString()}
                                    </div>
                                    <div className={styles.benefitCompare}>
                                      {t("paywall.upgrade.additionalImages", {
                                        count:
                                          upgradeDetails.imageGenerationLimitChange,
                                      })}
                                    </div>
                                  </div>
                                </div>
                              )}

                              {upgradeDetails.rewardsChange > 0 && (
                                <div className={styles.benefitCard}>
                                  <div className={styles.benefitIcon}>🌟</div>
                                  <div className={styles.benefitInfo}>
                                    <div className={styles.benefitTitle}>
                                      {t("paywall.upgrade.authorRewards")}
                                    </div>
                                    <div className={styles.benefitValue}>
                                      {selectedTier.author_rewards_pct}%
                                    </div>
                                    <div className={styles.benefitCompare}>
                                      {t("paywall.upgrade.higherCommission", {
                                        percent: upgradeDetails.rewardsChange,
                                      })}
                                    </div>
                                  </div>
                                </div>
                              )}

                              {upgradeDetails.multiplierChange > 0 && (
                                <div className={styles.benefitCard}>
                                  <div className={styles.benefitIcon}>✖️</div>
                                  <div className={styles.benefitInfo}>
                                    <div className={styles.benefitTitle}>
                                      {t("paywall.upgrade.rewardsMultiplier")}
                                    </div>
                                    <div className={styles.benefitValue}>
                                      x{selectedTier.social_rewards_multiplier}
                                    </div>
                                    <div className={styles.benefitCompare}>
                                      {t("paywall.upgrade.higherRewards", {
                                        multiplier:
                                          upgradeDetails.multiplierChange,
                                      })}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>,
    document.body
  );

  // Render instruction modal in a separate portal with the same tier color
  const instructionModal = createPortal(
    <div
      className={styles.instructionModal}
      onClick={toggleInstructions}
      style={{ "--tier-color": tierColor }}
    >
      <div
        className={styles.instructionContent}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className={styles.closeInstructionButton}
          onClick={toggleInstructions}
        >
          <IoClose />
        </button>

        <div className={styles.instructionTitle}>
          {t("paywall.instructions.title")}
        </div>

        <div className={styles.instructionText}>
          {t("paywall.instructions.description")}
        </div>

        <div className={styles.methodSection}>
          <h3>{t("paywall.instructions.method1")}</h3>

          <div className={styles.instructionStep}>
            <div className={styles.stepNumber}>1</div>
            <div className={styles.stepText}>
              {t("paywall.instructions.step1_1", {
                interpolation: { escapeValue: false },
                link: `<a href="https://fragment.com/stars/buy" target="_blank" rel="noopener noreferrer" style="color: ${tierColor}">fragment.com</a>`,
              })}
            </div>
          </div>

          <div className={styles.instructionStep}>
            <div className={styles.stepNumber}>2</div>
            <div className={styles.stepText}>
              {t("paywall.instructions.step1_2")}
            </div>
          </div>

          <div className={styles.instructionStep}>
            <div className={styles.stepNumber}>3</div>
            <div className={styles.stepText}>
              {t("paywall.instructions.step1_3")}
            </div>
          </div>

          <div className={styles.instructionStep}>
            <div className={styles.stepNumber}>4</div>
            <div className={styles.stepText}>
              {t("paywall.instructions.step1_4", {
                interpolation: { escapeValue: false },
                link: `<a href="https://t.me/wallet" target="_blank" rel="noopener noreferrer" style="color: ${tierColor}">@wallet</a>`,
              })}
            </div>
          </div>
        </div>

        <div className={styles.methodSection}>
          <h3>{t("paywall.instructions.method2")}</h3>

          <div className={styles.instructionStep}>
            <div className={styles.stepNumber}>1</div>
            <div className={styles.stepText}>
              {t("paywall.instructions.step2_1", {
                interpolation: { escapeValue: false },
                link: `<a href="https://t.me/PremiumBot" target="_blank" rel="noopener noreferrer" style="color: ${tierColor}">@PremiumBot</a>`,
              })}
            </div>
          </div>

          <div className={styles.instructionStep}>
            <div className={styles.stepNumber}>2</div>
            <div className={styles.stepText}>
              {t("paywall.instructions.step2_2")}
            </div>
          </div>

          <div className={styles.instructionStep}>
            <div className={styles.stepNumber}>3</div>
            <div className={styles.stepText}>
              {t("paywall.instructions.step2_3")}
            </div>
          </div>

          <div className={styles.instructionStep}>
            <div className={styles.stepNumber}>4</div>
            <div className={styles.stepText}>
              {t("paywall.instructions.step2_4")}
            </div>
          </div>
        </div>

        <div className={styles.instructionNote}>
          <strong>{t("paywall.instructions.note")}:</strong>{" "}
          {t("paywall.instructions.noteText")}
        </div>

        <div className={styles.buttonGroup}>
          <a
            href="https://fragment.com/stars/buy"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.ctaButton}
            onClick={(e) => {
              e.preventDefault();
              window.open("https://fragment.com/stars/buy", "_blank");
              toggleInstructions();
            }}
          >
            {t("paywall.instructions.visitFragment")}
          </a>

          <a
            href="https://t.me/PremiumBot"
            className={`${styles.ctaButton} ${styles.secondaryButton}`}
          >
            {t("paywall.instructions.openPremiumBot")}
          </a>
        </div>
      </div>
    </div>,
    document.body
  );

  return (
    <>
      {paywallContent}

      {showInstructions && instructionModal}
      {showCongratulation && congratulationModal}
    </>
  );
};
