import React from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from '@highlight-run/react';
import { H } from 'highlight.run';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { Background } from './components/Background';

import './index.css';

H.init(process.env.REACT_APP_HIGHLIGHT_PROJECT_ID, {
  serviceName: "tg-app",
  tracingOrigins: true,
  networkRecording: {
    enabled: true,
    recordHeadersAndBody: true,
    urlBlocklist: [
    "https://www.googleapis.com/identitytoolkit",
    "https://securetoken.googleapis.com",
    ],
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ErrorBoundary>
      <App />
      <Background />
    </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
