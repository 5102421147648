import React, { useEffect, useState, useMemo, useCallback, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { IoHomeOutline, IoChatbubbleOutline, IoAddCircleOutline, IoPersonOutline, IoCheckmarkCircleOutline } from 'react-icons/io5';
import { PiHandCoinsLight } from "react-icons/pi";

import { useApi } from '../../hooks/useApi';
import { Loader } from '../../components/Loader';
import { UserContext } from '../../components/UserProvider';
import { PayWall } from '../../components/PayWall/PayWall';

import styles from './Earn.module.css';

const tg = window.Telegram.WebApp;

// Daily rewards
export const REWARD_ADD_COMMENT = 30
export const REWARD_ADD_REACTION = 30
export const REWARD_FOLLOW_AUTHOR = 30

// One-time rewards
export const REWARD_ADD_POST_WITH_IMAGE = 300
export const REWARD_ADD_POST_WITH_CONVERSATION = 200
export const REWARD_ADD_POST = 100
export const REWARD_FOLLOW_CHARACTER = 150
export const REWARD_FIRST_TIME_FOLLOW_AUTHOR = 150

const getTierColor = (tier) => {
  const colors = {
    'basic': 'rgb(166, 103, 255)', // Professional blue - trustworthy, stable
    'premium': 'rgb(255, 215, 0)', // Classic gold - premium, luxury
    'diamond': 'rgb(81, 255, 101)', // Bright turquoise - exclusive, modern
  };
  return colors[tier.toLowerCase()] || 'rgb(255, 255, 255)';
}

const TransactionKinds = {
    BUY: 'Buy',
    CHAT: 'Chats',
    IMAGE_GENERATION: 'Image Generations',
    IMPORT_CHARACTER: 'Import Character',
    SUBSCRIPTION: 'Subscription',
    SUBSCRIPTION_RENEWAL: 'Subscription Renewal',
    REWARD: 'Rewards',
    AUTHOR_REWARD: 'Author Rewards',
}

const TransactionsList = () => {
    const { t } = useTranslation();

    const [pagination, setPagination] = useState({
      page: 1,
      limit: 10,
      kind: 'REWARD'
    });
  
    const apiOptions = useMemo(() => ({
      baseUrl: process.env.REACT_APP_API_URL,
      requestOptions: {
        headers: {
          'x-init-data': window.Telegram.WebApp.initData,
        }
      }
    }), []);
  
    const { user: { useTransactions } } = useApi(apiOptions);
    const { fetch: fetchTransactions, loading, data } = useTransactions();
  
    const fetchPage = useCallback(async (page = 1) => {
      try {
        await fetchTransactions({ 
          query: { 
            page,
            limit: pagination.limit,
            kind: pagination.kind
          } 
        });
      } catch (error) {
        console.error('Failed to fetch transactions:', error);
      }
    }, [fetchTransactions, pagination.limit]);
  
    useEffect(() => {
      fetchPage(pagination.page);
    }, [pagination.page]);
  
    const handlePageChange = (event, page) => {
      setPagination(prev => ({ ...prev, page }));
    };
  
    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleDateString({
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    };
  
    const getPageNumbers = useCallback((currentPage, totalPages) => {
      const delta = 1;
      const left = currentPage - delta;
      const right = currentPage + delta;
      const range = [];
      
      for (let i = 1; i <= totalPages; i++) {
        if (
          i === 1 ||
          i === totalPages ||
          (i >= left && i <= right)
        ) {
          range.push(i);
        }
      }
      
      const result = [];
      let prev = 0;
      
      for (const i of range) {
        if (prev + 1 < i) {
          result.push('...');
        }
        result.push(i);
        prev = i;
      }
      
      return result;
    }, []);
  
    const [isVisible, setIsVisible] = useState(false);
  
    if (loading) {
      return <div className="flex justify-center p-4">Loading...</div>;
    }
  
    const transactions = data?.transactions || [];
    const paginationData = data?.pagination;
  
    const toggleVisibility = () => {
      setIsVisible(prev => !prev);
    };

    return (
      <div className={styles.transactionsContainer}>
        <button onClick={toggleVisibility} className={styles.showTransactionsButton}>
        {isVisible ? t('profile.transactions.hideTransactions') : t('profile.transactions.showTransactions')}
        </button>
        
        {isVisible && (
          <>
            <div className={styles.tableWrapper}>
                <table className={styles.table}>
                <thead>
                    <tr>
                    <th>{t('profile.transactions.date')}</th>
                    <th>{t('profile.transactions.kind')}</th>
                    <th>{t('profile.transactions.amount')}</th>
                    </tr>
                </thead>
                <tbody>
                    {transactions.map((transaction) => (
                    <tr key={transaction.id}>
                        <td>{formatDate(transaction.created_at)}</td>
                        <td>{t(`profile.transactions.types.${transaction.kind.replace(/ /g, '_')}`)}</td>
                        <td className={transaction.amount < 0 ? styles.red : styles.green}>{transaction.amount}</td>
                    </tr>
                    ))}
                </tbody>
                </table>
            </div>
  
            {paginationData?.pages > 1 && (
              <div className={styles.pagination}>
                <button 
                  className={styles.paginationButton}
                  disabled={paginationData.page === 1}
                  onClick={() => handlePageChange(null, paginationData.page - 1)}
                >
                  {t('profile.transactions.pagination.previous')}
                </button>
                
                <div className={styles.paginationPages}>
                  {getPageNumbers(paginationData.page, paginationData.pages).map((pageNum, idx) => (
                    pageNum === '...' ? (
                      <span key={`dots-${idx}`} className={styles.paginationDots}>...</span>
                    ) : (
                      <button
                        key={pageNum}
                        onClick={() => handlePageChange(null, pageNum)}
                        className={`${styles.paginationPage} ${pageNum === paginationData.page ? styles.active : ''}`}
                      >
                        {pageNum}
                      </button>
                    )
                  ))}
                </div>
  
                <button
                  className={styles.paginationButton}
                  disabled={paginationData.page === paginationData.pages}
                  onClick={() => handlePageChange(null, paginationData.page + 1)}
                >
                  {t('profile.transactions.pagination.next')}
                </button>
              </div>
            )}
          </>
        )}
      </div>
    );
};

const LeaderboardList = () => {
    const apiOptions = useMemo(() => ({
        baseUrl: process.env.REACT_APP_API_URL,
        requestOptions: {
            headers: {
                'x-init-data': window.Telegram.WebApp.initData,
            }
        }
    }), []);

    const { user: { useLeaderboard } } = useApi(apiOptions);
    const { fetch: fetchLeaderboard, loading, data } = useLeaderboard();

    useEffect(() => {
        fetchLeaderboard();
    }, []);

    const users = useMemo(() => data?.leaderboard || [], [data]);

    if (loading) {
        return <div className="flex justify-center p-4">Loading...</div>;
    }

    return (
        <div className={styles.leaderboardContainer}>
            <div className={styles.tableWrapper}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>User</th>
                            <th>Earned</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user, index) => (
                            <tr key={user.id} className={user.is_me ? styles.currentUser : ''}>
                                <td>{index + 1}</td>
                                <td style={{ color: getTierColor(user.tier) }}>{user.nickname || 'Anonymous'}</td>
                                <td>{user.earned}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export function Earn() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const { user } = useContext(UserContext);
    const [loading, setLoading] = useState(false); // true
    const [activeTab, setActiveTab] = useState('earn'); // Add this state for tab management

    const paymentStatus = searchParams.get('payment');
    const [isPayWallOpen, setIsPayWallOpen] = useState(['success', 'canceled'].includes(paymentStatus));

    // Determine subscription tier and multiplier
    const subscriptionInfo = useMemo(() => {
        if (!user?.subscriptions || user.subscriptions.length === 0) {
            return { tier: 'basic', multiplier: 1, nextTier: 'premium', nextMultiplier: 2 };
        }
        
        const currentTier = user.subscriptions[0].tier.name.toLowerCase();
        const currentMultiplier = user.subscriptions[0].tier.social_rewards_multiplier || 1;
        
        if (currentTier === 'premium') {
            return { tier: 'premium', multiplier: currentMultiplier, nextTier: 'diamond', nextMultiplier: 5 };
        } else if (currentTier === 'diamond') {
            return { tier: 'diamond', multiplier: currentMultiplier, nextTier: null, nextMultiplier: null };
        }
        
        return { tier: 'basic', multiplier: 1, nextTier: 'premium', nextMultiplier: 2 };
    }, [user]);
    
    // Use the multiplier from subscription info instead
    const rewardsMultiplier = subscriptionInfo.multiplier;

    const apiOptions = useMemo(() => ({
        baseUrl: process.env.REACT_APP_API_URL,
        requestOptions: {
            headers: {
                'x-init-data': tg.initData,
            }
        }
    }), [])

    const { user: { useActivities } } = useApi(apiOptions)
    const { fetch: fetchActivities, data: activities } = useActivities();

    useEffect(() => {
        tg.expand()
        tg.BackButton.show()
        tg.BackButton.onClick(() => navigate(`/`))
        tg.disableVerticalSwipes()

    }, [navigate])

    useEffect(() => {
        if (!user) return;
        fetchActivities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const onHomeClick = useCallback(() => navigate(`/`), [navigate])
    const onAddCharacterClick = useCallback(() => navigate(`/character/create`), [navigate])
    const onProfileClick = useCallback(() => navigate(`/profile`), [navigate])
    const onChatsClick = useCallback(() => navigate(`/chats`), [navigate])

    const onEarnClick = useCallback(() => {
      navigate('/earn')
    }, [navigate])

    // Open PayWall modal function
    const openPayWall = useCallback(() => {
        setIsPayWallOpen(true);
    }, []);

    // Close PayWall modal function
    const closePayWall = useCallback(() => {
        setIsPayWallOpen(false);
    }, []);

    // Updated to open PayWall instead of direct navigation
    const onSubscribeClick = useCallback(() => {
        openPayWall();
    }, [openPayWall]);

    // Helper function to calculate rewards with multiplier
    const calculateReward = useCallback((baseReward) => {
        return Math.round(baseReward * rewardsMultiplier);
    }, [rewardsMultiplier]);

    if (loading) {
        return <Loader />;
    }

    return (
        <div className={styles.earn + ' ' + styles[tg.platform] + ' '}>
            <PayWall
                isOpen={isPayWallOpen}
                onClose={closePayWall}
                paymentStatus={paymentStatus}
                message={subscriptionInfo.tier === 'premium' 
                    ? t('earn.paywall.diamondUpgrade') 
                    : t('earn.paywall.premiumUpgrade')}
            />
            <div className={styles.container}>
                <div className={styles.tabs}>
                    <button 
                        className={`${styles.tab} ${activeTab === 'earn' ? styles.active : ''}`}
                        onClick={() => setActiveTab('earn')}
                    >
                        {t('earn.tabs.earn')}
                    </button>
                    <button 
                        className={`${styles.tab} ${activeTab === 'leaderboard' ? styles.active : ''}`}
                        onClick={() => setActiveTab('leaderboard')}
                    >
                        {t('earn.tabs.leaderboard')}
                    </button>
                    {/* <button 
                        className={`${styles.tab} ${activeTab === 'faq' ? styles.active : ''}`}
                        onClick={() => setActiveTab('faq')}
                    >
                        {t('earn.tabs.faq')}
                    </button> */}
                </div>

                {activeTab === 'earn' && (
                    <>
                        <header>
                            <h1>{t('earn.header.title')}</h1>
                            <p>{t('earn.header.description')}</p>

                            {subscriptionInfo.tier === 'basic' && (
                                <div className={styles.subscribeContainer}>
                                    <p>Get <mark>x2</mark> credits with Premium subscription!</p>
                                    <button className={styles.subscribeButton} onClick={onSubscribeClick}>
                                        Get Premium
                                    </button>
                                </div>
                            )}
                            
                            {subscriptionInfo.tier === 'premium' && (
                                <div className={styles.multiplierContainer}>
                                    <div className={styles.multiplierBadge}>
                                        <span>🌟 {rewardsMultiplier} Premium Boost! 🌟</span>
                                    </div>
                                    <div className={styles.subscribeContainer}>
                                        <p>Upgrade to Diamond and get <mark>x5</mark> credits!</p>
                                        <button className={styles.subscribeButton} onClick={onSubscribeClick}>
                                            Upgrade to Diamond
                                        </button>
                                    </div>
                                </div>
                            )}
                            
                            {subscriptionInfo.tier === 'diamond' && (
                                <div className={styles.multiplierBadge}>
                                    <span>💎 {rewardsMultiplier} Diamond Boost! 💎</span>
                                </div>
                            )}
                        </header>
                        <div className={styles.content}>
                            <div className={styles.earned}>
                                <h2>{t('earn.stats.totalEarned')}</h2>
                                <p>{activities?.activities?.earned || 0} {t('earn.stats.credits')}</p>
                                <h2>{t('earn.stats.earnedToday')}</h2>
                                <p>{activities?.activities?.daily_earned || 0} {t('earn.stats.credits')}</p>
                            </div>
                            <div className={styles.field + ' ' + styles.transactions}>
                                <div className={styles.fieldLabel}>
                                  {t('earn.stats.transactionHistory')}
                                </div>
                                <TransactionsList />
                            </div>

                            <h2>{t('earn.stats.dailyActivities')}</h2>
                            <div className={styles.activity}>
                                <div className={styles.activityItem}>
                                    <h3>{t('earn.activities.addComments.title')}</h3>
                                    <p className={styles.activityItemCredits}>
                                        {REWARD_ADD_COMMENT} x {rewardsMultiplier} = {t('earn.activities.addComments.creditsPerComment', { amount: calculateReward(REWARD_ADD_COMMENT) })}
                                        <span>{t('earn.activities.addComments.maxCredits', { amount: calculateReward(REWARD_ADD_COMMENT) * 5 })}</span>
                                    </p>
                                    <div className={styles.spoiler}>
                                        <span>{t('earn.activities.addComments.help')} <a href="https://telegra.ph/How-to-Add-a-Comment-to-a-Post-02-24" target="_blank" rel="noopener noreferrer">{t('earn.activities.addComments.learnMore')}</a></span>
                                    </div>
                                </div>
                                <div className={styles.activityStatus + ' ' + (activities?.activities?.daily?.add_comment?.status === 'completed' ? styles.completed : '')}>
                                    <IoCheckmarkCircleOutline />
                                    <p>{activities?.activities?.daily?.add_comment?.count}/5</p>
                                </div>
                            </div>
                            <div className={styles.activity}>
                                <div className={styles.activityItem}>
                                    <h3>{t('earn.activities.addReactions.title')}</h3>
                                    <p className={styles.activityItemCredits}>
                                        {REWARD_ADD_REACTION} x {rewardsMultiplier} = {t('earn.activities.addReactions.creditsPerReaction', { amount: calculateReward(REWARD_ADD_REACTION) })}
                                        <span>{t('earn.activities.addReactions.maxCredits', { amount: calculateReward(REWARD_ADD_REACTION) * 5 })}</span>
                                    </p>
                                    <div className={styles.spoiler}>
                                        <span>{t('earn.activities.addReactions.help')} <a href="https://telegra.ph/How-to-Add-a-Reaction-to-a-Post-02-24" target="_blank" rel="noopener noreferrer">{t('earn.activities.addReactions.learnMore')}</a></span>
                                    </div>
                                </div>
                                <div className={styles.activityStatus + ' ' + (activities?.activities?.daily?.add_reaction?.status === 'completed' ? styles.completed : '')}>
                                    <IoCheckmarkCircleOutline />
                                    <p>{activities?.activities?.daily?.add_reaction?.count}/5</p>
                                </div>
                            </div>
                            <div className={styles.activity}>
                                <div className={styles.activityItem}>
                                    <h3>{t('earn.activities.followAuthor.title')}</h3>
                                    <p className={styles.activityItemCredits}>
                                        {REWARD_FOLLOW_AUTHOR} x {rewardsMultiplier} = {t('earn.activities.followAuthor.creditsPerAuthor', { amount: calculateReward(REWARD_FOLLOW_AUTHOR) })}
                                        <span>{t('earn.activities.followAuthor.maxCredits', { amount: calculateReward(REWARD_FOLLOW_AUTHOR) * 5 })}</span>
                                    </p>
                                    <div className={styles.spoiler}>
                                        <span>{t('earn.activities.followAuthor.help')} <a href="https://telegra.ph/How-to-Follow-an-Author-02-24" target="_blank" rel="noopener noreferrer">{t('earn.activities.followAuthor.learnMore')}</a></span>
                                    </div>
                                </div>
                                <div className={styles.activityStatus + ' ' + (activities?.activities?.daily?.follow_author?.status === 'completed' ? styles.completed : '')}>
                                    <IoCheckmarkCircleOutline />
                                    <p>{activities?.activities?.daily?.follow_author?.count}/5</p>
                                </div>
                            </div>
                            <h2>{t('earn.stats.oneTimeActivities')}</h2>
                            <div className={styles.activity}>
                                <div className={styles.activityItem}>
                                    <h3>{t('earn.activities.createPostWithImage.title')}</h3>
                                    <p className={styles.activityItemCredits}>
                                        {REWARD_ADD_POST_WITH_IMAGE} x {rewardsMultiplier} = {t('earn.activities.createPostWithImage.credits', { amount: calculateReward(REWARD_ADD_POST_WITH_IMAGE) })}
                                    </p>
                                    <div className={styles.spoiler}>
                                        <span>{t('earn.activities.createPostWithImage.help')} <a href="https://telegra.ph/How-to-Create-a-Post-with-an-Image-Generated-by-a-Character-02-24" target="_blank" rel="noopener noreferrer">{t('earn.activities.createPostWithImage.learnMore')}</a></span>
                                    </div>
                                </div>
                                <div className={styles.activityStatus + ' ' + (activities?.activities?.one_time?.create_post_with_image?.status === 'completed' ? styles.completed : '')}>
                                    <IoCheckmarkCircleOutline />
                                </div>
                            </div>
                            <div className={styles.activity}>
                                <div className={styles.activityItem}>
                                    <h3>{t('earn.activities.createPostWithConversation.title')}</h3>
                                    <p className={styles.activityItemCredits}>
                                        {REWARD_ADD_POST_WITH_CONVERSATION} x {rewardsMultiplier} = {t('earn.activities.createPostWithConversation.credits', { amount: calculateReward(REWARD_ADD_POST_WITH_CONVERSATION) })}
                                    </p>
                                    <div className={styles.spoiler}>
                                        <span>{t('earn.activities.createPostWithConversation.help')} <a href="https://telegra.ph/How-to-Create-a-Post-with-Your-Conversation-with-a-Character-02-24" target="_blank" rel="noopener noreferrer">{t('earn.activities.createPostWithConversation.learnMore')}</a></span>
                                    </div>
                                </div>
                                <div className={styles.activityStatus + ' ' + (activities?.activities?.one_time?.create_post_with_conversation?.status === 'completed' ? styles.completed : '')}>
                                    <IoCheckmarkCircleOutline />
                                </div>
                            </div>
                            <div className={styles.activity}>
                                <div className={styles.activityItem}>
                                    <h3>{t('earn.activities.createPostWithCharacter.title')}</h3>
                                    <p className={styles.activityItemCredits}>
                                        {REWARD_ADD_POST} x {rewardsMultiplier} = {t('earn.activities.createPostWithCharacter.credits', { amount: calculateReward(REWARD_ADD_POST) })}
                                    </p>
                                    <div className={styles.spoiler}>
                                        <span>{t('earn.activities.createPostWithCharacter.help')} <a href="https://telegra.ph/How-to-Create-a-Post-About-a-Character-02-24" target="_blank" rel="noopener noreferrer">{t('earn.activities.createPostWithCharacter.learnMore')}</a></span>
                                    </div>
                                </div>
                                <div className={styles.activityStatus + ' ' + (activities?.activities?.one_time?.create_post_with_character?.status === 'completed' ? styles.completed : '')}>
                                    <IoCheckmarkCircleOutline />
                                </div>
                            </div>
                            <div className={styles.activity}>
                                <div className={styles.activityItem}>
                                    <h3>{t('earn.activities.followCharacter.title')}</h3>
                                    <p className={styles.activityItemCredits}>
                                        {REWARD_FOLLOW_CHARACTER} x {rewardsMultiplier} = {t('earn.activities.followCharacter.credits', { amount: calculateReward(REWARD_FOLLOW_CHARACTER) })}
                                    </p>
                                    <div className={styles.spoiler}>
                                        <span>{t('earn.activities.followCharacter.help')} <a href="https://telegra.ph/How-to-Follow-Your-Favorite-Character-02-24" target="_blank" rel="noopener noreferrer">{t('earn.activities.followCharacter.learnMore')}</a></span>
                                    </div>
                                </div>
                                <div className={styles.activityStatus + ' ' + (activities?.activities?.one_time?.follow_character?.status === 'completed' ? styles.completed : '')}>
                                    <IoCheckmarkCircleOutline />
                                </div>
                            </div>
                            <div className={styles.activity}>
                                <div className={styles.activityItem}>
                                    <h3>{t('earn.activities.followFavoriteAuthor.title')}</h3>
                                    <p className={styles.activityItemCredits}>
                                        {REWARD_FIRST_TIME_FOLLOW_AUTHOR} x {rewardsMultiplier} = {t('earn.activities.followFavoriteAuthor.credits', { amount: calculateReward(REWARD_FIRST_TIME_FOLLOW_AUTHOR) })}
                                    </p>
                                    <div className={styles.spoiler}>
                                        <span>{t('earn.activities.followFavoriteAuthor.help')} <a href="https://telegra.ph/How-to-Follow-an-Author-02-24" target="_blank" rel="noopener noreferrer">{t('earn.activities.followFavoriteAuthor.learnMore')}</a></span>
                                    </div>
                                </div>
                                <div className={styles.activityStatus + ' ' + (activities?.activities?.one_time?.follow_author?.status === 'completed' ? styles.completed : '')}>
                                    <IoCheckmarkCircleOutline />
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {activeTab === 'leaderboard' && (
                    <div className={styles.content}>
                        <header>
                            <h1>{t('earn.leaderboard.title')}</h1>
                            <p>{t('earn.leaderboard.description')}</p>
                        </header>
                        <LeaderboardList />
                    </div>
                )}

                {activeTab === 'faq' && (
                    <div className={styles.content}>
                        <header>
                            <h1>{t('earn.faq.title')}</h1>
                            <p>{t('earn.faq.description')}</p>
                        </header>
                        <div className={styles.comingSoon}>
                            {t('earn.faq.comingSoon')}
                        </div>
                    </div>
                )}

                <footer className={styles.footer}>
                    <div className={styles.footerTabs}>
                        <button onClick={onHomeClick}><IoHomeOutline /></button>
                        <button onClick={onChatsClick}><IoChatbubbleOutline /></button>
                        <button className={styles.earnButton} onClick={onEarnClick}>
                            <PiHandCoinsLight />
                            <label>earn</label>
                        </button>
                        <button onClick={onAddCharacterClick}><IoAddCircleOutline /></button>
                        <button onClick={onProfileClick}><IoPersonOutline /></button>
                    </div>
                </footer>
            </div>
        </div>
    );
}
