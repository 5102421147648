import React, { useEffect, useCallback } from 'react';
import { IoShareOutline, IoClose } from 'react-icons/io5';
import { createPortal } from 'react-dom';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useTranslation } from "react-i18next";
import styles from '../Chat.module.css';

export const ImageLightbox = ({ src, onClose, onShare }) => {
    const { t } = useTranslation();
    
    const handleShare = useCallback((e) => {
        e.stopPropagation()
        onShare()
    }, [onShare])

    useEffect(() => {
        const handleEsc = (e) => {
            if (e.key === 'Escape') {
                onClose();
            }
        };
        document.addEventListener('keydown', handleEsc);
        return () => document.removeEventListener('keydown', handleEsc);
    }, [onClose]);

    return createPortal(
        <div className={styles.lightbox} onClick={onClose}>
            <div className={styles.lightboxControls}>
                <button 
                    className={styles.lightboxButton} 
                    onClick={handleShare}
                    aria-label={t('chat.contextMenu.share')}
                >
                    <IoShareOutline size={24} color="#fff" />
                </button>
                <button 
                    className={styles.lightboxButton} 
                    onClick={onClose}
                    aria-label={t('general.close')}
                >
                    <IoClose size={24} color="#fff" />
                </button>
            </div>
            <div className={styles.lightboxContent} onClick={e => e.stopPropagation()}>
                <TransformWrapper
                    initialScale={1}
                    minScale={0.5}
                    maxScale={4}
                    centerOnInit={true}
                    doubleClick={{ disabled: true }}
                    panning={{ disabled: false }}
                    pinch={{ disabled: false }}
                    limitToBounds={false}
                    wheel={{ disabled: true }}
                >
                    <TransformComponent
                        wrapperStyle={{
                            width: "100%",
                            height: "100%"
                        }}
                    >
                        <img src={src} alt={t('chat.imageGeneration.generatedImage')} />
                    </TransformComponent>
                </TransformWrapper>
            </div>
        </div>,
        document.body
    );
}; 