import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import {
  IoInformationCircleOutline,
  IoHeart,
  IoHeartOutline,
  IoChatbubbleOutline,
  IoBookmarkOutline,
  IoBookmark,
  IoShareOutline,
} from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useApi } from "../../hooks/useApi";
import { formatNumber } from "../../utils/formatNumber";
import { MessageView } from "../../pages/Chat/components/MessageView";
import { Comments } from '../Comments/Comments';

import styles from "./Post.module.css";

const REACTIONS = [
  { kind: "LIKE", emoji: "👍" },
  { kind: "HEART", emoji: "❤️" },
  { kind: "LAUGH", emoji: "😂" },
  { kind: "PEACH", emoji: "🍑" },
  { kind: "CRY", emoji: "😢" },
  { kind: "ANGRY", emoji: "😡" },
  { kind: "SKULL", emoji: "💀" },
];

const tg = window.Telegram.WebApp;

export function Post({ post: initialPost, character }) {
  const { t } = useTranslation();

  const apiOptions = useMemo(
    () => ({
      baseUrl: process.env.REACT_APP_API_URL,
      requestOptions: {
        headers: {
          "x-init-data": tg.initData,
        },
      },
    }),
    []
  );

  const {
    post: { useReact, useFavorite, useComment, useSharePost },
    // user: { useFollow, useUnfollow },
  } = useApi(apiOptions);

  const [post, setPost] = useState(initialPost);

  const { fetch: commentPost } = useComment(post.id);
  const { fetch: reactToPost } = useReact(post.id);
  const { fetch: favoritePost } = useFavorite(post.id);
  const { fetch: sharePost } = useSharePost(post.id);

  const [isBlurred, setIsBlurred] = useState(
    ["R", "X", "XXX"].includes(post.rating)
  );

  const [showAllComments, setShowAllComments] = useState(false);
  const [showReactions, setShowReactions] = useState(false);
  const reactionsRef = useRef(null);
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);

  const reactionsListRef = useRef(null);

  const [height, setHeight] = useState(0);
  const containerRef = useRef(null);

  const handleImageLoad = useCallback((e) => {
    setHeight(e.target.getBoundingClientRect().height);
  }, []);

  useEffect(() => {
    if (!containerRef.current) return;

    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        const imgElement = entry.target.querySelector('img');
        if (imgElement) {
          setHeight(imgElement.getBoundingClientRect().height);
        }
      }
    });

    resizeObserver.observe(containerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const handleClickOutside = useCallback((event) => {
    if (
      reactionsRef.current &&
      !reactionsRef.current.contains(event.target)
    ) {
      setShowReactions(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleCharacterClick = useCallback(() => {
    navigate(`/character/${character.id}`);
  }, [character.id]);

  const handleReactionClick = useCallback(
    (kind) => (e) => {
      e.stopPropagation();

      setPost((prev) => ({
        ...prev,
        reactions: {
          ...prev.reactions,
          [kind]:
            (prev.reactions[kind] || 0) + (prev.myReactions[kind] ? -1 : 1),
        },
        myReactions: {
          ...prev.myReactions,
          [kind]:
            (prev.myReactions[kind] || 0) + (prev.myReactions[kind] ? -1 : 1),
        },
        _count: {
          ...prev._count,
          reactions:
            (prev._count.reactions || 0) + (prev.myReactions[kind] ? -1 : 1),
        },
      }));

      reactToPost({
        body: JSON.stringify({
          kind,
        }),
      });
    },
    [post.id]
  );

  const handleReactionsListClick = useCallback(
    (e) => {
      e.stopPropagation();
      setShowReactions(!showReactions);
    },
    [showReactions]
  );

  const handlePostClick = useCallback((e) => {
    e.stopPropagation();
    setIsBlurred(false);
    setIsExpanded(true);
  }, []);

  const handleNsfwWarningClick = useCallback((e) => {
    e.stopPropagation();
    setIsBlurred(false);
  }, []);

  const handleFavoriteClick = useCallback((e) => {
    e.stopPropagation();
    setPost((prev) => ({
      ...prev,
      isFavorite: !prev.isFavorite,
    }));
    favoritePost();
  }, []);

  const handleSharePress = useCallback(async (e) => {
    e.stopPropagation();
    const { prepared_message } = await sharePost();
    await tg.shareMessage(prepared_message.id);
  }, []);

  const hasReactions = useMemo(() => {
    return Object.keys(post.myReactions).length > 0;
  }, [post.myReactions]);

  const handleAddComment = useCallback(async (text, parentId = null) => {
    const { comment } =await commentPost({
      body: JSON.stringify({
        content: text,
        comment_id: parentId
      })
    });
    
    setPost(prev => ({
      ...prev,
      comments: [comment, ...prev.comments],
      _count: {
        ...prev._count,
        comments: prev._count.comments + 1
      }
    }));
  }, [commentPost]);

  const handleShowComments = useCallback((e) => {
    e.stopPropagation();
    setShowAllComments(true);
  }, []);

  const handleAuthorClick = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    navigate(`/profile/${post.author.nickname}`);
  }, [post.author.nickname]);

  return (
    <div
      className={`${styles.post} ${post.messages && post.messages.length && styles.hasMessages} ${isExpanded ? styles.expanded : ""}`}
      style={{
        "--background-image": `url(${post.preview_url || character.avatar_url})`,
      }}
      ref={containerRef}
    >
      <div className={styles.authorInfo}>
        <span className={styles.authorName}>
            <a href={`/profile/${post.author.nickname}`} onClick={handleAuthorClick}>@{post.author.nickname}</a>
        </span>
        <button className={styles.shareButton} onClick={handleSharePress}>
            <IoShareOutline />
        </button>
      </div>
      <div
        className={`${styles.postHeader}`}
        style={{ height: height ? `${height}px` : 'auto' }}
        onClick={handlePostClick}
      >
        {isBlurred && (
          <div className={styles.nsfwWarning} onClick={handleNsfwWarningClick}>
            <div className={styles.nsfwWarningText}>
              {t('post.ratingWarning', { rating: post.rating })}
            </div>
          </div>
        )}
        {post.messages && post.messages.length > 0 ? (
          <div
            className={styles.messagesContainer}
          >
            {post.messages.map((message, index) => (
              <MessageView
                key={index}
                message={message}
                character={character}
              />
            ))}
          </div>
        ) : (
          <div className={styles.imageContainer} style={{ height: height ? `${height}px` : 'auto' }}>
            <img
              className={styles.previewImage}
              alt={character.name}
              src={post.preview_url || character.avatar_url}
              onLoad={handleImageLoad}
            />
          </div>
        )}

        <div className={styles.characterInfo}>
          <div className={styles.characterInfoHeader}>
            <div
              className={styles.characterNameContainer}
              onClick={handleCharacterClick}
            >
              <span className={styles.characterName}>{character.name}</span>
              <IoInformationCircleOutline className={styles.infoIcon} />
            </div>

            <div className={styles.postActions}>
                <button
                    className={styles.favoriteButton}
                    onClick={handleFavoriteClick}
                >
                    {post.isFavorite ? <IoBookmark /> : <IoBookmarkOutline />}
                </button>

                <button className={styles.addCommentButton} onClick={handleShowComments}>
                    <IoChatbubbleOutline />
                    <span className={styles.reactionCount}>
                        {formatNumber(post._count.comments)}
                    </span>
                </button>

                <button
                    className={`${styles.reactionButton} ${
                    hasReactions ? styles.active : ""
                    }`}
                    onClick={handleReactionsListClick}
                >
                    <span className={styles.reactionsListSwitch}>
                        {hasReactions ? <IoHeart /> : <IoHeartOutline />}
                        <span className={styles.reactionCount}>
                            {formatNumber(post._count.reactions)}
                        </span>
                    </span>
                </button>
            </div>
          </div>

          {(!!post._count.reactions || showReactions) && (
            <div
              className={`${styles.reactionsList} ${
                showReactions ? styles.show : ""
              }`}
              ref={reactionsListRef}
            >
              {REACTIONS.filter(
                (reaction) =>
                  showReactions || post.reactions?.[reaction.kind] > 0
              ).map(({ kind, emoji }) => (
                <button
                  key={kind}
                  className={`${styles.reactionOption} ${
                    post.myReactions[kind] ? styles.active : ""
                  }`}
                  onClick={handleReactionClick(kind)}
                >
                  <span className={styles.reactionEmoji}>{emoji}</span>
                  <span className={styles.reactionCount}>
                    {formatNumber(post.reactions?.[kind])}
                  </span>
                </button>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className={styles.postContent}>
        <p>
          <b>@{post.author.nickname}</b> {post.content}
        </p>
        <div className={styles.postDate}>
          {new Date(post.created_at).toLocaleDateString()}
        </div>
      </div>

      <Comments
        post={post}
        onAddComment={handleAddComment}
        showAllComments={showAllComments}
        onShowComments={setShowAllComments}
      />
    </div>
  );
}
