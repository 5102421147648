import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Comments.module.css";

const MAX_COMMENT_LENGTH = 280;

export function CommentText({ text, maxLength = MAX_COMMENT_LENGTH, onReply }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const shouldTruncate = text.length > maxLength;
  const { t } = useTranslation();

  if (!shouldTruncate) {
    return (
      <div className={styles.commentTextContainer}>
        <div className={styles.commentTextWrapper}>
          <span className={styles.commentText}>{text}</span>
        </div>
        {onReply && (
          <button className={styles.replyButton} onClick={onReply}>
            {t('comments.reply')}
          </button>
        )}
      </div>
    );
  }

  return (
    <div className={styles.commentTextContainer}>
      <div className={styles.commentTextWrapper}>
        <span className={styles.commentText}>
          {isExpanded ? text : `${text.slice(0, maxLength)}...`}
          <button
            className={styles.expandButton}
            onClick={(e) => {
              e.stopPropagation();
              setIsExpanded(!isExpanded);
            }}
          >
            {isExpanded ? "less" : "more"}
          </button>
        </span>
      </div>
      {onReply && (
        <button className={styles.replyButton} onClick={onReply}>
          {t('comments.reply')}
        </button>
      )}
    </div>
  );
}
