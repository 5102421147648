import { useCallback } from 'react';
import { Name, Bio, Greeting, Tags, Description, Scenario, SystemPrompt, Gender, Appearance } from './inputs';
import { useGenerateContext } from '../../hooks/useGenerate';

export function Form({ character, formErrors, setCharacter, onChange }) {
    const { handleGenerate } = useGenerateContext()

    const handleDelete = useCallback((index) => {
        setCharacter({
            ...character,
            tags: character.tags.filter((_, i) => i !== index),
        })
    }, [character, setCharacter])

    const onTagUpdate = useCallback((index, newTag) => {
        const updatedTags = [...character.tags];
        updatedTags.splice(index, 1, newTag);
        setCharacter({
            ...character,
            tags: updatedTags,
        })
    }, [character, setCharacter])

    const handleAddition = useCallback((tag) => {
        setCharacter((prevCharacter) => {
            return {
                ...prevCharacter,
                tags: [...prevCharacter.tags, { id: tag.id, name: tag.name.toLowerCase() }],
            }
        })
    }, [setCharacter])

    const handleDrag = useCallback((tag, currPos, newPos) => {
        const newTags = character.tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        setCharacter((prevCharacter) => {
            return {
                ...prevCharacter,
                tags: newTags,
            }
        })
    }, [character, setCharacter])

    const onClearAll = useCallback(() => {
        setCharacter({
            ...character,
            tags: [],
        })
    }, [character, setCharacter])

    return (
        <>
            <Name value={character.name} onChange={onChange('name')} error={formErrors.name} />

            <Gender value={character.gender} onChange={onChange('gender')} error={formErrors.gender} />

            <Appearance value={character.appearance} onChange={onChange('appearance')} error={formErrors.appearance} generate={handleGenerate} />

            <Bio value={character.bio} onChange={onChange('bio')} error={formErrors.bio} generate={handleGenerate} />
            <Greeting value={character.first_message} onChange={onChange('first_message')} error={formErrors.first_message} generate={handleGenerate} />

            <Description value={character.description} onChange={onChange('description')} error={formErrors.description} generate={handleGenerate} />

            <Tags
                tags={character.tags}
                handleDelete={handleDelete}
                handleAddition={handleAddition}
                handleDrag={handleDrag}
                onTagUpdate={onTagUpdate}
                onClearAll={onClearAll}
                error={formErrors.tags}
                generate={handleGenerate}
            />

            <Scenario value={character.scenario} onChange={onChange('scenario')} error={formErrors.scenario} generate={handleGenerate} />
            {/* <SystemPrompt value={character.system_prompt} onChange={onChange('system_prompt')} error={formErrors.system_prompt} generate={handleGenerate} /> */}
        </>
    );
}