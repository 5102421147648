import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
  useContext,
} from "react";
import { IoSearchSharp, IoArrowDown, IoCloseOutline } from "react-icons/io5";
import { FaFilter } from "react-icons/fa6";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  IoHeartOutline,
  IoBookmarkOutline,
  IoChatbubbleOutline,
  IoPersonOutline,
  IoMaleOutline,
  IoFemaleOutline,
} from "react-icons/io5";
import { PiGenderNonbinary } from "react-icons/pi";
import { useTranslation } from "react-i18next";

import styles from "./../Home.module.css";

import { Loader } from "../../../components/Loader";
import { NotFound } from "../../../components/NotFound";
import { useApi } from "../../../hooks/useApi";
import { CharacterPreview } from "../../../components/CharacterPreview/CharacterPreview";
import { UserContext } from "../../../components/UserProvider";

const tg = window.Telegram.WebApp;

const highlightText = (text, query) => {
  if (!query) return text;
  const parts = text.split(new RegExp(`(${query})`, "gi"));
  return parts.map((part, index) =>
    part.toLowerCase() === query.toLowerCase() ? (
      <span key={index} className={styles.highlight}>
        {part}
      </span>
    ) : (
      part
    )
  );
};

const ratings = {
  PG: ["PG"],
  PG_13: ["PG", "PG_13"],
  R: ["PG", "PG_13", "R"],
  X: ["PG", "PG_13", "R", "X"],
  XXX: ["PG", "PG_13", "R", "X", "XXX"],
};

export function Characters({ 
  setLoadMoreHooks, 
  searchPanelRef, 
  browsingLevelRef, 
  charactersGridRef 
}) {
  const { t } = useTranslation();
  
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const tagsParam = searchParams.get("tags");
  const tabParam = searchParams.get("c_tab");
  const isAdultParam = searchParams.get("is_adult");
  const browseLevelsParam = searchParams.get("browse_levels");
  const genderParam = searchParams.get("gender");
  const [characters, setCharacters] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchFocused, setSearchFocused] = useState(false);
  const [tags, setTags] = useState([]);
  const [moreTagsOpen, setMoreTagsOpen] = useState(false);
  const [needIncrementPage, setNeedIncrementPage] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [requestParams, setRequestParams] = useState({
    page: 1,
    pageSize: 10,
    tags: [],
    c_tab: null,
    browse_levels: [],
    is_adult: false,
    gender: null,
    reset: true,
  });

  const FILTER_STORAGE_KEY = "character_filters";

  const { user } = useContext(UserContext);

  const apiOptions = useMemo(
    () => ({
      baseUrl: process.env.REACT_APP_API_URL,
      requestOptions: {
        headers: {
          "x-init-data": tg.initData,
        },
      },
    }),
    []
  );

  const {
    character: { useList, useSearch },
  } = useApi(apiOptions);

  const {
    fetch: getCharactersFetch,
    loading: getCharactersLoading
  } = useList();

  const {
    fetch: getCharactersSearchFetch,
    loading: getCharactersSearchLoading,
    data: getCharactersSearchData,
    abort: getCharactersSearchAbort,
  } = useSearch();

  const [filtersVisible, setFiltersVisible] = useState(false);

  const isAnyFilterApplied = useMemo(() => {
    return (
      requestParams.tags.length > 0 ||
      requestParams.browse_levels.length > 0 ||
      requestParams.c_tab !== null ||
      requestParams.gender !== null ||
      requestParams.kind !== null ||
      requestParams.is_adult
    );
  }, [requestParams]);

  const handleResize = useCallback(() => {
    const rowCount = Math.round(Math.min(window.innerWidth, 850) / 188);
    const columnCount = (window.innerHeight / 430) * 2;

    const newPageSize = Math.round(rowCount * columnCount);

    if (newPageSize <= requestParams.pageSize) return;

    setRequestParams((prevParams) => ({
      ...prevParams,
      page: 1,
      pageSize: newPageSize,
      reset: true,
    }));
  }, [requestParams]);

  const loadMore = useCallback(() => {
    const nextPage = Math.ceil(characters.length / requestParams.pageSize) + 1;

    if (nextPage > requestParams.page) {
        setNeedIncrementPage(true);
    }
  }, [characters, requestParams]);

  useEffect(() => {
    setLoadMoreHooks((prev) => ({ ...prev, characters: loadMore }));
  }, [loadMore]);

  useEffect(() => {
    if (!needIncrementPage) return;
    setRequestParams((prevParams) => ({
      ...prevParams,
      page: prevParams.page + 1,
      reset: false,
    }));
  }, [needIncrementPage]);

  useEffect(() => {
    tg.BackButton.hide();
    tg.expand();
    tg.disableVerticalSwipes();

    const rowCount = Math.round(Math.min(window.innerWidth, 850) / 188);
    const columnCount = (window.innerHeight / 430) * 2;

    // Load filters from localStorage first, then fallback to URL params
    const savedFilters = localStorage.getItem(FILTER_STORAGE_KEY);
    let initialFilters = {
      tags: tagsParam ? tagsParam.split(",").map((tag) => parseInt(tag)) : [],
      c_tab: tabParam ? tabParam : null,
      browse_levels: browseLevelsParam ? browseLevelsParam.split(",") : [],
      is_adult: isAdultParam === "true",
      gender: genderParam || null,
    };

    if (savedFilters) {
      try {
        const parsedFilters = JSON.parse(savedFilters);
        initialFilters = { ...initialFilters, ...parsedFilters };
      } catch (error) {
        console.error("Error parsing saved filters:", error);
      }
    }

    setRequestParams({
      page: 1,
      pageSize: Math.round(rowCount * columnCount),
      ...initialFilters,
      reset: true,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    if (!requestParams) return;
    
    const filtersToSave = {
      tags: requestParams.tags,
      c_tab: requestParams.c_tab,
      browse_levels: requestParams.browse_levels,
      is_adult: requestParams.is_adult,
      gender: requestParams.gender,
      kind: requestParams.kind,
    };
    
    localStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(filtersToSave));
    
    // Filter out empty parameters for URL
    const urlParams = {};
    if (requestParams.tags.length > 0) urlParams.tags = requestParams.tags.join(",");
    if (requestParams.browse_levels.length > 0) urlParams.browse_levels = requestParams.browse_levels.join(",");
    if (requestParams.c_tab) urlParams.c_tab = requestParams.c_tab;
    if (requestParams.gender) urlParams.gender = requestParams.gender;
    if (requestParams.is_adult) urlParams.is_adult = requestParams.is_adult;
    
    setSearchParams({ ...urlParams, tab: requestParams.tab || 'characters' });
    
    // Filter out empty parameters for API request
    const filteredParams = { ...requestParams };
    if (!filteredParams.c_tab) delete filteredParams.c_tab;
    if (!filteredParams.gender) delete filteredParams.gender;
    if (filteredParams.tags.length === 0) delete filteredParams.tags;
    if (filteredParams.browse_levels.length === 0) delete filteredParams.browse_levels;
    if (!filteredParams.is_adult) delete filteredParams.is_adult;
    if (!filteredParams.kind) delete filteredParams.kind;

    getCharactersFetch({ query: filteredParams }).then((data) => {
      if (requestParams.reset) {
        setCharacters(data.characters);

        setTotal(data.total);
        setTags(data.tags);
        setLoading(false);
      } else {
        setCharacters((prevCharacters) => [
          ...prevCharacters,
          ...data.characters,
        ]);
      }
      setNeedIncrementPage(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestParams]);

  const onCharacterClick = useCallback(
    (id) => () => {
      tg.BackButton.show();
      navigate(`/character/${id}`);
    },
    [navigate]
  );

  const onSearchChange = useCallback((e) => {
    getCharactersSearchAbort();

    setSearchQuery(e.target.value);

    if (getCharactersSearchLoading || e.target.value.length < 3) return;
    getCharactersSearchFetch({ query: { query: e.target.value, pageSize: 6 } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTagClick = useCallback(
    (tagId) => () => {
      setRequestParams((prevParams) => {
        let newSearchTags = [...prevParams.tags];

        if (prevParams.tags.includes(tagId)) {
          newSearchTags = newSearchTags.filter((id) => id !== tagId);
        } else {
          newSearchTags.push(tagId);
        }

        return { ...prevParams, tags: newSearchTags, page: 1, reset: true };
      });
    },
    []
  );

  const onAdultCharactersFilterClick = useCallback(() => {
    setRequestParams((prevParams) => {
      return {
        ...prevParams,
        is_adult: !(prevParams.is_adult.toString() === "true"),
        page: 1,
        reset: true,
      };
    });
  }, []);

  const onBrowseLevelClick = useCallback(
    (level) => () => {
      setRequestParams((prevParams) => {
        let newSearchLevels = [...prevParams.browse_levels];

        if (prevParams.browse_levels.includes(level)) {
          newSearchLevels = newSearchLevels.filter((id) => id !== level);
        } else {
          newSearchLevels.push(level.replace("-", "_"));
        }

        return {
          ...prevParams,
          browse_levels: newSearchLevels,
          page: 1,
          reset: true,
        };
      });
    },
    []
  );

  const onTabClick = useCallback(
    (_tab) => () => {
      setRequestParams((prevParams) => {
        if (prevParams.c_tab === _tab)
          return { ...prevParams, c_tab: null, page: 1, reset: true };
        return { ...prevParams, c_tab: _tab, page: 1, reset: true };
      });
    },
    []
  );

  const onMoreTagsClick = useCallback(() => {
    setMoreTagsOpen((prev) => !prev);
  }, []);

  const onSearchClose = useCallback(() => {
    setSearchQuery("");
    setSearchFocused(false);
    getCharactersSearchAbort();
  }, [getCharactersSearchAbort]);

  const onGenderClick = useCallback(
    (gender) => () => {
      setRequestParams((prevParams) => {
        return {
          ...prevParams,
          gender: prevParams.gender === gender ? null : gender,
          page: 1,
          reset: true,
        };
    });
  }, []);

  const onKindClick = useCallback(
    (kind) => () => {
      setRequestParams((prevParams) => {
        return {
          ...prevParams,
          kind: prevParams.kind === kind ? null : kind,
          page: 1,
          reset: true,
        };
    });
  }, []);

  const toggleFilters = useCallback(() => {
    setFiltersVisible(prev => !prev);
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      {getCharactersLoading && <div className={styles.spinner} />}

      <div
        className={
          styles.overlay + " " + (searchFocused ? styles.searchFocused : "")
        }
      >
        <div className={styles.searchContainer}>
          <div className={styles.searchPanel} ref={searchPanelRef}>
            { !searchFocused &&
              <div 
                className={`${styles.filterButton} ${isAnyFilterApplied ? styles.filterActive : ''}`} 
                onClick={toggleFilters}
              >
                <FaFilter />
              </div>
            }
            <div className={styles.searchInputWrapper}>
              <IoSearchSharp className={styles.searchIcon} />
              <input
                type="text"
                placeholder={t('characters.search')}
                onFocus={() => setSearchFocused(true)}
                onChange={onSearchChange}
                value={searchQuery}
              />
              <IoCloseOutline
                className={styles.searchCloseIcon}
                onClick={onSearchClose}
              />
            </div>
          </div>
          <div className={styles.searchResults}>
            <div className={styles.searchCharactersResults}>
              <div className={styles.searchCharactersResultsTitle}>
                {t('characters.titles.characters')}
              </div>
              {getCharactersSearchData?.characters.map((character) => (
                <div
                  className={styles.searchCharacter}
                  key={character.id}
                  onClick={onCharacterClick(character.id)}
                >
                  <div
                    className={styles.avatar}
                    style={
                      character.avatar_url
                        ? { backgroundImage: `url(${character.avatar_url})` }
                        : { backgroundColor: "#000" }
                    }
                  >
                    {" "}
                    {character.avatar_url ? "" : "no avatar"}{" "}
                  </div>
                  <div className={styles.name}>
                    {highlightText(character.name, searchQuery)}
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.searchTagsResults}>
              <div className={styles.searchTagsResultsTitle}>{t('characters.titles.tags')}</div>
              {getCharactersSearchData?.tags.map((tag) => (
                <div
                  key={tag.id}
                  data-tag-id={tag.id}
                  className={
                    styles.searchTag +
                    " " +
                    (requestParams.tags.includes(tag.id)
                      ? styles.searchTagActive
                      : "")
                  }
                  onClick={onTagClick(tag.id)}
                >
                  {highlightText(tag.name, searchQuery)}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <header className={styles.header}>
        {filtersVisible && (
          <div className={styles.filtersContainer}>
            <div className={styles.tabs}>
              <div
                data-c_tab="my"
                className={
                  styles.tab   +
                  " " +
                  (requestParams.c_tab === "my" ? styles.tabActive : "")
                }
                onClick={onTabClick("my")}
              >
                <IoPersonOutline />
                <span>{t('feed.tabs.my')}</span>
              </div>
              <div
                data-c_tab="liked"
                className={
                  styles.tab   +
                  " " +
                  (requestParams.c_tab === "liked" ? styles.tabActive : "")
                }
                onClick={onTabClick("liked")}
              >
                <IoHeartOutline />
                <span>{t('feed.tabs.likes')}</span>
              </div>
              <div
                data-c_tab="favorite"
                className={
                  styles.tab   +
                  " " +
                  (requestParams.c_tab === "favorite" ? styles.tabActive : "")
                }
                onClick={onTabClick("favorite")}
              >
                <IoBookmarkOutline />
                <span>{t('feed.tabs.favorites')}</span>
              </div>
              <div
                data-c_tab="chat"
                className={
                  styles.tab   +
                  " " +
                  (requestParams.c_tab === "chat" ? styles.tabActive : "")
                }
                onClick={onTabClick("chat")}
              >
                <IoChatbubbleOutline />
                <span>{t('feed.tabs.chats')}</span>
              </div>
            </div>

            <div className={styles.tabs}>
              <div
                data-kind="REALISTIC"
                className={
                  styles.tab   +
                  " " +
                  (requestParams.kind === "REALISTIC" ? styles.tabActive : "")
                }
                onClick={onKindClick("REALISTIC")}
              >
                <span>{t('characters.kind.realistic')}</span>
              </div>
              <div
                data-kind="ANIME"
                className={
                  styles.tab +
                  " " +
                  (requestParams.kind === "ANIME" ? styles.tabActive : "")
                }
                onClick={onKindClick("ANIME")}
              >
                <span>{t('characters.kind.anime')}</span>
              </div>
              <div
                data-kind="CARTOON"
                className={
                  styles.tab +
                  " " +
                  (requestParams.kind === "CARTOON" ? styles.tabActive : "")
                }
                onClick={onKindClick("CARTOON")}
              >
                <span>{t('characters.kind.cartoon')}</span>
              </div>
            </div>

            <div className={styles.tabs}>
              <div
                data-gender="MALE"
                className={
                  styles.tab +
                  " " +
                  (requestParams.gender === "MALE" ? styles.tabActive : "")
                }
                onClick={onGenderClick("MALE")}
              >
                <IoMaleOutline />
                <span>{t('characters.gender.male')}</span>
              </div>
              <div
                data-gender="FEMALE"
                className={
                  styles.tab   +
                  " " +
                  (requestParams.gender === "FEMALE" ? styles.tabActive : "")
                }
                onClick={onGenderClick("FEMALE")}
              >
                <IoFemaleOutline />
                <span>{t('characters.gender.female')}</span>
              </div>
              <div
                data-gender="OTHER"
                className={
                  styles.tab   +
                  " " +
                  (requestParams.gender === "OTHER" ? styles.tabActive : "")
                }
                onClick={onGenderClick("OTHER")}
              >
                <PiGenderNonbinary />
                <span>{t('characters.gender.other')}</span>
              </div>
            </div>

            <div className={styles.tags}>
              {tags.slice(0, moreTagsOpen ? tags.length : 5).map((tag) => (
                <span
                  key={tag.id}
                  data-tag-id={tag.id}
                  className={
                    styles.tag +
                    " " +
                    (requestParams.tags.includes(tag.id) ? styles.tagActive : "")
                  }
                  onClick={onTagClick(tag.id)}
                >
                  {tag.name}
                </span>
              ))}
              {tags.length > 5 && (
                <span
                  className={
                    styles.more +
                    " " +
                    styles.tag +
                    " " +
                    (moreTagsOpen ? styles.moreOpen : "")
                  }
                  onClick={onMoreTagsClick}
                >
                  {moreTagsOpen ? t('feed.less') : t('feed.more')} {<IoArrowDown />}
                </span>
              )}
            </div>

            {user.filter_rating && ratings[user.filter_rating] && (
              <div className={styles.browsingLevel} ref={browsingLevelRef}>
                {ratings[user.filter_rating].map((level) => (
                  <div
                    key={level}
                    className={
                      styles.browsingLevelItem +
                      " " +
                      (requestParams.browse_levels.includes(level)
                        ? styles.active
                        : "")
                    }
                    onClick={onBrowseLevelClick(level)}
                  >
                    {level.replace("_", "-")}
                  </div>
                ))}
                {user.is_adult && (
                  <div
                    className={
                      styles.browsingLevelItem +
                      " " +
                      (requestParams.is_adult ? styles.active : "")
                    }
                    onClick={onAdultCharactersFilterClick}
                  >
                    <IoHeartOutline className={styles.browsingLevelItemIcon} /> 18+
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </header>
      {characters.length === 0 && (
        <NotFound>
          <div>{t('characters.noCharactersFound')}</div>
        </NotFound>
      )}
      <div className={styles.characters} ref={charactersGridRef}>
        {characters.map((character) => (
          <div
            key={character.id}
            className={styles.character}
            onClick={onCharacterClick(character.id)}
          >
            <CharacterPreview user={user} character={character} />
          </div>
        ))}

        {getCharactersLoading &&
          Array.from({
            length: Math.min(total - characters.length, requestParams.pageSize),
          }).map((_, index) => (
            <div key={index} className={styles.emptyCharacter}>
              <div className={styles.avatar} />
              <span className={styles.name} />
              <span className={styles.description} />
              <div className={styles.tags}>
                {Array.from({ length: 3 }).map((_, index) => (
                  <span key={index} className={styles.tag} />
                ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
