import React, { useState, useEffect, useRef } from 'react';
import { 
  IoArrowForward, 
  IoSearchSharp, 
  IoHeartOutline, 
  IoChatbubbleOutline,
  IoClose
} from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import styles from './Tutorial.module.css';

export function Tutorial({ 
  onComplete, 
  onChangeMainTab, 
  onNavigate, 
  refs 
}) {
  const { t } = useTranslation();
  
  const TUTORIAL_STEPS = [
    {
      title: t('tutorial.steps.welcome.title'),
      content: t('tutorial.steps.welcome.content'),
      target: null,
      position: 'center',
    },
    {
      title: t('tutorial.steps.feed.title'),
      content: t('tutorial.steps.feed.content'),
      target: 'feedTab',
      position: 'bottom',
    },
    {
      title: t('tutorial.steps.characters.title'),
      content: t('tutorial.steps.characters.content'),
      target: 'charactersTab',
      position: 'bottom',
      isAction: true,
    },
    {
      title: t('tutorial.steps.search.title'),
      content: t('tutorial.steps.search.content'),
      target: 'searchPanel',
      position: 'bottom',
    },
    {
      title: t('tutorial.steps.filters.title'),
      content: t('tutorial.steps.filters.content'),
      target: 'browsingLevel',
      position: 'bottom',
    },
    {
      title: t('tutorial.steps.pickCharacter.title'),
      content: t('tutorial.steps.pickCharacter.content'),
      target: 'characters',
      position: 'top',
    },
    {
      title: t('tutorial.steps.profile.title'),
      content: t('tutorial.steps.profile.content'),
      target: null,
      position: 'center',
    },
    {
      title: t('tutorial.steps.startChat.title'),
      content: t('tutorial.steps.startChat.content'),
      target: 'button',
      position: 'top',
    },
    {
      title: t('tutorial.steps.allSet.title'),
      content: t('tutorial.steps.allSet.content'),
      target: null,
      position: 'center',
    }
  ];

  const TUTORIAL_STEP_KEY = "tutorial_current_step";

  const [step, setStep] = useState(() => {
    const savedStep = localStorage.getItem(TUTORIAL_STEP_KEY);
    return savedStep ? parseInt(savedStep) : 0;
  });

  useEffect(() => {
    localStorage.setItem(TUTORIAL_STEP_KEY, step.toString());
  }, [step]);

  const [targetElement, setTargetElement] = useState(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [visible, setVisible] = useState(true);
  const [waitingForAction, setWaitingForAction] = useState(false);
  const tooltipRef = useRef(null);

  const currentStep = TUTORIAL_STEPS[step];

  useEffect(() => {
    if (step >= TUTORIAL_STEPS.length) {
      onComplete();
      return;
    }

    if (!currentStep.target) {
      setTargetElement(null);
      setPosition({
        top: window.innerHeight / 2,
        left: window.innerWidth / 2,
        positionClass: 'center'
      });
      
      const updateCenterPosition = () => {
        setPosition({
          top: window.innerHeight / 2,
          left: window.innerWidth / 2,
          positionClass: 'center'
        });
      };

      window.addEventListener('resize', updateCenterPosition);
      window.addEventListener('orientationchange', updateCenterPosition);
      
      return () => {
        window.removeEventListener('resize', updateCenterPosition);
        window.removeEventListener('orientationchange', updateCenterPosition);
      };
    }

    const element = refs[currentStep.target]?.current;
    
    if (element) {
      setTargetElement(element);
      
      const updatePosition = () => {
        if (!element) return;
        
        const rect = element.getBoundingClientRect();
        const tooltipWidth = 320;
        const tooltipHeight = 200;
        const margin = 20;
        const arrowSize = 10;
        
        let top, left, positionClass;
        positionClass = currentStep.position || 'bottom';

        const spaceAbove = rect.top;
        const spaceBelow = window.innerHeight - rect.bottom;

        if (positionClass === 'bottom' && spaceBelow < tooltipHeight + margin) {
          positionClass = spaceAbove > spaceBelow ? 'top' : 'bottom';
        } else if (positionClass === 'top' && spaceAbove < tooltipHeight + margin) {
          positionClass = spaceBelow > spaceAbove ? 'bottom' : 'top';
        }

        switch (positionClass) {
          case 'top':
            top = rect.top - tooltipHeight - arrowSize;
            left = rect.left + (rect.width - tooltipWidth) / 2;
            break;
          case 'bottom':
            top = rect.bottom + arrowSize;
            left = rect.left + (rect.width - tooltipWidth) / 2;
            break;
          case 'left':
            top = rect.top + (rect.height - tooltipHeight) / 2;
            left = rect.left - tooltipWidth - arrowSize;
            break;
          case 'right':
            top = rect.top + (rect.height - tooltipHeight) / 2;
            left = rect.right + arrowSize;
            break;
          default:
            top = (window.innerHeight - tooltipHeight) / 2;
            left = (window.innerWidth - tooltipWidth) / 2;
        }

        const maxLeft = window.innerWidth - tooltipWidth - margin;
        const maxTop = window.innerHeight - tooltipHeight - margin;

        left = Math.max(margin, Math.min(left, maxLeft));
        top = Math.max(margin, Math.min(top, maxTop));

        let arrowPosition = {};
        
        if (positionClass === 'top' || positionClass === 'bottom') {
          const arrowLeft = rect.left + rect.width / 2 - left;
          arrowPosition = {
            left: `${Math.max(arrowSize, Math.min(tooltipWidth - arrowSize, arrowLeft))}px`
          };
        } else if (positionClass === 'left' || positionClass === 'right') {
          const arrowTop = rect.top + rect.height / 2 - top;
          arrowPosition = {
            top: `${Math.max(arrowSize, Math.min(tooltipHeight - arrowSize, arrowTop))}px`
          };
        }

        setPosition({ top, left, positionClass, arrowPosition });
      };

      updatePosition();
      
      window.addEventListener('resize', updatePosition);
      window.addEventListener('scroll', updatePosition);
      window.addEventListener('orientationchange', updatePosition);
      
      const resizeObserver = new ResizeObserver(updatePosition);
      resizeObserver.observe(document.body);
      
      return () => {
        window.removeEventListener('resize', updatePosition);
        window.removeEventListener('scroll', updatePosition);
        window.removeEventListener('orientationchange', updatePosition);
        resizeObserver.disconnect();
      };
    }
  }, [step, currentStep, onComplete, refs]);

  const handleNext = () => {
    if (step >= TUTORIAL_STEPS.length - 1) {
      localStorage.removeItem(TUTORIAL_STEP_KEY);
      onComplete();
      return;
    }

    if (currentStep.isAction) {
      setWaitingForAction(true);
      if (step === 2) {
        onChangeMainTab('characters')();
        setTimeout(() => {
          setStep(step + 1);
          setWaitingForAction(false);
        }, 500);
      }
    } else {
      if (step === 5) {
        setStep(step + 1);
        setWaitingForAction(true);
        setTimeout(() => {
          onNavigate('/character/13');
        }, 500);
      } else {
        setStep(step + 1);
      }
    }
  };

  const handleSkip = () => {
    localStorage.removeItem(TUTORIAL_STEP_KEY);
    onComplete();
  };

  if (!visible) return null;

  return (
    <div className={styles.tutorialContainer}>
      <div className={styles.overlay}></div>
      
      {targetElement && (
        <div 
          className={`${styles.highlight}`}
          style={{
            top: targetElement.getBoundingClientRect().top,
            left: targetElement.getBoundingClientRect().left,
            width: targetElement.offsetWidth,
            height: targetElement.offsetHeight,
          }}
        />
      )}
      
      <div 
        ref={tooltipRef}
        className={`${styles.tooltip} ${styles[position.positionClass || currentStep.position || 'center']}`}
        style={{
          top: position.top,
          left: position.left,
          '--arrow-left': position.arrowPosition?.left,
          '--arrow-top': position.arrowPosition?.top
        }}
      >
        <div className={styles.tooltipContent}>
          <h3>{currentStep.title}</h3>
          <p>{currentStep.content}</p>
          
          <div className={styles.actions}>
            <button className={styles.skipButton} onClick={handleSkip}>
              {t('tutorial.buttons.skip')}
            </button>
            
            <button 
              className={styles.nextButton} 
              onClick={handleNext}
              disabled={waitingForAction}
            >
              {waitingForAction ? t('tutorial.buttons.waiting') : (
                step === TUTORIAL_STEPS.length - 1 ? t('tutorial.buttons.finish') : t('tutorial.buttons.next')
              )}
              {!waitingForAction && <IoArrowForward />}
            </button>
          </div>
          
          <div className={styles.progress}>
            {TUTORIAL_STEPS.map((_, i) => (
              <div 
                key={i} 
                className={`${styles.dot} ${i === step ? styles.active : ''}`}
              />
            ))}
          </div>
        </div>
      </div>
      
      <button className={styles.closeButton} onClick={handleSkip}>
        <IoClose />
      </button>
    </div>
  );
} 